<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>体检套餐信息维护</b></h1>
    <h3><b>选择危害因素</b> 当劳动者的危害因素包括主要粉尘中的多个时，只能选择一种最主要粉尘上传。</h3>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" [formGroup]="form">
    <mat-form-field appearance="fill" style="width:29%">
        <mat-label>危害类型</mat-label>
        <mat-select formControlName="type" (selectionChange)="type_select($event)">
            <mat-option *ngFor="let i of type_list" [value]="i.id">{{i.type_name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- <mat-form-field appearance="fill" style="width:69%">
        <mat-label>点击以多选</mat-label>
        <mat-select formControlName="type_name"  multiple>
            <mat-option *ngFor="let option of filteredOptions" [value]="option" >
            {{option.type_name}}
            </mat-option>

        </mat-select>
    </mat-form-field> -->
    <mat-form-field appearance="fill" style="width:34%" >
        <mat-label  >危害因素输入以过滤..</mat-label>
        <input type="text"  matInput  
            [matAutocomplete]="hazards_auto" matChipInputSeparatorKeyCodes="separatorKeysCodes"
            formControlName="hazards_temp"  style="min-width: 20px !important; max-width: 100%;">
        <mat-autocomplete requireSelection #hazards_auto="matAutocomplete" (optionSelected)="add_hazards($event)">
            <mat-option *ngFor="let h of filteredOptions_auto |async" [value]="h" >
                {{h.type_name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:34%">
        <mat-label>主要粉尘</mat-label>
        <mat-select formControlName="type_name_single" (selectionChange)="single_select($event)">
            <mat-option *ngFor="let option of filteredOptions_single" [value]="option">
                {{option.type_name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <table style="width: 100%;">
        <th>主要粉尘:</th>
        <tr>
            <td>
                <mat-chip-list>
                    <mat-chip *ngFor="let hazard of form.get('type_name_single_one').value;let i = index" selectable removable color="accent"  selected
                        (removed)="remove_hazard_one_by_type_name(i)">
                        {{hazard.type_name}} <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
           
        </tr>
    </table>
    <table style="width: 100%;">
        <th>危害因素:</th>
        <tr>
            <td>
                <mat-chip-list>
                    <mat-chip *ngFor="let hazard of form.get('type_name').value;let i = index" selectable removable selected
                        (removed)="remove_hazard_by_type_name(i)">
                        {{hazard.type_name}} <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </td>
            
        </tr>
    </table>
</div>
<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1%">
    <button mat-raised-button color="wran" [mat-dialog-close]="false">取消</button>
    <button mat-raised-button color="primary" (click)="import_selected()">导入</button>
</div>