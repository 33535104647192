import { throwError } from "rxjs";

export function handleError(error: { error: { message: any; }; message: any; }) {
    let errorMessage = '';
    
    if (error.error instanceof ErrorEvent) {
      // client-side error
      ;
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      ;
      errorMessage = `Error Code: ${error}\nMessage: ${error.message}`;
    }
    ;
    //this.router.navigate(['login'], { queryParams: { msg:errorMessage}});
    return throwError(errorMessage);
  }