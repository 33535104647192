import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupUnitSelecterComponent } from './group-unit-selecter/group-unit-selecter.component';
import { NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { MaterialModule } from '../shared/material.module';
import { ErrorInterceptor } from '../utils/error.interceptor';
import { JwtInterceptor } from '../utils/jwt.interceptor';
import { DateRangeComponent } from './date-range/date-range.component';
import { HisDeptListComponent } from './his-dept-list/his-dept-list.component';



@NgModule({
  declarations: [GroupUnitSelecterComponent, DateRangeComponent, HisDeptListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    WebcamModule,
    NgxPrintModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
	ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
    { provide: LOCALE_ID, useValue: "zh-cn" },
  ],
  exports:[GroupUnitSelecterComponent,DateRangeComponent,HisDeptListComponent]
})
export class ComponentModelsModule { }
