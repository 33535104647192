import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { GroupOrderService } from "src/app/home/basic/group-order/group-order.service";
import { DialogModelsService } from "../dialog-models.service";

export class ComboDatasource implements DataSource<any>{
    private patSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: any[];
    public length: number

    constructor(private groupOrderService: DialogModelsService) { }

    loadtables(type:string,career_stage:string,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number) {

        this.loadingSubject.next(true);
        this.groupOrderService.sch_combo_list_by_career_stage(type,career_stage,q_condition, q_value, tpage_nmb, tn_per_page)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(thsp => {
                this.data = <any[]>thsp.data;
                this.length = thsp.count_num

                this.patSubject.next(thsp.data)
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}