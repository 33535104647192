import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { HazardAddMultiple2Component } from 'src/app/dialog-models/hazard-add-multiple2/hazard-add-multiple2.component';
import { LoadErrorComponent } from 'src/app/dialog-models/load-error/load-error.component';
import { UploadDialogComponent } from 'src/app/dialog-models/upload-dialog/upload-dialog.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { PeResultService } from '../../basic/pe-result/pe-result.service';
import { SingleCheckService } from '../../content/single-check/single-check.service';
import { PeResultDatasource } from './list.datasource';
import { PersonInfoLoadService } from './person-info-load.service';

@Component({
  selector: 'app-person-info-load',
  templateUrl: './person-info-load.component.html',
  styleUrls: ['./person-info-load.component.css'],
  providers: [DatePipe]
})
export class PersonInfoLoadComponent implements OnInit {
  tbutton_status: boolean = false;

  tcombo: any = {}
  dataSource: PeResultDatasource;
  displayedColumns = ['select', 'test_num', 'person_name', 'sex', 'age', 'dept', 'hazard_factors_text', 'other_hazard_factors', 'work_type_text', 'work_state_text', 'check_date', 'regist_date', 'operate'];
  print_flag = false

  selection = new SelectionModel<any>(true, []);
  selectedRowIndexAuto: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('group_unit_selecter') group_unit_selecter: any;
  combo_type_list = []
  section_id = '4'
  @Input() parent: any;
  lastWeekStartDate: Date;
  tomorrow: Date;
  session_physical_type = sessionStorage.getItem('physical_type')

  hazard_search_type = '1'
  search_form: any
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  work_type_control = this.fb.control('')
  work_type_list: Observable<any[]>
  work_state_list = []
  type_list = []
  filteredOptions = [];


  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private singleCheckService: SingleCheckService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    private peResultService: PeResultService,
    private personInfoLoadService: PersonInfoLoadService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.get_combo_type_list()
    this.get_type_list()
    this.get_work_state_list()
    const today = new Date()
    this.lastWeekStartDate = new Date(today)
    this.tomorrow = new Date(today)
    this.lastWeekStartDate.setDate(today.getDate() - 7);
    this.tomorrow.setDate(today.getDate() + 1)
  }

  ngOnInit(): void {
    this.search_form = this.fb.group({
      work_state_codes: [],
      hazard_factor_types: [],
      hazard_factors: this.fb.array([]),
      work_types: this.fb.array([]),
      start: new FormControl(this.lastWeekStartDate),
      end: new FormControl(this.tomorrow),
      input_cate: 'test_num',
      input_text: ''

    })
    this.dataSource = new PeResultDatasource(this.peResultService, this.notifyService);
    let input_data = {
      group_unit_name: '',
      section_code: this.section_id,
      q_condition: '',
      q_value: '',
      tpage_nmb: 0,
      tn_per_page: 15,
      start_date: this.datePipe.transform(this.search_form.value.start, 'yyyy-MM-dd HH:mm:ss'),
      end_date: this.datePipe.transform(this.search_form.value.end, 'yyyy-MM-dd HH:mm:ss'),
      work_state_codes: this.search_form.get('work_state_codes').value,
      hazard_factor_types: this.search_form.get('hazard_factor_types').value,
      hazard_factors: this.hazard_factors.value,
      work_types: this.work_types.value
    }
    this.dataSource.loadtables(input_data);
    this.work_type_list = this.work_type_control.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.title
          return this._filter(title)
        }),
      );
  }

  ngAfterViewInit() {
    if (this.tcombo == undefined) {
      this.tcombo = {}
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();
  }


  loadTablePage() {
    this.selection.clear()
    if (this.tcombo == undefined) {
      this.tcombo = {}
    }
    let input_data = {
      group_unit_name: this.group_unit_selecter.unit,
      section_code: this.section_id,
      q_condition: this.search_form.get('input_cate').value,
      q_value: this.search_form.get('input_text').value,
      tpage_nmb: this.paginator.pageIndex,
      tn_per_page: this.paginator.pageSize,
      start_date: this.datePipe.transform(this.search_form.value.start, 'yyyy-MM-dd HH:mm:ss'),
      end_date: this.datePipe.transform(this.search_form.value.end, 'yyyy-MM-dd HH:mm:ss'),
      work_state_codes: this.search_form.get('work_state_codes').value,
      hazard_factor_types: this.search_form.get('hazard_factor_types').value,
      hazard_factors: this.hazard_factors.value.map(m => m.type_code),
      work_types: this.work_types.value.map(m => m.value)
    }
    this.dataSource.loadtables(input_data)

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  search() {
    this.paginator.pageIndex = 0
    this.loadTablePage()

  }

  add_combo() {

    this.router.navigate(['sysset/combo/detail'])
  }
  edit_combo(row, edit_flag) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: row,
        edit_flag: edit_flag
      }
    };
    this.router.navigate(['sysset/combo/detail'], navigationExtras)
  }
  get_combo_type_list() {
    this.commonService.get_dict_data_by_type('checkType').subscribe(res => {
      if (res.flag) {
        this.combo_type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  generate_total_check_sheet_list() {
    this.print_flag = true

    let input_data = {
      "persons": this.selection.selected,
      "physical_type": this.session_physical_type,
    }
    this.peResultService.generate_total_check_sheet_list(input_data).subscribe(res => {
      if (res.flag) {
        this.commonService.print_pdf(res.value)
        this.notifyService.showSuccess('打印选中体检报告完成', '成功')
      } else {
        this.notifyService.showError(res.value, '失败')
      }
      this.print_flag = false
    })
  }

  replay() {
    // this.input_cate = 'test_num'
    this.search_form.get('work_state_codes').setValue([])
    this.search_form.get('hazard_factor_types').setValue([])
    this.hazard_factors.clear()
    this.work_types.clear()
    this.search_form.get('input_text').setValue('')
    this.group_unit_selecter.input_form.get('unit').setValue('')
    // this.range.get('start').setValue(this.lastWeekStartDate)
    // this.range.get('end').setValue(this.tomorrow)
    this.search()
  }

  upload_person(row, optype) {

    if (optype != '3') {
      if (row.upload_status == 0) {
        optype = '1'
      } else {
        optype = '2'
      }
    }
    this.upload_persons(row, optype)
  }
  upload_persons(row, optype) {
    let persons = this.selection.selected
    if (row) {
      persons = [row]
    }

    let input_data = {
      'persons': persons,
      'optype': optype
    }
    this.personInfoLoadService.generate_persons_zip_path(input_data).subscribe(res => {

      if (res.flag) {
        const uploadDialog = this.dialog.open(UploadDialogComponent, {
          width: "90%",
          height: "90%",
          data: {
            upload_detail_list: res.value.upload_detail_list,
            type_name: '个人',
            optype: optype
          }
        })
        uploadDialog.afterClosed().subscribe(res => {
          this.search()
        })
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  get hazard_factors() {
    return this.search_form.get('hazard_factors') as FormArray
  }

  get work_types() {
    return this.search_form.get('work_types') as FormArray
  }
  hazard_search_type_change(event) {
    let value = event.value
    this.hazard_search_type = event.value

    if (value == 1) {
      this.hazard_factors.clear()
    } else if (value == 2) {
      this.search_form.get('hazard_factor_types').setValue('')
    }
  }
  hazard_factors_add() {
    let dialogRef = this.dialog.open(HazardAddMultiple2Component, {
      width: '80%',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let hazard_factors_exist = this.search_form.get('hazard_factors').value

        let pro_types = res.type_name

        pro_types.forEach(type => {
          if (!this.hazard_factors.controls.find(control => control.get('id').value == type.id)) {
            this.hazard_factors.push(this.fb.group({ ...type }))
          }
        })
      }

    })

  }

  delete_hazard_factor(j) {
    this.hazard_factors.removeAt(j)
  }
  add_2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (input) {
      input.value = '';
    }
  }
  displayFn(option: any): string {

    return option && option.title ? option.title : ''
  }

  private _filter(title: any): any[] {
    let tl_new = []
    this.commonService.get_work_type_list(title).subscribe(res => {
      if (res.flag) {
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }
  get_work_state_list() {
    this.commonService.get_dict_data_by_type('workState')
      .subscribe(res => {
        if (res.flag) {

          this.work_state_list = res.value
        } else {
          this.notifyService.showError(res.value, '错误')
        }
      })
  }
  get_type_list() {

    this.commonService.get_t_pro_type_by_tree().subscribe(res => {

      if (res.flag) {
        this.type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  type_select(event) {

    this.commonService.get_t_pro_type_by_tree_and_condition(event.value, '')
      .subscribe(data => {
        if (data.flag) {
          this.filteredOptions = data.value
        } else {
          this.notifyService.showError(data.value, '失败')
        }
      })
  }
  add_effect(event) {
    let value = event.source.value
    const input = event.isUserInput
    if (!this.work_types.controls.find(f => f.get('value').value == value.value)) {
      this.work_types.push(this.fb.group({
        ...value
      }))
    }
    if (input) {
      event.source.value = '';
    }

  }
  remove_form_list_items(index: number) {
    this.work_types.removeAt(index)
  }
}
