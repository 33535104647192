import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  session_physical_type = sessionStorage.getItem('physical_type')

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  async onMenuAction(tpage: string) {
    switch (tpage) {
      case "recheck": {
        this.router.navigate(['/statistics/recheck']);
        break;
      }
      case "proResultSuggest": {
        this.router.navigate(['/statistics/proResultSuggest']);
        break;
      }
      case "proPositiveResultPL": {
        this.router.navigate(['/statistics/proPositiveResultPL']);
        break;
      }
      case "singleCheckSum": {
        this.router.navigate(['/statistics/singleCheckSum']);
        break;
      }
      case "singleDiagSum": {
        this.router.navigate(['/statistics/singleDiagSum']);
        break;
      } case "peisFee": {
        this.router.navigate(['/statistics/peisFee']);
        break;
      }case "comboSum": {
        this.router.navigate(['/statistics/comboSum']);
        break;
      }case "proHazardSum": {
        this.router.navigate(['/statistics/proHazardSum']);
        break;
      }case "checkingItemSum": {
        this.router.navigate(['/statistics/checkingItemSum']);
        break;
      } case "healthCheckResultSum": {
        this.router.navigate(['/statistics/healthCheckResultSum']);
        break;
      }case "checkPersonList": {
        this.router.navigate(['/statistics/checkPersonList']);
        break;
      }
      default: {
        break;
      }
    }
  }
}