import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-group-unit-selecter',
  templateUrl: './group-unit-selecter.component.html',
  styleUrls: ['./group-unit-selecter.component.css']
})
export class GroupUnitSelecterComponent implements OnInit {
  unit_list: Observable<any[]>
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private notifyService: NotificationService,
  ) { }
  input_form: FormGroup
  @Input() parent: any
  @Input() required:any=false
  @Input() auto_search:boolean = true
  ngOnInit(): void {
    this.input_form = this.fb.group({
      unit: ''
    })
    this.unit_list = this.input_form.get('unit').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.name
          return this._filter_e(title)
        }),
      );
  }
  private _filter_e(title: any): any[] {
    let tl_new = []
    this.commonService.get_group_unit_List_by_part(title).subscribe(res => {
      
      if (res.flag) {
        
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }
  displayFn_e(option: any): string {
    return option && option.name ? option.name : ''
  }
  get unit() {
    
    return this.input_form.get('unit').value.name ? this.input_form.get('unit').value.name : ''
  }
  get unit_id(){
    return this.input_form.get('unit').value.id?this.input_form.get('unit').value.id:''
  }
  unit_select() {
    if(this.auto_search){
      this.parent.search()

    }
  }
}
