import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';
import { ApiHandleError } from '../data-services/api-handle-error';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  lastWeekStartDate: Date;
  tomorrow: Date;
  init_start_date:any
  init_end_date:any  
  constructor(
    private http: HttpClient) {
    const today = new Date()
    this.lastWeekStartDate = new Date(today)
    this.tomorrow = new Date(today)
    this.lastWeekStartDate.setDate(today.getDate() - 7);
    this.tomorrow.setDate(today.getDate() + 1)
    this.lastWeekStartDate.setHours(0,0,0,0)
    this.tomorrow.setHours(23,59,59,999)
   }
   get_start_date():Date{
    return this.lastWeekStartDate
   }

   get_start_date_string():string{
    return new DatePipe('en-US').transform(this.lastWeekStartDate, 'yyyy-MM-dd HH:mm:ss')
   }

   get_end_date():Date{
    return this.tomorrow
   }

   get_end_date_string():string{
    return new DatePipe('en-US').transform(this.tomorrow, 'yyyy-MM-dd HH:mm:ss')

   }

  get_dict_data_by_type(type_str: string): Observable<any> {
    let params = new HttpParams()
      .set('type_str', type_str)
    return this.http.get<any>(`${environment.apiUrl}/common/get_dict_data_by_type/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  // 查询科室下拉列表
  get_dept_List(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/common/get_dept_set_List/`, {})
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }

  // 查询his科室下拉列表
  get_his_dept_List(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/common/get_his_dept_set_List/`, {})
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  get_dict_data_by_type_and_condition(type_str: string, dict_data_title: string): Observable<any> {
    let params = new HttpParams()
      .set('type_str', type_str)
      .set('dict_data_title', dict_data_title)
    return this.http.get<any>(`${environment.apiUrl}/common/get_dict_data_by_type_and_condition/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  get_t_pro_type_by_tree(parent_id?: string): Observable<any> {
    let params = new HttpParams()
    if (parent_id) {
      params.set('parent_id', parent_id)
    }
    return this.http.get<any>(`${environment.apiUrl}/common/get_t_pro_type_by_tree/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }

  get_t_pro_type_by_tree_and_condition(parent_id: string, type_name: string): Observable<any> {
    let params = new HttpParams()
      .set('parent_id', parent_id)
      .set('type_name', type_name)
    
    
    return this.http.get<any>(`${environment.apiUrl}/common/get_t_pro_type_by_tree_and_condition/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  get_dict_data_datasource(section_code, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number): Observable<any> {
    let params = new HttpParams()
      .set('section_code', section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page));

    return this.http.get<any>(`${environment.apiUrl}/common/get_dict_data_datasource/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }


  get_dict_data_datasource_sym(t_dict_type, symptom_type_value, description): Observable<any> {
    let params = new HttpParams()
      .set('t_dict_type', t_dict_type)
      .set('symptom_type_value', symptom_type_value)
      .set('description', description)
    return this.http.get<any>(`${environment.apiUrl}/common/get_dict_data_datasource_sym/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }

  //获取文档进行打印
  print_pdf(path: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    let params = new HttpParams().set('tfile_name', path)
    return this.http.get(`${environment.apiUrl}/download_file/`, { params, headers: headers, responseType: 'blob' as 'json' })
      .subscribe(response => { this.previewLoadFile(response, "application/pdf") });
  }
  previewLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }


  getProTypeByTypeCode(type_str: string): Observable<any> {
    let params = new HttpParams()
      .set('type_str', type_str)
    return this.http.get<any>(`${environment.apiUrl}/common/getProTypeByTypeCode/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }


  get_work_state_by_code(value: string): Observable<any> {
    let params = new HttpParams()
      .set('value', value)
    return this.http.get<any>(`${environment.apiUrl}/common/get_work_state_by_code/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }


  // 查询oralce  tx01.sortdt 下拉列表
  get_srotdt_List(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/common/get_srotdt_List/`, {})
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }

  get_sign_photo(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/get_sign_photo/`, {})
      .pipe(
        map(res => {
          return res
        }),
        catchError(handleError)
      )
  }
  //获取所属地区
  get_select_zone_list(input_data): Observable<any> {
    let params = new HttpParams()
      .set('title', input_data.title)
      .set('zone_type', input_data.zone_type)
    return this.http.get<any>(`${environment.apiUrl}/common/get_select_zone_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }

  //获取行业类别列表
  get_select_indus_type_list(input_data): Observable<any> {
    let params = new HttpParams()
      .set('title', input_data.title)
      .set('indus_type', input_data.indus_type)
    return this.http.get<any>(`${environment.apiUrl}/common/get_select_indus_type_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }


  //获取经济类型编码
  get_select_economy_list(input_data): Observable<any> {
    let params = new HttpParams()
      .set('title', input_data.title)
      .set('type', input_data.type)
    return this.http.get<any>(`${environment.apiUrl}/common/get_select_economy_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }


  //获取企业规模
  get_grpt_size_list(): Observable<any> {
    let params = new HttpParams()
    return this.http.get<any>(`${environment.apiUrl}/common/get_grpt_size_list/`, {})
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }

  //获取工种名称
  get_work_type_list(title): Observable<any> {
    let params = new HttpParams()
      .set('title', title)
    return this.http.get<any>(`${environment.apiUrl}/common/get_work_type_list/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  //通过value获取工种名称
  get_work_type_by_code(code): Observable<any> {
    let params = new HttpParams()
      .set('value', code)
    return this.http.get<any>(`${environment.apiUrl}/common/get_work_type_by_code/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

   //通过value获取工种名称
   get_dept_by_code(code): Observable<any> {
    let params = new HttpParams()
      .set('value', code)
    return this.http.get<any>(`${environment.apiUrl}/common/get_dept_by_code/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  //查询症状一级标题
  get_symptom_list() {
    return this.http.get<any>(`${environment.apiUrl}/common/get_symptom_list_level1/`, {})
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }


    //查询症状一级标题,二级标题
    get_symptom_list_level2() {
      return this.http.get<any>(`${environment.apiUrl}/common/get_symptom_list_level2/`, {})
        .pipe(
          map(res => {
            
            return res
          }), catchError(handleError)
        )
    }

  //通过分组id，查询疑似职业病
  get_yszyb_list(groupid) {
    let params = new HttpParams()
      .set('groupid', groupid)
    return this.http.get<any>(`${environment.apiUrl}/common/get_yszyb_list_by_groupid/`, { params })
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }

  //通过分组id，查询职业禁忌证
  get_zyjjz_list(groupid) {
    let params = new HttpParams()
      .set('groupid', groupid)
    return this.http.get<any>(`${environment.apiUrl}/common/get_zyjjz_list_by_groupid/`, { params })
      .pipe(
        map(res => {
          
          return res
        }), catchError(handleError)
      )
  }

  //获取xml文档，并下载
  download_xml(path: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xml');
    let params = new HttpParams().set('tfile_name', path)
    return this.http.get(`${environment.apiUrl}/download_file_xml/`, { params, headers: headers, responseType: 'blob' as 'json' })
      .subscribe(response => { this.downLoadFile(response, "application/xml") });
  }


  //下载文件
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xml';
    a.click();
    window.URL.revokeObjectURL(url);
  }
  //预览xml
  previewFile_xml(path: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/xml');
    let params = new HttpParams().set('tfile_name', path)
    return this.http.get(`${environment.apiUrl}/download_file_xml/`, { params, headers: headers, responseType: 'blob' as 'json' })
      .subscribe(response => { this.previewLoadFile(response, "application/xml") });
  }

  //获取zip包
  get_zip(file_name) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/zip');
    let params = new HttpParams().set('tfile_name', file_name)
    return this.http.get(`${environment.apiUrl}/download_file_zip/`, { params, headers: headers, responseType: 'blob' as 'json' })
    .pipe(
      map((blobData:Blob) => {
        
        return blobData
      }), catchError(handleError)
    )
  }
  //上传文件到网报接口
  // upload_net_official(blobData:Blob,file_name:string,type_name){
  //   const formData = new FormData();
  //   formData.append('dataFile', blobData, file_name);
  //   formData.append('unitCode', '2113820001');
  //   formData.append('password', '8132c317b185408fa6f143da27629fe1');
  //   // 发送POST请求
  //   let url_end =''
  //   if (type_name=='企业'){
  //     url_end = 'crptUpService'
  //   }else{
  //     url_end = 'zwbhkService'
  //   }
  //  return this.http.post<any>(`${environment.api}${url_end}`, formData)
  //   .pipe(
  //     map(res=>{
  //       return res
  //     }),catchError(ApiHandleError)
  //   )
  // }

  //上传文件到网报接口
  upload_net_official(blobData:Blob,file_name:string,type_name){
    const formData = new FormData();
    
    formData.append('dataFile', blobData, file_name);
    formData.append('unitCode', '2113820001');
    formData.append('password', '8132c317b185408fa6f143da27629fe1');
    // 发送POST请求
    
    let url_end =''
    if (type_name=='企业'){
      url_end = 'crptUpService'
    }else{
      url_end = 'zwbhkService'
    }
    formData.append('url_end',url_end);
    return this.http.post<any>(`http://127.0.0.1:8018/forward_request/`, formData)
    .pipe(
      map(res=>{
        
        return res
      }),catchError(ApiHandleError)
    )
  }
  
    //download_sheet
    download_sheet(file_name: string) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      let params = new HttpParams().set('tfile_name', file_name)
      return this.http.get(`${environment.apiUrl}/download_file_xslx/`, { params, headers: headers, responseType: 'blob' as 'json' })
        .subscribe(response => { this.previewLoadFile_rename(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",file_name) });
    }

    previewLoadFile_rename(data: any, type: string,file_name:string) {
      let blob = new Blob([data], { type: type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');  
      a.href = url;  
      a.download = file_name; // 你可以根据需要更改文件名  
      document.body.appendChild(a);  
      a.click();  
      document.body.removeChild(a);  
    }

    get_version(): Observable<any> {
      const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache'); 
      return this.http.get(`${environment.apiUrl}/get_version/`,{headers})
        .pipe(
          map(res => {
            return res
          }),
          catchError(handleError)
        )
    }

    // 查询团检客户下拉列表
    get_group_unit_List_by_part(value:string=''): Observable<any> {
      let params = new HttpParams()
      .set('physical_type', sessionStorage.getItem('physical_type'))
      .set('part_value',value)
      return this.http.get<any>(`${environment.apiUrl}/basic/get_group_unit_List_by_part/`, {params})
        .pipe(
          map(res => {
            return res
          }),
          catchError(handleError)
        )
    }


  //获取无纸化返回的pdf结果
  get_nopaper_pdf_by_path(pdfUrl: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    let params = new HttpParams().set('pdf_url', pdfUrl)
    return this.http.get<Blob>(`${environment.apiUrl}/get_nopaper_pdf_by_path`, {params,observe: 'response',responseType: 'blob' as 'json',headers: headers});
  }

  
  gener_error_zip_file(input_data,timestamp:string){
    console.log(input_data);
    
    return this.http.post<any>(`${environment.apiUrl}/common/gener_error_zip_file/`, input_data, {...this.httpOptions,responseType: 'blob' as 'json'})
    .pipe(
      map(res => {
        saveAs(res,'网报错误日志'+timestamp+'.zip')
        return true
      }), catchError(handleError)
    )
  }
}
