import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogData } from 'src/app/dialog-models/base-dialog/base-dialog.component';
import { AskCheckService } from 'src/app/home/content/ask-check/ask-check.service';
import { SingleCheckService } from 'src/app/home/content/single-check/single-check.service';
import { SymptomAddComponent } from 'src/app/home/sysset/combo/combo-detail/symptom-add/symptom-add.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-ask-check-detail',
  templateUrl: './ask-check-detail.component.html',
  styleUrls: ['./ask-check-detail.component.css'],
  providers: [DatePipe],

})
export class AskCheckDetailComponent implements OnInit {
  session_physical_type = sessionStorage.getItem('physical_type')
  group_person: any
  constructor(
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private singleCheckService: SingleCheckService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private askCheckService: AskCheckService,
    private dialogRef: MatDialogRef<AskCheckDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    
    this.data_loading = true
    //获取体检项目信息，并跳转到详情页面
    let input_data = this.data.input_data
    let row = this.data.person
    this.group_person = this.data.person
    
    this.askCheckService.search_ask_check_group_and_order_group_by_id(input_data).subscribe(res => {
      if (res.flag) {
        
        this.order_group = res.value.order_group

        let data = res.value.ask_check_group
        
        this.ask_check_group = this.fb.group({
          _id: data._id,
          person_name: row.person_name,
          anonymity: row.anonymity,
          order_id: data.order_id,
          group_id: data.group_id,
          ask_check_doc: [data.ask_check_doc, [Validators.required]],
          ask_check_date: [data.ask_check_date, [Validators.required]],
          basic_info: this.fb.group(data.basic_info),
          personalLifeHistory: this.fb.group({
            menstrualHistory: this.fb.group(data.personalLifeHistory.menstrualHistory),
            childbearingHistory: this.fb.group(data.personalLifeHistory.childbearingHistory),
            tobaccoAndWineHistory: this.fb.group(data.personalLifeHistory.tobaccoAndWineHistory),
            familyHistory: data.personalLifeHistory.familyHistory,
          }),
          zys_table_data: this.fb.array(data.zys_table_data.map(m => this.fb.group({
            his_type: m.his_type,//1、放射；2、非放射
            start_end_date: [m.start_end_date, [Validators.required, Validators.pattern(/^\d{4}\.([1-9]|1[0-2])-\d{4}\.([1-9]|1[0-2])$/)]],
            work_dept: [m.work_dept, [Validators.required]],
            work_shop: m.work_shop,
            work_type: m.work_type,
            hazard_factors: m.hazard_factors,
            safe_measure: m.safe_measure,
            freq: m.freq,
          }))),
          jwbs_table_data: this.fb.array(data.jwbs_table_data.map(m => this.fb.group({
            disease_name: [m.disease_name, [Validators.required]],
            diagnose_date: m.diagnose_date ? new Date(m.diagnose_date) : '',
            diagnose_dept: m.diagnose_dept,
            process: m.process,
            end: m.end,
          }))),
          other_content: data.other_content,
          symptomData1: this.fb.array(data.symptomData1.map(m => this.fb.group(m))),
          symptomData2: this.fb.array(data.symptomData2.map(m => this.fb.group(m))),
          symptomData3: this.fb.array(data.symptomData3.map(m => this.fb.group(m)
          ))
        })
        this.datasource_zy = new MatTableDataSource(
          this.zys_table_data.controls
        )
        this.datasource_jwbs = new MatTableDataSource(
          this.jwbs_table_data.controls
        )
        this.datasource_sym_data1 = new MatTableDataSource(
          this.symptomData1.controls
        )
        this.datasource_sym_data2 = new MatTableDataSource(
          this.symptomData2.controls
        )
        this.datasource_sym_data3 = new MatTableDataSource(
          this.symptomData3.controls
        )
        
        let input_data = {
          'meals': res.value.order_group.meals
        }
        this.singleCheckService.search_combo_others_for_person(input_data).subscribe(r => {
          if (r.flag) {
            this.occupational_diseases = r.value.occupational_diseases
            this.occupational_taboo = r.value.occupational_taboo
          } else {
            this.notifyService.showError(r.value, '错误')
          }
        })
        this.data_loading = false
       
      } else {
        this.notifyService.showError(res.value, '错误')
        this.data_loading = false
      }
    })
  }
  loading: boolean = false
  order_group = {
    items: [],
    meals: [],
    total_sale_price: 0,
    total_discount_price: 0,
    name: ''
  }
  ask_check_group: FormGroup
  group_item_form: FormGroup

  displayedColumns: string[] = ['index', 'name', 'detail', 'unit_name', 'unusual'];
  displayedColumns_zy: string[] = [ 'start_end_date', 'work_dept', 'work_shop', 'work_type', 'hazard_factors', 'safe_measure']
  displayedColumns_zy_title = [ '起止日期', '工作单位', '车间', '工种', '危害因素', '防护措施']  //接触频次：默认48小时/每周
  displayedColumns_jwbs: string[] = [ 'disease_name', 'diagnose_date', 'diagnose_dept', 'process', 'end']
  displayedColumns_jwbs_title = [ '患病名称', '诊断日期', '诊断单位', '治疗经过', '转归']
  displayenColumns_symptom: string[] = ['projectName', 'degree']
  displayenColumns_symptom_other: string[] = [ 'projectName', 'degree']
  dataSource = new MatTableDataSource<any>();
  userid = ''
  username = ''
  occupational_diseases = ''
  occupational_taboo = ''
  com_disabled = true
  datasource_zy = new MatTableDataSource<any>();
  datasource_jwbs = new MatTableDataSource<any>();
  datasource_sym_data1 = new MatTableDataSource<any>();
  datasource_sym_data2 = new MatTableDataSource<any>();
  datasource_sym_data3 = new MatTableDataSource<any>();
  new_data_flag = false
  data_loading = false
  ngOnInit(): void {
    this.userid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opcode"] : ''//用户ID
    this.username = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opname"] : ''//姓名
    //问诊
    this.ask_check_group = this.fb.group({
      _id: '',
      person_name: '',
      anonymity: 0,
      order_id: '',
      group_id: '',
      ask_check_doc: [this.userid + ':' + this.username, [Validators.required]],
      ask_check_date: [new Date(), [Validators.required]],
      basic_info: this.fb.group({
        workExperienceYears: '',
        workExperienceMonths: '',
        maritalStatus: '',
        educationLevel: '',
        exposureYears: [''],
        exposureMonths: [''],
        jobTitle: '',
        otherJobTitle: '',
        homeAddress: '',
        department: '',
      }),
      personalLifeHistory: this.fb.group({
        menstrualHistory: this.fb.group({
          isxmns: '',
          menarcheAge: '',
          menstrualPeriod: '',
          cyc: '',
          menopauseAge: ''
        }),
        childbearingHistory: this.fb.group({
          numberOfSons: '',
          numberOfDaughters: '',
          numberOfAbortions: '',
          numberOfPrematureBirths: '',
          numberOfStillbirths: '',
          numberOfAbnormalPregnancies: ''
        }),
        tobaccoAndWineHistory: this.fb.group({
          smokingStatus: '', //2.0版本--弃用
          cigarettesPerDay: '', //2.0版本--弃用
          smokingAge: '', //2.0版本--弃用
          //3.0版本修改-------------
          smksta: '4', //吸烟史-吸烟情况
          smkdayble: 0, // 平均每天吸烟量
          smkyerqty: 0, //吸烟史-年
          smkmthqty: 0, //吸烟史-月
          //-----------------------
          drinkingStatus: '',
          drinkingType: '',
          alcoholConsumptionPerDay: '',
          drinkingAge: '',
        }),
        familyHistory: '',
      }),
      zys_table_data: this.fb.array([]),
      jwbs_table_data: this.fb.array([]),
      other_content: '',
      symptomData1: this.fb.array([]),
      symptomData2: this.fb.array([]),
      symptomData3: this.fb.array([])
    })
    this.datasource_zy = new MatTableDataSource(
      this.zys_table_data.controls
    )
    this.datasource_jwbs = new MatTableDataSource(
      this.jwbs_table_data.controls
    )
    this.datasource_sym_data1 = new MatTableDataSource(
      this.symptomData1.controls
    )
    this.datasource_sym_data2 = new MatTableDataSource(
      this.symptomData2.controls
    )
    this.datasource_sym_data3 = new MatTableDataSource(
      this.symptomData3.controls
    )


  }


  get base_projects() {
    return this.group_item_form.get("base_projects") as FormArray;
  }
  get zys_table_data() {
    return this.ask_check_group.get('zys_table_data') as FormArray;
  }
  get jwbs_table_data() {
    return this.ask_check_group.get('jwbs_table_data') as FormArray;
  }
  get symptomData1() {
    return this.ask_check_group.get('symptomData1') as FormArray;
  }
  get symptomData2() {
    return this.ask_check_group.get('symptomData2') as FormArray;
  }
  get symptomData3() {
    return this.ask_check_group.get('symptomData3') as FormArray;
  }

  get tobaccoAndWineHistory() {
    return this.ask_check_group.get('personalLifeHistory').get('tobaccoAndWineHistory') as FormGroup
  }
  get_positive(){
    let data1 =  this.symptomData1.value.filter(f=>f.degree =='+')
    let data2 = this.symptomData2.value.filter(f=>f.degree =='+')
    let data3 = this.symptomData3.value
    return [...data1,...data2,...data3]
  }
}
