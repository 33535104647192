import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sexStates'
})
export class SexStatesPipe implements PipeTransform {

  transform(value:number): string {
    if (value==0){
      return '未知的性别'
    }else if(value==1){
      return '男'
    }else if(value==2){
      return '女'
    }else if(value==9){
      return '未说明性别'
    }
  }

}