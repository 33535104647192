import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './data-services/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './home/users/users.component';
import { RouteReuseStrategy } from '@angular/router';
import { SimpleReuseStrategy } from './utils/SimpleReuseStrategy';
import { TestDocComponent } from './home/test-doc/test-doc.component';
import { PersonInfoLoadModule } from './home/data-upload/person-info-load/person-info-load.module';
import { RecheckModule } from './home/statistics/recheck/recheck.module';
import { ProResultSuggestModule } from './home/statistics/proResultSuggest/proResultSuggest.module';
import { ProPositiveResultPLModule } from './home/statistics/proPositiveResultPL/proPositiveResultPL.module';
import { SingleCheckSumModule } from './home/statistics/singleCheckSum/singleCheckSum.module';
import { SingleDiagSumModule } from './home/statistics/singleDiagSum/singleDiagSum.module';
import { PeisFeeModule } from './home/statistics/peisFee/peisFee.module';
import { ComboSumModule } from './home/statistics/comboSum/comboSum.module';
import { ProHazardSumModule } from './home/statistics/proHazardSum/proHazardSum.module';
import { CheckingItemSumModule } from './home/statistics/checkingItemSum/checkingItemSum.module';
import { HealthCheckResultSumModule } from './home/statistics/healthCheckResultSum/healthCheckResultSum.module';
import { CheckPersonListModule } from './home/statistics/checkPersonList/checkPersonList.module';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { keep: true, roles: ['管理员', '体检科', '分检科室', '总检科室', '前台', '财务','问诊科室'] } },
  { path: 'login', component: LoginComponent },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuardService] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  //开始修改日志页面
  { path: 'test-doc', component: TestDocComponent },
  { path: 'group-unit', loadChildren: () => import('./home/basic/group-unit/group-unit.module').then(m => m.GroupUnitModule) },
  { path: 'group-order', loadChildren: () => import('./home/basic/group-order/group-order.module').then(m => m.GroupOrderModule) },
  { path: 'basic/group-nav-sheets-print', loadChildren: () => import('./home/basic/group-navigation-sheets-print/group-navigation-sheets-print.module').then(m => m.GroupNavigationSheetsPrintModule) },
  { path: 'individual-order', loadChildren: () => import('./home/basic/individual-order/individual-order.module').then(m => m.IndividualOrderModule) },
  { path: 'sysset/dept-set', loadChildren: () => import('./home/sysset/dept-set/dept-set.module').then(m => m.DeptSetModule) },
  { path: 'sysset/base-project', loadChildren: () => import('./home/sysset/base-project/base-project.module').then(m => m.BaseProjectModule) },
  { path: 'sysset/combo', loadChildren: () => import('./home/sysset/combo/combo.module').then(m => m.ComboModule) },
  { path: 'sysset/portfolio-project', loadChildren: () => import('./home/sysset/portfolio-project/portfolio-project.module').then(m => m.PortfolioProjectModule) },
  { path: 'sysset/base-agreement', loadChildren: () => import('./home/sysset/base-agreement/base-agreement.module').then(m => m.BaseAgreementModule) },
  { path: 'content/reprint', loadChildren: () => import('./home/content/reprint/reprint.module').then(m => m.ReprintModule) },
  { path: 'content/confirm-inspect', loadChildren: () => import('./home/content/confirm-inspect/confirm-inspect.module').then(m => m.ConfirmInspectModule) },
  { path: 'content/register', loadChildren: () => import('./home/content/pe-register/pe-register.module').then(m => m.PeRegisterModule) },
  { path: 'content/askcheck', loadChildren: () => import('./home/content/ask-check/ask-check.module').then(m => m.AskCheckModule) },
  { path: 'content/singlecheck', loadChildren: () => import('./home/content/single-check/single-check.module').then(m => m.SingleCheckModule) },
  { path: 'content/totalcheck', loadChildren: () => import('./home/content/total-check/total-check.module').then(m => m.TotalCheckModule), data: { roles: ['管理员', '体检科', '分检科室', '总检科室', '前台', '财务','问诊科室'] } },
  { path: 'basic/group-unit-summary', loadChildren: () => import('./home/basic/group-unit-summary/group-unit-summary.module').then(m => m.GroupUnitSummaryModule) },
  { path: 'basic/peresult', loadChildren: () => import('./home/basic/pe-result/pe-result.module').then(m => m.PeResultModule) },
  { path: 'basic/peplan', loadChildren: () => import('./home/basic/pe-person-plan/pe-person-plan.module').then(m => m.PePersonPlanModule) },
  { path: 'sysset/term-set', loadChildren: () => import('./home/sysset/term-set/term-set.module').then(m => m.TermSetModule) },
  { path: 'sysset/diag-set', loadChildren: () => import('./home/sysset/diag-set/diag-set.module').then(m => m.DiagSetModule) },
  { path: 'sysset/authority-management', loadChildren: () => import('./home/sysset/authority-management/authority-management.module').then(m => m.AuthorityManagementModule) },
  { path: 'content/term-import', loadChildren: () => import('./home/content/term-import/term-import.module').then(m => m.TermImportModule) },
  { path: 'cost/individual-order', loadChildren: () => import('./home/cost/individual-order/individual-order.module').then(m => m.IndividualOrderModule) },
  { path: 'cost/individual-order-sum', loadChildren: () => import('./home/cost/individual-order-sum/individual-order-sum.module').then(m => m.IndividualOrderSumModule) },
  { path: 'cost/group-unit-orders', loadChildren: () => import('./home/cost/group-unit-orders/group-unit-orders.module').then(m => m.GroupUnitOrdersModule) },
  { path: 'cost/check-transfer', loadChildren: () => import('./home/cost/check-transfer/check-transfer.module').then(m => m.CheckTransferModule) },
  { path: 'content/lis-check', loadChildren: () => import('./home/content/lis-check/lis-check.module').then(m => m.LisCheckModule) },
  { path: 'group-persons', loadChildren: () => import('./home/basic/group-persons/group-persons.module').then(m => m.GroupPersonsModule) },

  //网报
  { path: 'basic/enterprise-information-upload', loadChildren: () => import('./home/data-upload/enterprise-info-load/enterprise-info-load.module').then(m => m.EnterpriseInfoLoadModule) },
  { path: 'basic/test-upload', loadChildren: () => import('./home/data-upload/test-upload/test-upload.module').then(m => m.TestUploadModule) },
  { path: 'basic/person-infor-load', loadChildren: () => import('./home/data-upload/person-info-load/person-info-load.module').then(m => PersonInfoLoadModule) },
  //统计
  { path: 'statistics/recheck', loadChildren: () => import('./home/statistics/recheck/recheck.module').then(m => RecheckModule) },
  { path: 'statistics/proResultSuggest', loadChildren: () => import('./home/statistics/proResultSuggest/proResultSuggest.module').then(m => ProResultSuggestModule) },
  { path: 'statistics/proPositiveResultPL', loadChildren: () => import('./home/statistics/proPositiveResultPL/proPositiveResultPL.module').then(m => ProPositiveResultPLModule) },
  { path: 'statistics/singleCheckSum', loadChildren: () => import('./home/statistics/singleCheckSum/singleCheckSum.module').then(m => SingleCheckSumModule) },
  { path: 'statistics/singleDiagSum', loadChildren: () => import('./home/statistics/singleDiagSum/singleDiagSum.module').then(m => SingleDiagSumModule) },
  { path: 'statistics/peisFee', loadChildren: () => import('./home/statistics/peisFee/peisFee.module').then(m => PeisFeeModule) },
  { path: 'statistics/comboSum', loadChildren: () => import('./home/statistics/comboSum/comboSum.module').then(m => ComboSumModule) },
  { path: 'statistics/proHazardSum', loadChildren: () => import('./home/statistics/proHazardSum/proHazardSum.module').then(m => ProHazardSumModule) },
  { path: 'statistics/checkingItemSum', loadChildren: () => import('./home/statistics/checkingItemSum/checkingItemSum.module').then(m => CheckingItemSumModule) },
  { path: 'statistics/healthCheckResultSum', loadChildren: () => import('./home/statistics/healthCheckResultSum/healthCheckResultSum.module').then(m => HealthCheckResultSumModule) },
  { path: 'statistics/checkPersonList', loadChildren: () => import('./home/statistics/checkPersonList/checkPersonList.module').then(m => CheckPersonListModule) }


];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    { provide: RouteReuseStrategy, useClass: SimpleReuseStrategy }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
