import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yOrN'
})
export class YOrNPipe implements PipeTransform {

  transform(value:number): unknown {
    let result = ''
    if(value==0){
      result = ''
    }else if (value==1){
      result = '是'
    }else{
      result = '未知错误'
    }
    return result;
  }
}
