import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameHide'
})
export class NameHidePipe implements PipeTransform {

  transform(value:string): string {
   return '*'+value.substr(1 - value.length);
  }

}
