import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-load-error',
  templateUrl: './load-error.component.html',
  styleUrls: ['./load-error.component.css']
})
export class LoadErrorComponent implements OnInit {
  error_list=[]
  constructor(
    public dialogRef: MatDialogRef<LoadErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService:CommonService,
    private notifyService: NotificationService,

  ) { 
    this.error_list = data.error_list
  }

  ngOnInit(): void {
    
  }
  download_file(path){
    if (path){
      this.commonService.download_xml(path)
    }else{
      this.notifyService.showError('文件名不存在','错误')
    }
  }

  previewFile(path){
    if (path){
      this.commonService.previewFile_xml(path)
    }else{
      this.notifyService.showError('文件名不存在','错误')
    }
  }

}
