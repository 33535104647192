import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.css']
})
export class BasicComponent implements OnInit {

  session_physical_type = sessionStorage.getItem('physical_type')

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  async onMenuAction(tpage: string) {
    switch (tpage) {
      case "imp_patient": {
        this.router.navigate(['/pat-imp']);
        break;
      }
      case "sch_patient": {
        this.router.navigate(['/pat-sch']);
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  // 导入临床及门诊患者
  imp_patient() {
    this.router.navigate(["pat-imp"]);
  }
  sch_patient() { //患者信息查询
    this.router.navigate(["pat-sch"]);
  }
  sch_order() { //医嘱与费用
    this.router.navigate(["pat-order"]);
  }

  inhos_his() { //临床患者
    this.router.navigate(["proc-bf-visit-prn"]);
  }
  outpatient_his() { //麻醉门诊患者
    this.router.navigate(["proc-bf-visit"]);
  }
  otherpat_his() { //其他患者
    this.router.navigate(["proc-bf-accept"]);
  }

  retunHome() { //返  回
    this.router.navigate(["/home"]);
  }

  enterprise_information_upload(){
    this.router.navigate(["enterprise-information-upload"]);
  }

  person_information_upload(){
    this.router.navigate(["person-information-upload"]);

  }
}

