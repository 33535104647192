import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { NotificationService } from 'src/app/utils/notification.service';
import { SingleCheckService } from '../../content/single-check/single-check.service';

@Component({
  selector: 'app-pe-result',
  templateUrl: './pe-result.component.html',
  styleUrls: ['./pe-result.component.css']
})
export class PeResultComponent implements OnInit {
  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;
  @ViewChild('list') list:any
  @ViewChild('detail') detail:any
  session_physical_type = sessionStorage.getItem('physical_type')
  constructor(
    private fb: FormBuilder,
    private singleCheckService:SingleCheckService,
    private notifyService: NotificationService,

  ) { }

  ngOnInit(): void {
  }
  onRowClicked(row: any) {
    
    //赋值人员信息
    // row.test_num = row.id.toString().padStart(13,'0')
    this.detail.group_person=this.fb.group(row)
    let group_id  =row.group_id
    //获取体检项目信息，并跳转到详情页面
    this.detail.get_yszyb_list(group_id)
    this.detail.get_zyjjz_list(group_id)
    this.detail.loading_count = 0
    this.singleCheckService.search_order_group_by_id({"group_id":group_id}).subscribe(res=>{
      this.detail.loading_count +=1
      if(res.flag){
         //---获取 化验条码整理后的分检项目信息
         let input_data1 = {
          '_id': row.id
        }
        this.singleCheckService.search_single_check_items_by_id(input_data1).subscribe(re => {
          
          if (re.flag) {
            let items = re.value
            this.detail.order_group = res.value
            
            if (items.length > 0) {
              this.detail.order_group.items = items
            }
            this.detail.group_item_form.get("single_item").setValue(res.value.items[0])
            this.detail.item_search_all_data(res.value.items[0],1)
          } else {
            this.notifyService.showError(res.value, '错误')
          }
        })
        this.detail.search_total_check_result_by_id()
        let input_data={
          'meals':res.value.meals
        }
        this.singleCheckService.search_combo_others_for_person(input_data).subscribe(r=>{
          this.detail.loading_count +=1
          if(r.flag){
            this.detail.occupational_diseases=r.value.occupational_diseases
            this.detail.occupational_taboo = r.value.occupational_taboo
            this.detail.com_disabled = true
          }else{
            this.notifyService.showError(r.value,'错误')
          }
        })
      }else{
        this.notifyService.showError(res.value,'错误')
      }
    })
    setTimeout(() => {
      this.tabGroup.selectedIndex=1
    }, 500);
    
  }

}
