<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color:blue"><b>查询与统计</b></h1>
    <h3><b>体检阳性结果汇总</b></h3>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" [formGroup]="search_form">
    <mat-form-field appearance="fill" style="width: 19%;">
        <mat-label>体检登记时间</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start_date" placeholder="Start date">
            <input matEndDate formControlName="end_date" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="search_form.controls.start_date.hasError('matStartDateInvalid')">Invalid start
            date</mat-error>
        <mat-error *ngIf="search_form.controls.end_date.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 39%;">
        <mat-label>团检单位名称</mat-label>
        <input matInput placeholder="请选择人员所在企业名称" formControlName="group_unit_id" [matAutocomplete]="enterprise" />
        <mat-autocomplete requireSelection #enterprise="matAutocomplete" [displayWith]="displayFn_e.bind(this)">
            <mat-option *ngFor="let e of unit_list |async" [value]="e">{{e.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>订单类型</mat-label>
        <mat-select formControlName="order_type">
            <mat-option value="">全部</mat-option>
            <mat-option value="团检">团检</mat-option>
            <mat-option value="个检">个检</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%" *ngIf="search_form.get('physical_type').value=='职业体检'">
        <mat-label>是否复检</mat-label>
        <mat-select formControlName="is_recheck">
            <mat-option value="0">否</mat-option>
            <mat-option value="1">是</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width:19%" appearance="fill" *ngIf="search_form.get('physical_type').value=='职业体检'">
        <mat-label>在岗状态</mat-label>
        <mat-select multiple formControlName="work_state_codes">
            <mat-option *ngFor="let i of work_state_list" [value]="i.value">{{i.title}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>人员体检进度</mat-label>
        <mat-select formControlName="is_pass" multiple>
            <mat-option value="2">体检中</mat-option>
            <mat-option value="3">总检中</mat-option>
            <mat-option value="4">总检完成</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>性别</mat-label>
        <mat-select formControlName="person_gender" multiple>
            <mat-option value=1>男</mat-option>
            <mat-option value=2>女</mat-option>
        </mat-select>
    </mat-form-field>
    <div style="width:39%">
        <nz-slider [nzMarks]="marks" nzRange formControlName="age_range"></nz-slider>
        <nz-input-group nzPrefix="年龄范围：" nzSuffix="岁">
            <nz-input-number [formControl]="min_age" [nzMin]="0" [nzMax]="100" [nzStep]="1"
                style="width:40%"></nz-input-number>
            <nz-input-number [formControl]="max_age" [nzMin]="0" [nzMax]="100" [nzStep]="1"
                style="width:40%"></nz-input-number>
        </nz-input-group>
    </div>
    <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%"
        *ngIf="search_form.get('physical_type').value=='职业体检'">
        <mat-form-field appearance="fill" style="width: 19%;">
            <mat-label>危害因素查询</mat-label>
            <mat-select (selectionChange)="hazard_search_type_change($event)" [value]="hazard_search_type">
                <mat-option value="1">根据危害因素种类查询</mat-option>
                <mat-option value="2">根据危害因素查询</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:79%" *ngIf="hazard_search_type=='1'">
            <mat-label>危害类型</mat-label>
            <mat-select formControlName="hazard_factor_types" (selectionChange)="type_select($event)" multiple>
                <mat-option *ngFor="let i of type_list" [value]="i.id">{{i.type_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width:79%" appearance="fill" (dblclick)="hazard_factors_add()"
            *ngIf="hazard_search_type=='2'">
            <mat-label>危害因素(双击添加)</mat-label>
            <mat-chip-list formArrayName="hazard_factors">
                <mat-chip *ngFor="let hazard_factor of hazard_factors.controls;let j =index" selectable removable
                    (removed)="delete_hazard_factor(j)">
                    {{hazard_factor.get('type_name').value}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill" style="width: 99%;" *ngIf="search_form.get('physical_type').value=='职业体检'">
        <mat-label>工种名称</mat-label>
        <mat-chip-list #chipList8>
            <mat-chip *ngFor="let type of work_types.controls;let i=index" selectable removable
                (removed)="remove_form_list_items(i)" [matAutocompon]>
                {{type.get('title').value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-label>|</mat-label>
            <input placeholder="请选择工种名称,输入部分内容可检索......" matInput [matChipInputFor]="chipList8"
                [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add_2($event)" [matAutocomplete]="work_type"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [formControl]="work_type_control">
            <mat-autocomplete requireSelection #work_type="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let w of work_type_list |async" [value]="w"
                    (onSelectionChange)="add_effect($event)">{{w.value}}:{{w.title}}</mat-option>
            </mat-autocomplete>
        </mat-chip-list>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 99%;">
        <mat-label>体检科室</mat-label>
        <mat-select formControlName="office_ids" multiple (selectionChange)="office_id_select($event)">
            <mat-option value="" (onSelectionChange)="select_all($event)">所有科室</mat-option>
            <mat-option *ngFor="let i of his_dept_list"
                [value]="i.id">{{i.section_code}}:{{i.section_name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width:99%" appearance="fill" (dblclick)="recheck_item_add()">
        <mat-label>检查项目(双击添加)</mat-label>
        <mat-chip-list formArrayName="check_items">
            <mat-chip *ngFor="let item of check_items.controls;let j =index" selectable removable
                (removed)="delete_recheck_item(j)">
                {{item.get('short_name').value}}|{{item.get('name').value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>姓名</mat-label>
        <input type="text" matInput placeholder="请输入部分内容.." formControlName="person_name">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>身份证号</mat-label>
        <input type="text" matInput placeholder="请输入部分内容.." formControlName="id_card">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>体检编号</mat-label>
        <input type="text" matInput placeholder="请输入部分内容.." formControlName="test_num">
    </mat-form-field>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <button mat-raised-button color="primary" (click)="search()">开始统计</button>
    <button mat-stroked-button color="warn" (click)="replay()" matTooltip="重置搜索项"><mat-icon>replay</mat-icon>重置</button>
    <button mat-raised-button color="primary" (click)="export_excel()">导出为excel</button>

</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" style="width: 100%;">
    <table>
        <tr style="font-size: 18px;">
            <th>总人数：{{recheck_person_count}}</th>
        </tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div style="min-height:100px;overflow: auto;max-height: 80vh; width: 100%; ">
        <table mat-table [dataSource]='dataSource' matSort (matSortChange)="sortChange($event)" style="width: 100%;">
            <ng-container matColumnDef="group_unit_name">
                <th *matHeaderCellDef mat-sort-header>所属单位</th>
                <td *matCellDef="let sa">{{sa.group_unit_name}}</td>
            </ng-container>
            <ng-container matColumnDef="order_id">
                <th *matHeaderCellDef mat-sort-header>订单号</th>
                <td *matCellDef="let sa">{{sa.order_id}}</td>
            </ng-container>
            <ng-container matColumnDef="order_type">
                <th *matHeaderCellDef mat-sort-header>订单类型</th>
                <td *matCellDef="let sa">{{sa.order_type}}</td>
            </ng-container>
            <ng-container matColumnDef="test_num">
                <th *matHeaderCellDef mat-sort-header>体检号</th>
                <td *matCellDef="let sa">{{sa.test_num}}</td>
            </ng-container>
            <ng-container matColumnDef="last_test_num">
                <th *matHeaderCellDef mat-sort-header>上次体检号</th>
                <td *matCellDef="let sa">{{sa.last_test_num}}</td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th *matHeaderCellDef mat-sort-header>姓名</th>
                <td *matCellDef="let sa">{{sa.name}}</td>
            </ng-container>
            <ng-container matColumnDef="id_card">
                <th *matHeaderCellDef mat-sort-header>身份证号</th>
                <td *matCellDef="let sa">{{sa.id_card}}</td>
            </ng-container>
            <ng-container matColumnDef="gender">
                <th *matHeaderCellDef mat-sort-header>性别</th>
                <td *matCellDef="let sa">{{sa.gender|sexStates}}</td>
            </ng-container>
            <ng-container matColumnDef="age">
                <th *matHeaderCellDef mat-sort-header>年龄</th>
                <td *matCellDef="let sa">{{sa.age}}</td>
            </ng-container>
            <ng-container matColumnDef="hazard_factors">
                <th *matHeaderCellDef mat-sort-header>危害因素</th>
                <td *matCellDef="let sa">{{sa.hazard_factors}}</td>
            </ng-container>
            <ng-container matColumnDef="work_state">
                <th *matHeaderCellDef mat-sort-header>在岗状态</th>
                <td *matCellDef="let sa">{{sa.work_state}}</td>
            </ng-container>
            <ng-container matColumnDef="work_type">
                <th *matHeaderCellDef mat-sort-header>工种名称</th>
                <td *matCellDef="let sa">{{sa.work_type}}</td>
            </ng-container>
            <ng-container matColumnDef="office_name">
                <th *matHeaderCellDef mat-sort-header>检查科室</th>
                <td *matCellDef="let sa">{{sa.office_name}}</td>
            </ng-container>
            <ng-container matColumnDef="positive_item_name">
                <th *matHeaderCellDef mat-sort-header>项目名称</th>
                <td *matCellDef="let sa">{{sa.positive_item_name}}</td>
            </ng-container>
            <ng-container matColumnDef="positive_result">
                <th *matHeaderCellDef mat-sort-header>阳性结果</th>
                <td *matCellDef="let sa">{{sa.positive_result}}</td>
            </ng-container>
            <ng-container matColumnDef="register_date">
                <th *matHeaderCellDef mat-sort-header>登记时间</th>
                <td *matCellDef="let sa">{{sa.register_date|date:"yyyy-MM-dd HH:mm:ss"}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='displayedColumns;sticky: true'></tr>
            <tr mat-row *matRowDef='let row; columns:displayedColumns'></tr>
        </table>
    </div>
</div>
<br><br><br><br>