import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdcardHidePipe } from '../idcard-hide';
import { JzTypePipe } from '../jz-type.pipe';
import { MarryStatesPipe } from '../marry_states';
import { NameHidePipe } from '../name-hide';
import { OrderStatePipe } from '../order-state.pipe';
import { PePlanPipe } from '../pe-plan.pipe';
import { PhoneHidePipe } from '../phone-hide';
import { PrintStatusPipe } from '../print-status.pipe';
import { RecheckStatusPipe } from '../recheck-status.pipe';
import { RemoveBracketPipe } from '../remove-bracket.pipe';
import { SexStatesPipe } from '../sex-states';
import { UploadStatusPipe } from '../upload-status.pipe';
import { YOrNPipe } from '../y-or-n.pipe';



@NgModule({
  declarations: [
    UploadStatusPipe,
    PePlanPipe,
    MarryStatesPipe,
    RemoveBracketPipe,
    IdcardHidePipe,
    PhoneHidePipe,
    NameHidePipe,
    SexStatesPipe,
    RecheckStatusPipe,
    JzTypePipe,
    PrintStatusPipe,
    YOrNPipe,
    OrderStatePipe,

  ],
  imports: [
    CommonModule,
  ],
  exports:[
    UploadStatusPipe,
    PePlanPipe,
    MarryStatesPipe,
    RemoveBracketPipe,
    IdcardHidePipe,
    PhoneHidePipe,
    NameHidePipe,
    SexStatesPipe,
    RecheckStatusPipe,
    JzTypePipe,
    PrintStatusPipe,
    YOrNPipe,
    OrderStatePipe,
  ]
})
export class PipeModule { }
