<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--术语导入--</h1>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <mat-form-field appearance="fill" style="width:45%">
        <mat-label>输入部分内容以检索....</mat-label>
        <input type="text" matInput [(ngModel)]="content" (ngModelChange)="search()">
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="search()"><mat-icon>search</mat-icon>搜索</button>

</div>
<div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%">

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" style="width: 79%;">
        <button mat-stroked-button color="primary" (click)="add_term_set()"> <mat-icon>add</mat-icon>新增</button>
        <button mat-stroked-button color="primary" (click)="delete_selected()"><mat-icon>delete</mat-icon>批量删除</button>
        <button mat-stroked-button color="primary" (click)="search()"> <mat-icon>refresh</mat-icon>刷新</button>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="1%" style="width: 20%;">
        <button mat-raised-button color="primary" (click)="import()">导入选中</button>
    </div>
</div>
<br>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-table class="drug-insert-table mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="office_name">
        <mat-header-cell *matHeaderCellDef><b>科室名称</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.office_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="inspect_type">
        <mat-header-cell *matHeaderCellDef><b>体检类型</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.inspect_type}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type_name">
        <mat-header-cell *matHeaderCellDef><b>术语类型</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.type_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hazard_factors_text">
        <mat-header-cell *matHeaderCellDef><b>危害因素</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.hazard_factors_text}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="work_state_text">
        <mat-header-cell *matHeaderCellDef><b>在岗状态</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.work_state_text}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef><b>状态</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.status==0?'启用':'停用'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="content">
        <mat-header-cell *matHeaderCellDef><b>术语内容</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.content}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="create_time">
        <mat-header-cell *matHeaderCellDef><b>创建时间</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            {{row.create_time|date: 'yyyy-MM-dd HH:mm:ss'}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="operate">
        <mat-header-cell *matHeaderCellDef><b>操作</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            <button mat-icon-button matTooltip="查看" (click)="edit_term_set(row,false)"><mat-icon
                    color="primary">visibility</mat-icon></button>
            <button mat-icon-button matTooltip="修改" (click)="edit_term_set(row,true)"><mat-icon
                    color="primary">edit</mat-icon></button>
            <button mat-icon-button matTooltip="删除" (click)="delete_item(row)"><mat-icon
                    color="warn">delete</mat-icon></button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [length]="dataSource.length" [pageSize]="15" [pageSizeOptions]="[15,30, 60]" showFirstLastButtons>
</mat-paginator>
<br>
<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
    <button mat-raised-button color="primary" (click)="import()">导入选中</button>
</div>
<br>
<br>
<br>