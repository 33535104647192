import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-group-unit-select',
  templateUrl: './group-unit-select.component.html',
  styleUrls: ['./group-unit-select.component.css']
})
export class GroupUnitSelectComponent implements OnInit {
  unit_list: Observable<any[]>
  title = ''
  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private notifyService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private matDialogRef: MatDialogRef<GroupUnitSelectComponent>,
  ) { 
    this.title = this.dialog_data.title
  }
  input_form: FormGroup
  @Input() parent: any
  ngOnInit(): void {
    this.input_form = this.fb.group({
      unit: ''
    })
    this.unit_list = this.input_form.get('unit').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.name
          return this._filter_e(title)
        }),
      );
  }
  private _filter_e(title: any): any[] {
    let tl_new = []
    this.commonService.get_group_unit_List_by_part(title).subscribe(res => {
      
      if (res.flag) {
        
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }
  displayFn_e(option: any): string {
    return option && option.name ? option.name : ''
  }
  get unit() {
    
    return this.input_form.get('unit').value.name ? this.input_form.get('unit').value.name : ''
  }
  get unit_id(){
    return this.input_form.get('unit').value.id?this.input_form.get('unit').value.id:''
  }
  submit(){
    this.dialog_data.persons.map(m=>{
      m.dept = this.unit
      m.unit_id = this.unit_id
      return m
    })
    this.matDialogRef.close(this.dialog_data.persons)
  }
}
