<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h2 style="color:red;">上传失败</h2>
</div>
<br>
<table *ngFor="let error of error_list" style="border: 1px black solid; width: 100%;">
    <tr>
        <td style="width:20%">上传时间：</td>
        <td style="width:80%">{{error.record_time}}</td>
    </tr>
    <tr>
        <td>上传主键信息</td>
        <td>{{error.ids}}</td>
    </tr>
    <tr>
        <td style="color:red;">错误信息</td>
        <td style="color:red;">{{error.error_text}}</td>
    </tr>
    <tr>
        <td colspan="2" style="border-top: 1px black solid;">
            <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
                <button mat-button color="primary" (click)="previewFile(error.upload_file_name)">预览</button>
                <button mat-button color="primary" (click)="download_file(error.upload_file_name)">下载</button>
            </div>

        </td>
    </tr>
</table>
<br>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center">
    <button mat-raised-button color="warn" mat-dialog-close="false">确定</button>
</div>