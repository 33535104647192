<!-- 经济类型选择框 -->
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h3>--{{title}}--</h3>
</div>
<div >
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start" fxLayoutGap="1%" style="width: 100% ;" [formGroup]="zone_group">
        <mat-form-field style="width: 24%;" appearance="fill">
            <mat-label></mat-label>
            <mat-select formControlName="level_1"  (selectionChange)="get_select_economy_list($event.value,'2')">
                <mat-option *ngFor="let c of level_1_list" [value] = 'c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field >
        <mat-form-field style="width: 24%;" appearance="fill" *ngIf="level_2_list.length>0">
            <mat-label></mat-label>
            <mat-select formControlName="level_2" (selectionChange)="get_select_economy_list($event.value,'3')">
                <mat-option *ngFor="let c of level_2_list" [value] = 'c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 24%;" appearance="fill" *ngIf="level_3_list.length>0">
            <mat-label></mat-label>
            <mat-select formControlName="level_3" (selectionChange)="get_select_economy_list($event.value,'4')">
                <mat-option *ngFor="let c of level_3_list" [value] ='c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 24%;" appearance="fill" *ngIf="level_4_list.length>0">
            <mat-label></mat-label>
            <mat-select formControlName="level_4" (selectionChange)="get_select_economy_list($event.value,'5')">
                <mat-option *ngFor="let c of level_4_list" [value] ='c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
        <button mat-raised-button color="warn" (click)="close()">确定</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false">取消</button>
    </div>
</div>