
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProPositiveResultPLService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }
  post_proPositiveResultPL_statistics(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/statistics/post_proPositiveResultPL_statistics/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
    }
}
