import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { NotificationService } from "src/app/utils/notification.service";
import { DiagSetService } from "./diag-set.service";

export class DiagSetDatasource implements DataSource<any> {
    private patSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: any[];
    public length: number

    constructor(private diagSetService: DiagSetService,
        private notifyService: NotificationService,
    ) { }

    loadtables(office_id, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number) {

        this.loadingSubject.next(true);
        this.diagSetService.sch_diag_set_list(office_id, q_condition, q_value, tpage_nmb, tn_per_page)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(res => {

                if (res.flag) {

                    let thsp = res.value
                    this.data = <any[]>thsp.data;
                    this.length = thsp.count_num
                    this.patSubject.next(thsp.data)
                } else {
                    this.data = <any[]>[]
                    this.length = 0
                    this.patSubject.next([])
                    this.notifyService.showError(res.value, '错误')
                }

            });

    }

    //20240625增加导入异常就出建议
    // loadtables_find_final_suggest_based_on_anomalies(q_value){

    //     this.loadingSubject.next(true);
    //     this.diagSetService.sch_diag_set_list_find_final_suggest_based_on_anomalies(q_value)
    //         .pipe(
    //             catchError(() => of([])),
    //             finalize(() => this.loadingSubject.next(false))
    //         )
    //         .subscribe(res => {

    //             if (res.flag) {

    //                 let thsp = res.value
    //                 this.data = <any[]>thsp.data;
    //                 this.length = thsp.count_num
    //                 this.patSubject.next(thsp.data)
    //             } else {
    //                 this.data = <any[]>[]
    //                 this.length = 0
    //                 this.patSubject.next([])
    //                 this.notifyService.showError(res.value, '错误')
    //             }
    //         });

    // }

    

    connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}
