import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/data-services/auth.service';
import { ShareService } from 'src/app/data-services/share-service';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  isAuthenticated: boolean = false;
  userid = ''
  username = ''
  flag_test_develop = ''
  versioin_explain = ''
  physical_type = ''
  font_version_code = ''
  back_version_code = ''
  constructor(private router: Router,
    public authService: AuthService,
    private shareService: ShareService,
    private commonService: CommonService,
  ) {
    this.shareService.data.subscribe(value => {
      if (value) {
        this.userid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opcode"] : ''//用户ID
        this.username = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opname"] : ''//姓名
        this.physical_type = sessionStorage.getItem('physical_type')
      }
    })
    this.font_version_code = require('../../../../package.json').version

  }

  ngOnInit(): void {
    this.isAuthenticated = false;
    this.flag_test_develop = environment.apiUrl

    if (this.flag_test_develop == 'http://172.16.17.25:8000') {
      this.flag_test_develop = '测试版'
      this.versioin_explain = "PEIS测试版" + this.font_version_code
    } else if (this.flag_test_develop == 'http://172.16.200.101:1001') {
      this.flag_test_develop = '正式版'
    } else {
      this.flag_test_develop = '开发版'
      this.versioin_explain = "PEIS开发版" + this.font_version_code

    }
    this.userid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opcode"] : ''//用户ID
    this.username = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opname"] : ''//姓名
    this.physical_type = sessionStorage.getItem('physical_type')
    this.get_version()
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  logout() {
    this.userid = ''//用户ID
    this.username = ''//姓名
    this.physical_type = ''
    this.isAuthenticated = false;
    this.authService.logout();
    sessionStorage.clear()
    sessionStorage.setItem('physical_type', '')
    sessionStorage.setItem('is_login', 'logout')
    window.location.reload();


  }

  returnHome() {
    this.router.navigate(['/home']);
  }
  gouser() {
    this.userid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opcode"] : ''//用户ID
    this.username = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opname"] : ''//姓名
    if (localStorage.getItem('currentUser') == null) {
      this.authService.logout();
    } else {
      this.router.navigate(['/users']);
    }
  }
  returnTest() {
    this.router.navigate(['/test-doc']);
  }
  get_version() {
    //设置关闭浏览器自动清空缓存-------end
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
    this.commonService.get_version().subscribe(res => {
      this.back_version_code = res.version


    })
  }
}
