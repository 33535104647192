import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/shared/common.service';
import { SingleCheckService } from 'src/app/home/content/single-check/single-check.service';
import { TotalCheckService } from 'src/app/home/content/total-check/total-check.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { PeResultService } from '../pe-result.service';
import { MatDialog } from '@angular/material/dialog';
import { AskCheckDetailComponent } from '../ask-check-detail/ask-check-detail.component';
import { BaseDialogAlertComponent } from 'src/app/dialog-models/base-dialog-alert/base-dialog-alert.component';
import { ImagesResultDialogComponent } from 'src/app/dialog-models/images-result-dialog/images-result-dialog.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  providers: [DatePipe]
})
export class DetailComponent implements OnInit {
  @Input() parent: any;
  session_physical_type = sessionStorage.getItem('physical_type')
  exam_conclusion_list = []
  yszyb_list = []
  zyjjz_list = []
  xp_item_codes = ['11141', '11248']  //胸片项目列表，参考（人员）网报接口文档 4.15
  constructor(
    private fb: FormBuilder,
    private singleCheckService: SingleCheckService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private totalCheckService: TotalCheckService,
    private peResult: PeResultService,
    private dialog: MatDialog,
  ) {
    this.get_exam_conclusion_list()
  }
  group_person: FormGroup
  total_check_form: FormGroup
  loading: boolean = false
  loading_count = 4
  order_group = {
    items: [],
    meals: [],
    total_sale_price: 0,
    total_discount_price: 0,
    name: ''
  }
  group_item_form: FormGroup

  displayedColumns: string[] = ['operate','index', 'code', 'name', 'detail', 'unit_name', 'unusual'];
  dataSource = new MatTableDataSource<any>();
  userid = ''
  username = ''
  occupational_diseases = ''
  occupational_taboo = ''
  com_disabled = true
  protfolio_project = null

  ngOnInit(): void {
    this.userid = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opcode"] : ''//用户ID
    this.username = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))["opname"] : ''//姓名
    this.group_person = this.fb.group({
      "sex": "",
      "work_name": "",
      "create_id": "",
      "is_wz_check": 0,
      "diagnosis_date": null,
      "unit_id": null,
      "review_statu": null,
      "id_card": "",
      "work_state_code": "",
      "create_time": "",
      "is_pass": 0,
      "past_medical_history": null,
      "statu": null,
      "check_date": null,
      "birth": "",
      "work_state_text": "",
      "update_id": null,
      "test_num": null,
      "diagnostic_unit": null,
      "check_result": null,
      "department": null,
      "age": '',
      "work_type_code": "",
      "update_time": null,
      "avatar": null,
      "regist_date": null,
      "old_group_id": null,
      "certificate_type": null,
      "is_marry": "",
      "work_type_text": "",
      "delete_id": null,
      "hazard_factors": "",
      "report_printing_num": null,
      "other_hazard_factors": "",
      "nation": null,
      "person_name": "",
      "mobile": "",
      "jc_type": "",
      "delete_time": null,
      "hazard_factors_text": "",
      "physical_type": "",
      "sporadic_physical": null,
      "home_address": null,
      "id": "",
      "dept": null,
      "del_flag": 0,
      "order_id": "",
      "is_check": 0,
      "address_unit": null,
      "print_state": null,
      "work_num": null,
      "group_id": "",
      "is_recheck": null
    })

    this.group_item_form = this.fb.group({
      _id: '',
      single_item: this.fb.control(''),
      base_projects: this.fb.array([]),
      diagnose_tip: this.fb.control(''),  // 体检提醒或影像所见
      diagnose_sum: this.fb.control(''),  // 诊断小结
      check_doc: this.fb.control(''),  // 检查医生
      check_date: this.fb.control(''),  // 检查日期
      abandon: this.fb.control(false),//弃检当前项目
      abandon_reason: this.fb.control(''),//弃检原因
    })
    this.total_check_form = this.fb.group({
      _id: '',
      conclusion: this.fb.control(''),  // 体检提醒或影像所见
      handle_opinion: this.fb.control(''),  // 诊断小结
      check_doc: this.fb.control(''),  // 检查医生
      check_date: this.fb.control(''),  // 检查日期
      abnormal_results: this.fb.control(''),// 健康体检 -- 异常结果汇总（综述）
      //主检结论
      total_exam_conclusion_code: '',
      //健康体检---
      health_results: this.fb.array([]),
      //体检危害因素
      td_tj_badrsns: this.fb.array([]),
      other_abnormal_results: this.fb.array([]), //其他疾病或异常
    })

  }

  get base_projects() {
    return this.group_item_form.get("base_projects") as FormArray;
  }

  return_list() {
    setTimeout(() => {
      this.parent.tabGroup.selectedIndex = 0
    }, 200);

  }

  item_search_all_data(event, add_num) {

    this.loading = true
    let input_data = {
      "person": this.group_person.value,
      "item": event
    }
    this.protfolio_project = event
    this.singleCheckService.item_search_all_data(input_data).subscribe(res => {

      if (add_num == 4) {
        this.loading_count = 4
      } else {
        this.loading_count += add_num
      }

      if (res.flag) {
        this.base_projects.clear()
        res.value.base_projects.map(b => {
          let code = b.code
          let rst_flag = b.rst_flag
          if (this.xp_item_codes.includes(code) && rst_flag == '') {
            rst_flag = '0'
          }
          let limit = ''
          let maxval = ''
          let minval = ''
          if (b.result_type == '数值' && !b.limit) {
            maxval = b.maxval
            minval = b.minval
            limit = minval + '-' + maxval
          } else if (b.limit) {
            limit = b.limit
          }
          this.base_projects.push(this.fb.group({
            "name": this.fb.control(b.name),
            "detail": this.fb.control(b.detail),
            "unit_name": this.fb.control(b.unit_name),
            "unusual": this.fb.control(b.unusual),
            "state": b.State ? this.fb.control(b.State) : '',
            "limit": limit,
            "input_result_type": 'text',
            "placeholder": '',
            "code": b.code,
            "unit_code": b.unit_code,
            "result_type": b.result_type,
            "rst_flag": rst_flag,
            'skip_check_flag':b.skip_check_flag
          }))
        })
        this.dataSource = new MatTableDataSource<any>(this.base_projects.controls)
        this.group_item_form.get('_id').setValue(res.value._id)
        this.group_item_form.get('diagnose_tip').setValue(res.value.diagnose_tip)
        this.group_item_form.get('diagnose_sum').setValue(res.value.diagnose_sum)
        this.group_item_form.get('check_doc').setValue(res.value.check_doc.length > 0 ? res.value.check_doc : this.userid + ':' + this.username)
        this.group_item_form.get('check_date').setValue(res.value.check_date.length > 0 ? new Date(res.value.check_date) : new Date())
        this.group_item_form.get('abandon').setValue(res.value.abandon)
        this.group_item_form.get('abandon_reason').setValue(res.value.abandon_reason)
      } else {
        this.notifyService.showError('查询组合项目详细信息失败' + res.value, '失败')
      }
      this.loading = false
    })
  }
  save() {
    let check_date = this.datePipe.transform(this.group_item_form.get('check_date').value, 'yyyy-MM-dd HH:mm:ss')
    let t_depart_item_result = this.group_item_form.value
    t_depart_item_result.check_date = check_date
    let input_data = {
      "t_depart_item_result": t_depart_item_result
    }
    this.singleCheckService.update_depart_item_result(input_data).subscribe(res => {

      if (res.flag) {
        this.notifyService.showSuccess('保存分检信息成功', '成功')
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })

  }
  get health_results() {
    return this.total_check_form.get('health_results') as FormArray
  }

  get other_abnormal_results() {
    return this.total_check_form.get('other_abnormal_results') as FormArray
  }
  get td_tj_badrsns() {
    return this.total_check_form.get('td_tj_badrsns') as FormArray
  }
  refresh() {
    this.loading_count = 2
    this.item_search_all_data(this.group_item_form.value.single_item, 1)
    this.search_total_check_result_by_id()
  }

  search_total_check_result_by_id() {
    let input_data = {
      "_id": this.group_person.value.test_num
    }
    this.totalCheckService.search_total_check_result_by_id(input_data).subscribe(res => {
      this.loading_count += 1
      if (res.flag) {
        this.total_check_form.get('_id').setValue(res.value._id)
        this.total_check_form.get('conclusion').setValue(res.value.conclusion)
        this.total_check_form.get('handle_opinion').setValue(res.value.handle_opinion)
        this.total_check_form.get('check_doc').setValue(res.value.check_doc.length > 0 ? res.value.check_doc : this.userid + ':' + this.username)
        this.total_check_form.get('check_date').setValue(res.value.check_date.length > 0 ? new Date(res.value.check_date) : new Date())
        this.total_check_form.get('abnormal_results').setValue(res.value.abnormal_results)
        this.health_results.clear()
        if (res.value.health_results) {
          res.value.health_results.forEach(m => {
            this.health_results.push(
              this.fb.group({
                "title": this.fb.control(m.title),
                "suggest": this.fb.control(m.suggest),
              })
            )
          })
        }
        this.other_abnormal_results.clear()
        if (res.value.other_abnormal_results) {
          res.value.other_abnormal_results.forEach(m => {
            this.other_abnormal_results.push(
              this.fb.group({
                "other_abnormal": this.fb.control(m.other_abnormal),
                "suggest": this.fb.control(m.suggest),
              })
            )
          })
        }
        this.total_check_form.get('total_exam_conclusion_code').setValue(res.value.total_exam_conclusion_code)
        this.total_check_form.get('total_exam_conclusion_code').disable()
        this.td_tj_badrsns.clear()
        res.value.td_tj_badrsns.forEach(m => {
          this.td_tj_badrsns.push(this.fb.group({
            'badrsn_code': m.badrsn_code, //有害因素编码
            'other_label': m.other_label, //有害因素名称
            'exam_conclusion_code': m.exam_conclusion_code, //体检结论代码
            'yszyb_code': m.yszyb_code,//疑似职业病
            'zyjjz_code': m.zyjjz_code,//职业禁忌证
            'otjb_name': m.otjb_name,//其他疾病或异常描述
            'other_badrsn': m.other_badrsn,//其他危害因素名称
            'yszyb_view': m.yszyb_view,
            'zyjjz_view': m.zyjjz_view,
            'otjb_view': m.otjb_view,
            'enterprise_name': m.enterprise_name
          }))
        })
        this.td_tj_badrsns.disable()
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

  generate_total_check_sheet() {
    this.loading = true
    let input_data = {
      "person": this.group_person.value,
      "order_groups": this.order_group,
      "physical_type": this.session_physical_type,
    }
    if (this.group_person.get('print_flag').value == -1) {
      const dialog_pop = this.dialog.open(BaseDialogAlertComponent, {
        width: '50%',
        data: {
          content: '该体检报告已过期，请等待总检完成后，再次打印'
        }
      })
    } else {
      this.peResult.generate_total_check_sheet(input_data).subscribe(res => {
        this.loading = false
        if (res.flag) {
          this.commonService.print_pdf(res.value)
          this.notifyService.showSuccess('打印体检报告完成', '成功')
        } else {
          this.notifyService.showError(res.value, '失败')
        }
        this.parent.list.loadTablePage()
      })
    }

  }
  get_exam_conclusion_list() {
    this.commonService.get_dict_data_by_type('physical_conclusion').subscribe(res => {
      if (res.flag) {
        this.exam_conclusion_list = res.value
      } else {
        this.notifyService.showError('查询主检结论失败', 'physical_conclusion')
      }
    })
  }
  get_yszyb_list(group_id) {
    this.commonService.get_yszyb_list(group_id).subscribe(res => {

      if (res.flag) {
        this.yszyb_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })


  }

  get_zyjjz_list(group_id) {
    this.commonService.get_zyjjz_list(group_id).subscribe(res => {

      if (res.flag) {
        this.zyjjz_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }

    })
  }

  open_ask_check_detail() {
    let person_id = this.group_person.get('id').value
    let group_id = this.group_person.get('group_id').value
    if (person_id && group_id) {
      let input_data = { "person_id": person_id, 'group_id': group_id }
      this.dialog.open(AskCheckDetailComponent, {
        'width': '90%',
        'height': '90%',
        data: {
          'input_data': input_data,
          'person': this.group_person.value
        }
      })
    } else {
      this.notifyService.showWarning('问诊详情查询错误，人员信息为空', '警告')
    }

  }

  open_images() {

    // 无纸化类型，体检号
    let input_data = {
      "rpt_type": this.protfolio_project.rpt_type,
      "test_num": this.group_person.get('test_num').value
    }
    this.singleCheckService.get_nopaper_results_by_condition(input_data).subscribe(res => {

      if (res.flag) {
        if (res.value.file_list.length > 0) {
          this.dialog.open(ImagesResultDialogComponent, {
            width: "90%",
            height: "90%",
            data: {
              "file_list": res.value.file_list
            }
          })
        } else {
          this.notifyService.showWarning('该分检项目影像图文报告尚未上传', '警告')
        }

      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })

  }
}
