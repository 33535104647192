import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonInfoLoadService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }
  sch_group_unit_list(q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number): Observable<any> {
    let params = new HttpParams()
      .set('q_condition', q_condition)

    return this.http.get<any>(`${environment.apiUrl}/basic/sch_group_unit_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }


  generate_persons_zip(input_data){
    return this.http.post<any>(`${environment.apiUrl}/upload/generate_persons_zip/`,input_data,this.httpOptions)
    .pipe(
      map(res=>{
        return res
      }),catchError(handleError)
    )
  }
  generate_persons_zip_path(input_data){
    return this.http.post<any>(`${environment.apiUrl}/upload/generate_persons_zip_path/`,input_data,this.httpOptions)
    .pipe(
      map(res=>{
        return res
      }),catchError(handleError)
    )
  }
}