import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonInfoLoadComponent } from './person-info-load.component';

const routes: Routes = [
  {path:'',component:PersonInfoLoadComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PersonInfoLoadRoutingModule { }
