<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--异常项目导入--</h1>
</div>
<div fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="1%" >
    <button mat-raised-button color="primary" (click)="import()">导入选中</button>
</div>
<br>
<mat-table class="drug-insert-table mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef><b>异常情况</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.value}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<br>
<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%" >
    <button mat-raised-button color="primary" (click)="import()">导入选中</button>
</div>
<br>
<br>
<br>