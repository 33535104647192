import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EnterpriseInfoLoadService } from 'src/app/home/data-upload/enterprise-info-load/enterprise-info-load.service';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

export class UploadDetail {
  ids: string[]
  error_text: string
  zip_filename: string
  upload_status: number
}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.css'],
  providers: [DatePipe]

})
export class UploadDialogComponent implements OnInit {
  type_name = ''
  upload_group = this.fb.group({
    upload_detail_list: this.fb.array([])
  })
  displayedColumns = ['ids', 'upload_status', 'error_text']
  dataSource = new MatTableDataSource<any>()
  optype = '1'
  error_list = []
  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private enterpriseInfoLoadService: EnterpriseInfoLoadService,
    private datePipe: DatePipe,

  ) {

    data.upload_detail_list.forEach((f: UploadDetail) => {
      this.upload_detail_list.push(
        this.fb.group({
          'ids': this.fb.array(f.ids),
          'error_text': f.error_text,
          'zip_filename': f.zip_filename,
          'upload_status': f.upload_status,
        }))

    })
    this.dataSource = new MatTableDataSource(this.upload_detail_list.controls)
    this.type_name = data.type_name
    this.optype = data.optype
  }

  ngOnInit(): void {
    this.upload_detail_list.controls.forEach(f => {
      let zip_filename = f.get('zip_filename').value
      if (f.get('upload_status').value != 0) {
        f.get('upload_status').setValue(2)
        this.commonService.get_zip(zip_filename).subscribe(res => {
          this.commonService.upload_net_official(res, zip_filename, this.type_name).subscribe(res2 => {
            let status_code = res2.status_code
            let retcode_value = res2.retcode_value
            let content = res2.content
            if (retcode_value == '00' && status_code == '200') {
              f.get('upload_status').setValue(3)
              f.get('error_text').setValue('')
              let input_data = {
                'ids': f.get('ids').value,
                'type_name': this.type_name,
                'optype': this.optype
              }
              this.enterpriseInfoLoadService.updage_enterprise_person_upload_status(input_data).subscribe(res => {

                if (!res.flag) {
                  this.notifyService.showError(res.value, '错误')
                }
              })
            } else {
              f.get('upload_status').setValue(4)
              f.get('error_text').setValue(content)
              this.error_list.push({
                'original_file_name': this.renameFile(f.get('zip_filename').value),
                'error_text': content
              })
            }
          })
        },
          (error) => {
            f.get('upload_status').setValue(4)
            f.get('error_text').setValue(error)
          }
        )
      }
    })
  }
  previewFile(path) {
    if (path) {
      this.commonService.previewFile_xml(path)
    } else {
      this.notifyService.showError('文件名不存在', '错误')
    }
  }

  get upload_detail_list() {
    return this.upload_group.get('upload_detail_list') as FormArray;
  }

  renameFile(filename: string): string {
    if (filename.endsWith(".zip")) {
      return filename.replace(".zip", "_original.xml");
    }
    return filename;
  }

  export_xml() {
    if (this.error_list.length > 0) {
      let input_data = {
        'error_list': this.error_list
      }
      let timestamp = this.datePipe.transform(new Date(), 'yyyy-MM-dd-HH-mm')
      this.commonService.gener_error_zip_file(input_data,timestamp).subscribe(res => {
        if (res) {
          this.notifyService.showSuccess('导出外检人员信息成功', '成功')

        }
      })
    }
  }



}
