<mat-nav-list>
    <a mat-list-item href={{pass_Url}} (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">合理用药</span>
    </a>
    <a mat-list-item href={{outemrUrl}} (click)="onSidenavClose()">
        <mat-icon>assignment_ind</mat-icon> <span class="nav-caption">门诊电子病历</span>   
    </a>
    <a mat-list-item href={{emrUrl}} (click)="onSidenavClose()">
        <mat-icon>account_balance</mat-icon><span class="nav-caption">住院电子病历</span>
    </a> 
    <a mat-list-item href={{nuseUrl}} (click)="onSidenavClose()">
        <mat-icon>account_balance</mat-icon><span class="nav-caption">护理病历</span>
    </a>    
</mat-nav-list>
