import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HazardAddMultipleComponent } from 'src/app/home/basic/group-order/group-order-detail/order-group-person/hazard-add-multiple/hazard-add-multiple.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { DialogModelsService } from '../dialog-models.service';
//身份证号校验正则规则
const _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
const _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.css'],
  providers: [DatePipe]

})
export class EditPersonComponent implements OnInit {

  tGroupPersonForm: FormGroup
  edit_flag: boolean = false
  submit_disabled: boolean = false
  workStateCodeArr = []
  work_state_list = []
  hazard_factors_list = []
  hazard_factors_text_list = []
  session_physical_type = sessionStorage.getItem('physical_type')
  work_type_list: Observable<any[]>
  work_name_show = false
  hazard_factors_dict_list = []
  hazard_factors_remark_list = []
  other_hazard_factors_show = false
  other_hazard_factors_group = this.fb.group({
    others_array: this.fb.array([
    ])
  }

  )
  constructor(
    public dialogRef: MatDialogRef<EditPersonComponent>,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private dialogModelsService:DialogModelsService
  ) {
    this.get_work_state_list()
  }

  ngOnInit(): void {
    if (this.dialog_data.data) {
      let data = this.dialog_data.data
      this.tGroupPersonForm = this.fb.group(
        {
          id: data.id,
          person_name: data.person_name,
          id_card: [data.id_card, [
            Validators.required,
            Validators.pattern(_IDRe18 || _IDre15),
            this.varifyIdCard()
          ]],
          sex: data.sex,
          birth: data.birth,
          age: data.age,
          is_marry: data.is_marry,
          mobile: [data.mobile, [Validators.required, Validators.pattern(/^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/||/^(0\d{2,3})-?(\d{7,8})$/)]],
          home_address: data.home_address,
          hazard_factors: data.hazard_factors,
          hazard_factors_remark: data.hazard_factors_remark,
          hazard_factors_text: [data.hazard_factors_text, [Validators.required]],
          other_hazard_factors: data.other_hazard_factors,
          work_type_code: data.work_type_code,
          work_type_text: data.work_type_text,
          work_name: data.work_name,
          work_state_code: data.work_state_code,
          work_state_text: data.work_state_text,
          exposure_start_date: data.exposure_start_date,
          jc_type: data.jc_type,
          is_pass: data.is_pass,
          is_check: data.is_check,
          is_wz_check: data.is_wz_check,
          physical_type: data.physical_type,
          order_id: data.order_id,
          group_id: data.group_id,
          dept: data.dept,
          check_date:data.check_date,
          work_state: '',
          unit_id: data.unit_id,
          exposure_years: data.exposure_years,
          exposure_months: data.exposure_months,
          department: data.department,
          work_num: data.work_num,
          anonymity: data.anonymity,
          banonymity: data.anonymity == '1' ? true : false,
          production_cell:data.production_cell
        }
      )
      this.get_work_type_by_code(data.work_type_code)
      this.edit_flag = this.dialog_data.edit_flag
      if (!this.edit_flag) {
        this.tGroupPersonForm.disable()
        this.submit_disabled = true
      }
      this.hazard_factors_list = data.hazard_factors != '' ? data.hazard_factors.split(';') : []
      this.hazard_factors_text_list = data.hazard_factors_text != '' ? data.hazard_factors_text.split(';') : []
      this.hazard_factors_remark_list = data.hazard_factors_remark != '' ? data.hazard_factors_remark.split(';') : []
      this.hazard_factors_list.map((value, index) => {
        this.hazard_factors_dict_list.push({
          'type_code': value,
          'type_name': this.hazard_factors_text_list[index],
          'remark': this.hazard_factors_remark_list[index] == 'null' ? null : this.hazard_factors_remark_list[index],
        })

      })

      if (data.other_hazard_factors_dict) {
        data.other_hazard_factors_dict.split(';').forEach(m => {
          if (m) {
            let other = m.split(':')
            this.others_array.push(this.fb.group({
              other_code: other[0],
              other_label: other[1],
              other_value: [other[2], [Validators.required]],
            }))
          }

        })
      }
      if (this.others_array.controls.length > 0) {
        this.other_hazard_factors_show = true
      }
      if (data.work_type_code == '999999') {
        this.work_name_show = true
        this.tGroupPersonForm.get('work_type_code').setValidators([Validators.required])
      }
    } else {
      this.tGroupPersonForm = this.fb.group({
        id: '',
        person_name: '',
        id_card: ['', [
          Validators.required,
          Validators.pattern(_IDRe18 || _IDre15),
          this.validateDateOfBirth(),
          this.varifyIdCard()
        ]],
        sex: '',
        birth: '',
        age: '',
        is_marry: '',
        mobile: [null, [Validators.required, Validators.pattern(/^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/||/^(0\d{2,3})-?(\d{7,8})$/)]],
        home_address: '',
        hazard_factors: '',
        hazard_factors_text: ['', [Validators.required]],
        other_hazard_factors: null,
        work_type_code: '',
        work_type_text: '',
        work_name: '',
        work_state_code: '',
        work_state_text: '',
        exposure_start_date: '',
        jc_type: '1',
        is_pass: -2,//是否通过检查(0-未登记，1-登记，2-在检,3-总检,4-已完成，-1-未交款,-2 订单未确认)
        is_check: 0, //是否检查(0-否，1-是)
        is_wz_check: 0, //问诊科是否检查
        physical_type: this.session_physical_type,
        order_id: '',
        group_id: '',
        dept: '',
        check_date: null,
        work_state: '',
        unit_id: '',
        exposure_years: null,
        exposure_months: null,
        department: null,
        work_num: null,
        anonymity: 0,
        banonymity: false,
        production_cell :''
      })
    }
    this.work_type_list = this.tGroupPersonForm.get('work_type_text').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.title
          return this._filter(title)
        }),
      );
  }
  submit() {
    this.tGroupPersonForm.markAllAsTouched()
    this.other_hazard_factors_group.markAllAsTouched()

    let person_dict = {}

    Object.keys(this.tGroupPersonForm.controls).map(key => {
      const control = this.tGroupPersonForm.get(key);
      person_dict[key] = control ? control.value : null
    })

    let hazard_factors = this.hazard_factors_list.length > 0 ? this.hazard_factors_list.map(r => r).reduce((s, value) => s + ';' + value, '').slice(1) : ''
    let hazard_factors_text = this.hazard_factors_text_list.length > 0 ? this.hazard_factors_text_list.map(r => r).reduce((s, value) => s + ';' + value, '').slice(1) : ''
    let hazard_factors_remark = this.hazard_factors_remark_list.length > 0 ? this.hazard_factors_remark_list.map(r => r).reduce((s, value) => s + ';' + value, '').slice(1) : ''
    person_dict['hazard_factors'] = hazard_factors
    person_dict['hazard_factors_remark'] = hazard_factors_remark
    person_dict['hazard_factors_text'] = hazard_factors_text
    this.tGroupPersonForm.get('hazard_factors_text').setValue(hazard_factors_text)
    let birth = this.datePipe.transform(this.tGroupPersonForm.value.birth, 'yyyy-MM-dd')
    let exposure_start_date = this.datePipe.transform(this.tGroupPersonForm.value.exposure_start_date, 'yyyy-MM-dd')
    person_dict['birth'] = birth
    person_dict['exposure_start_date'] = exposure_start_date
    let work_state_code = this.tGroupPersonForm.get('work_state').value ? this.tGroupPersonForm.get('work_state').value.value : ''
    let work_state_text = this.tGroupPersonForm.get('work_state').value ? this.tGroupPersonForm.get('work_state').value.title : ''
    person_dict['work_state_code'] = work_state_code
    person_dict['work_state_text'] = work_state_text
    if (this.tGroupPersonForm.get('work_type_text').value) {
      let work_type_text = this.tGroupPersonForm.get('work_type_text').value.title
      let work_type_code = this.tGroupPersonForm.get('work_type_text').value.value
      person_dict['work_type_text'] = work_type_text
      person_dict['work_type_code'] = work_type_code
    }

    let other_hazard_factors = this.others_array.controls.length > 0 ? this.others_array.controls
      .map(m => [m.get('other_value').value])
      .reduce((acc, [value]) => acc + ',' + value, '').slice(1) : ''

    let other_hazard_factors_dict = this.others_array.controls.length > 0 ? this.others_array.controls
      .map(m => [m.get('other_code').value, m.get('other_label').value, m.get('other_value').value])
      .reduce((acc, [code, label, value]) => acc + ';' + code + ':' + label + ':' + value, '').slice(1) : ''
    person_dict['other_hazard_factors'] = other_hazard_factors
    person_dict['other_hazard_factors_dict'] = other_hazard_factors_dict

    //处理个人信息是否隐匿
    if (this.tGroupPersonForm.value.banonymity == true) {
      this.tGroupPersonForm.get('anonymity').setValue(1)
      person_dict['anonymity'] = 1
    } else {
      this.tGroupPersonForm.get('anonymity').setValue(0)
      person_dict['anonymity'] = 0
    }

    const controls = this.tGroupPersonForm.controls
    let ok_flag = true
    Object.keys(controls).forEach(key => {
      const control = controls[key] as FormControl;
      if (control.hasError('required')) {
        ok_flag = false
        this.notifyService.showWarning('请输入必填项', '警告')
        return
      }
      if (control.hasError('data_overflow') || control.hasError('pattern') || control.hasError('dateOfBirthInvalid') || control.hasError('idcardInvalid')) {
        ok_flag = false
        this.notifyService.showWarning('输入格式错误，请检查', '警告')
        return
      }
    })
    //循环其他危害因素
    this.others_array.controls.map(m => {
      const group = m as FormGroup
      Object.keys(group.controls).forEach(key => {
        if (group.get(key).hasError('required')) {
          ok_flag = false
          this.notifyService.showWarning('其他危害因素必填*', '警告')
        }
      })
    })
    if (!this.tGroupPersonForm.get('work_type_code').value) {
      this.tGroupPersonForm.get('work_type_text').setValue('')
      this.notifyService.showWarning('工种名称错误，请重新选择', '警告')
      return
    }
    if (ok_flag) {
      let input_data = {
        person:person_dict
      }
      this.dialogModelsService.update_person(input_data).subscribe(res=>{
        console.log(res);
        if(res.flag){
          this.dialogRef.close(res.value)
          this.notifyService.showSuccess('更新人员信息成功','成功')
        }else{
          this.notifyService.showError('更新人员信息失败'+res.value,'失败')
        }
        
      })
    }
  }

  //双击添加危害因素
  hazard_factors_add() {

    if (!this.submit_disabled) {
      let dialogRef = this.dialog.open(HazardAddMultipleComponent, {
        width: '80%',
        height: 'auto',
      })
      dialogRef.afterClosed().subscribe(res => {

        if (res) {
          let bardsns = res.type_name ? res.type_name : []
          let main_bardsn = res.type_name_single
          bardsns.map(m => {
            if (!this.hazard_factors_dict_list.find(h => h.type_code == m.type_code)) {
              this.hazard_factors_dict_list.push(m)
            }
          })
          console.log(main_bardsn)
          console.log(this.hazard_factors_dict_list)
          if (main_bardsn && this.hazard_factors_dict_list.find(f => f.type_code != main_bardsn.type_code && f.remark == '1')) {
            this.hazard_factors_dict_list = this.hazard_factors_dict_list.filter(f => f.remark != '1') //过滤掉已经存在的主要粉尘
            this.hazard_factors_dict_list.push(main_bardsn)
            this.notifyService.showWarning('当劳动者的危害因素包括主要粉尘中的多个时，只能选择一种最主要粉尘上传。', '已替换主要粉尘')
          } else if (main_bardsn) {
            if(!this.hazard_factors_dict_list.find(f => f.type_code == main_bardsn.type_code)){
              this.hazard_factors_dict_list.push(main_bardsn)
            }
          }
          this.hazard_factors_list = []
          this.hazard_factors_remark_list = []
          this.hazard_factors_text_list = []
          this.hazard_factors_dict_list.map(m => {
            this.hazard_factors_list.push(m.type_code)
            this.hazard_factors_remark_list.push(m.remark)
            this.hazard_factors_text_list.push(m.type_name)
          })
          if (this.hazard_factors_dict_list.find(f => f.remark == 'other')) {
            this.other_hazard_factors_show = true
          } else {
            this.other_hazard_factors_show = false
          }
          let others = bardsns.filter(f => f.remark == 'other')

          others.forEach(m => {
            if (!this.others_array.controls?.find(f => f.get('other_code').value == m.type_code)) {
              this.others_array.push(this.fb.group({
                other_code: m.type_code,
                other_label: m.type_name,
                other_value: ["", [Validators.required]]
              }))
            }
          })
        }
      })
    }

  }

  delete_hazard_factor(j) {
    if (!this.submit_disabled) {
      const remove_dict = this.hazard_factors_dict_list[j]

      this.submit_disabled = true
      this.hazard_factors_list.splice(j, 1);
      this.hazard_factors_remark_list.splice(j, 1)
      this.hazard_factors_text_list.splice(j, 1)
      this.hazard_factors_dict_list.splice(j, 1)
      if (this.hazard_factors_dict_list.find(f => f.remark == 'other')) {
        this.other_hazard_factors_show = true
      } else {
        this.other_hazard_factors_show = false
      }
      let remove_other = this.others_array.controls.find(f => f.get('other_code').value == remove_dict.type_code)
      if (remove_dict.remark == 'other') {
        let remove_index = this.others_array.controls.indexOf(remove_other)
        this.others_array.removeAt(remove_index)
      }
      this.submit_disabled = false



    }

  }

  get_work_state_list() {
    this.commonService.get_dict_data_by_type('workState')
      .subscribe(res => {

        if (res.flag) {
          this.work_state_list = res.value
          this.tGroupPersonForm.get('work_state').setValue(this.work_state_list.filter(m => m.value == this.tGroupPersonForm.get('work_state_code').value).map(m => m)[0])
        } else {
          this.notifyService.showError(res.value, '错误')
        }
      })
  }

  generate_other_by_id_card(event) {


    if (!this.tGroupPersonForm.get('id_card').errors) {
      //证件号码格式无误后，再进行其他数据处理
      //获取性别
      if (event.length == 18) {




        let birthYear = event.slice(6, 10)
        let birthMonth = event.slice(10, 12)
        let birthDay = event.slice(12, 14)
        let sex_num = event.slice(16, 17)
        let sex = sex_num % 2 == 0 ? '2' : '1'
        let birth = new Date(birthYear + '/' + birthMonth + '/' + birthDay)
        this.tGroupPersonForm.get('birth').setValue(birth)
        let exposure_start_date = this.tGroupPersonForm.get('exposure_start_date').value
        let work_state = this.tGroupPersonForm.get('work_state').value

        if (exposure_start_date) {
          if (work_state?.value != '1001') {
            this.tGroupPersonForm.get('exposure_start_date').setValidators([Validators.required, this.exposure_start_date_Validator()])
          } else {
            this.tGroupPersonForm.get('exposure_start_date').setValidators([this.exposure_start_date_Validator()])
          }
          this.tGroupPersonForm.get('exposure_start_date').reset()
          this.tGroupPersonForm.get('exposure_start_date').setValue(exposure_start_date)
          this.tGroupPersonForm.get('exposure_start_date').markAsTouched()
        }

        this.tGroupPersonForm.get('sex').setValue(sex)
        let now = new Date()
        const nowMonth = now.getMonth() + 1;
        const nowDay = now.getDay();
        // 计算年龄
        let age = now.getFullYear() - birthYear;
        if (nowMonth < birthMonth || (nowMonth === birthMonth && nowDay < birthDay)) {
          age -= 1;
        }
        this.tGroupPersonForm.get('age').setValue(age)
      } else if (event.length == 15) {




      }
    }


  }

  //创建自定义函数
  validateDateOfBirth(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      let id_card = control.value
      if (id_card.length == 18) {
        let year = id_card.slice(6, 10)
        let month = id_card.slice(10, 12)
        let day = id_card.slice(12, 14)
        let birth = new Date(year + '/' + month + '/' + day)
        if (birth.getMonth() + 1 != parseInt(month) || birth.getDate() != parseInt(day)) {

          return { dateOfBirthInvalid: true }
        } else {
          return null
        }
      } else if (id_card.length == 15) {
        let year = id_card.slice(6, 8)
        let month = id_card.slice(8, 10)
        let day = id_card.slice(10, 12)
        let birth = new Date(year + '/' + month + '/' + day)
        if (birth.getMonth() + 1 != parseInt(month) || birth.getDate() != parseInt(day)) {

          return { dateOfBirthInvalid: true }
        } else {
          return null
        }
      }
    }
  }
  //身份证号校验
  varifyIdCard(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let code = control.value

      let s: number = 0;

      for (let i: number = 0; i < 17; i++) {
        let j: number = parseInt(code.charAt(17 - i - 1));
        s += j * Math.pow(2, i + 1) % 11;
      }

      let cd: number = (12 - (s % 11)) % 11;
      let last: string = code.charAt(17);

      let res: string;

      if (last === 'X' ||last === 'x') {
        if (cd === 10) {
          res = 'correct';
        } else {
          res = 'error';
        }
      } else {
        if (cd === parseInt(last)) {
          res = 'correct';
        } else {
          res = 'error';
        }
      }
      if (res == 'correct') {
        return null
      } else {
        return { idcardInvalid: true }
      }
    }
  }

  displayFn(option: any): string {
    this.tGroupPersonForm.get('work_type_code').setValue(option.value)
    if (option.value) {
      if (option.value == '999999') {
        this.work_name_show = true
        this.tGroupPersonForm.get('work_name').setValidators([Validators.required])

      } else {
        this.work_name_show = false
        this.tGroupPersonForm.get('work_name').reset()
        this.tGroupPersonForm.get('work_name').clearValidators()
      }
    }
    return option && option.title ? option.title : ''
  }
  private _filter(title: any): any[] {
    let tl_new = []
    this.commonService.get_work_type_list(title).subscribe(res => {
      if (res.flag) {
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }

  work_state_change(event) {

    let value = event.value

    if (value.value != '1001') {
      this.tGroupPersonForm.get('exposure_start_date').setValidators([Validators.required, this.exposure_start_date_Validator()])
      this.tGroupPersonForm.get('exposure_start_date').updateValueAndValidity()
    } else {
      this.tGroupPersonForm.get('exposure_start_date').setValidators([this.exposure_start_date_Validator()])
      this.tGroupPersonForm.get('exposure_start_date').reset()


    }
  }
  exposure_start_date_Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let birth = this.tGroupPersonForm.get('birth').value
      if (!birth || !control.value) {
        return null
      }

      if (control.value < birth) {
        return { data_overflow: true }
      }
      return null
    }
  }
  get_work_type_by_code(code) {
    this.commonService.get_work_type_by_code(code).subscribe(res => {
      if (res.flag) {
        this.tGroupPersonForm.get('work_type_text').setValue(res.value)
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
  }

  get others_array() {
    return this.other_hazard_factors_group.get('others_array') as FormArray
  }

}
