<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>{{title}}</b></h1>
    <h3><b>{{subtitle}}</b></h3>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <mat-form-field appearance="fill">
        <mat-label>执行科室</mat-label>
        <mat-select [(ngModel)]="section_id" (selectionChange)="search()">
            <mat-option value="">所有科室</mat-option>
            <mat-option *ngFor="let i of his_dept_list" [value]="i.id">{{i.section_code}}:{{i.section_name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>搜索字段</mat-label>
        <mat-select [(ngModel)]="input_cate">
            <mat-option value="name">名称</mat-option>
            <mat-option value="short_name">简称</mat-option>

        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>请输入查询内容..</mat-label>
        <input matInput [(ngModel)]="input_text" (keyup.enter)="search()" />
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="search()"><mat-icon>search</mat-icon>搜索</button>
    <button mat-raised-button color="primary" (click)="import_selected()">导入选中</button>
</div>

<br>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<div class="content" >
    <mat-table  [dataSource]="dataSource">
        <ng-container matColumnDef="select" >
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef><b>名称</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="short_name">
            <mat-header-cell *matHeaderCellDef><b>简称</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.short_name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="sale_price">
            <mat-header-cell *matHeaderCellDef><b>销售单价（元）</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.sale_price}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="cost_price">
            <mat-header-cell *matHeaderCellDef><b>成本单价（元）</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.cost_price}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="office_name">
            <mat-header-cell *matHeaderCellDef><b>科室名称</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.office_name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef><b>检查地址</b></mat-header-cell>
            <mat-cell class="description-cell" *matCellDef="let row">{{row.address}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true" ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
</div>

<mat-paginator [length]="dataSource.length" [pageSize]="15" [pageSizeOptions]="[15,30, 60]" showFirstLastButtons>
</mat-paginator>

<div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="1%">
    <button mat-raised-button color="wran" [mat-dialog-close]="false">取消</button>
    <button mat-raised-button color="primary" (click)="import_selected()">导入选中</button>
</div>