import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderState'
})
export class OrderStatePipe implements PipeTransform {

  transform(value:number): unknown {
    let result = ''
    if(value==0){
      result = '暂存'
    }else if (value==1){
      result = '已提交'
    }else{
      result = '未知错误'
    }
    return result;
  }

}
