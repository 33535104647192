import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PeResultComponent } from './pe-result.component';

const routes: Routes = [
  {path:'',component:PeResultComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeResultRoutingModule { }
