import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.css'],
  providers: [DatePipe]

})
export class DateRangeComponent implements OnInit {
  range: FormGroup
  @Input() parent: any
  @Input() title: any
  constructor(
    private datePipe: DatePipe,
    private commonService: CommonService,

  ) {

  }

  ngOnInit(): void {
    this.range = new FormGroup({
      start: new FormControl(this.commonService.get_start_date()),
      end: new FormControl(this.commonService.get_end_date())
    });
  }
  get start_date() {
    console.log();
    let start_date  = this.range.value.start.setHours(0,0,0,0)
    return this.datePipe.transform(start_date, 'yyyy-MM-dd HH:mm:ss')
  }

  get end_date() {
    let end_date  = this.range.value.end.setHours(23,59,59,999)
    return this.datePipe.transform(end_date, 'yyyy-MM-dd HH:mm:ss')
  }
  reset_time() {
    this.range.get('start').setValue(this.commonService.get_start_date)
    this.range.get('end').setValue(this.commonService.get_end_date)
  }
}
