import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cost',
  templateUrl: './cost.component.html',
  styleUrls: ['./cost.component.css']
})
export class CostComponent implements OnInit {
  session_physical_type = sessionStorage.getItem('physical_type')

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  async onMenuAction(tpage: string) {
    // 麻醉费用管理
    switch (tpage) {
      case "individual-order": { // 个人体检费用结算
        this.router.navigate(['/cost/individual-order']);
        break;
      } case "individual-order-sum": { // 个人体检费用信息查询
        this.router.navigate(['/cost/individual-order-sum']);
        break;
      }
      case "group-unit-orders": {//团检客户费用信息查询
        this.router.navigate(['/cost/group-unit-orders']);
        break;
      }
      case "check-transfer": {//团检客户费用信息查询
        if (this.session_physical_type=='健康体检'){
          this.router.navigate(['/cost/check-transfer']);
        }else{
          window.location.reload();
          this.router.navigate([''])
        }
        
        break;
      }
      default: {
        break;
      }
    }
  }
}
