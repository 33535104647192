// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  total_dept: 'tj', //医院总检科编码

  emrUrl: 'http://172.19.40.201:9700', //住院电子病历入口
  nuseUrl: 'http://172.19.40.201:9800', //护理病历入口
  outemrUrl: 'http://172.19.40.201:9900', //门诊电子病历入口
  passUrl: 'http://172.19.40.201:9502', //合理用药-主入口
  wsUrl: 'ws://127.0.0.1:8000/ws',//websocket请求
  personUrlShama: 'http://59.197.185.106:6004/BhkDataService/zwbhkService',
  personUrl: 'http://193.168.33.103:6002/BhkDataService/zwbhkService',
  enterpriseUrlShama: 'http://59.197.185.106:6004/BhkDataService/crptUpService',
  enterpriseUrl: 'http://193.168.33.103:6002/BhkDataService/crptUpService',

  api: "/api",
  // apiUrl:'/apiUrl',

  // apiUrl: 'http://127.0.0.1:8188', //localhost开发
  // apiUrl:'http://192.168.31.156:8088'
  // apiUrl: 'http://192.168.31.203:9813',   //公司服务器203
  apiUrl: 'http://172.16.200.101:9813',   //凌源凌钢医院正式库



  //ng serve  --host 172.16.17.25 --port 4216  --proxy--config proxy.conf.json
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
/*

ng serve --host 192.168.31.156 --port 4215
*/