import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DiagSetPopComponent } from 'src/app/home/sysset/diag-set/diag-set-pop/diag-set-pop.component';
import { DiagSetComponent } from 'src/app/home/sysset/diag-set/diag-set.component';
import { DiagSetDatasource } from 'src/app/home/sysset/diag-set/diag-set.datasource';
import { DiagSetService } from 'src/app/home/sysset/diag-set/diag-set.service';
import { DeptSet } from 'src/app/models/sysset/dept-set';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-diag-set-import',
  templateUrl: './diag-set-import.component.html',
  styleUrls: ['./diag-set-import.component.css'],
  providers: [DatePipe]

})
export class DiagSetImportComponent implements OnInit {


  tbutton_status: boolean = false;
  office_id = ''
  tgroupUnit: DeptSet = new DeptSet();
  dataSource: DiagSetDatasource;
  displayedColumns = ['select', 'office_id', 'office_name', 'diag_brief_code', 'diag_name', 'advice_suggest', 'operate'];

  selection = new SelectionModel<DeptSet>(true, []);
  selectedRowIndexAuto: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('his_dept_list') his_dept_list: any;
  input_text = ''
  input_cate: string = 'diag_name';
  title = '导入诊断术语'

  constructor(
    public dialogRef: MatDialogRef<DiagSetComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private datepipe: DatePipe,
    private router: Router,
    private diagSetService: DiagSetService,
    private notifyService: NotificationService,
    public dialog: MatDialog,
    private commonService: CommonService,



  ) {

    if (this.dialog_data.check_item_office_id) {
      this.office_id = this.dialog_data.check_item_office_id

    }
    if (this.dialog_data.title) {
      this.title = this.dialog_data.title
    }
  }
  

  ngOnInit(): void {
    this.dataSource = new DiagSetDatasource(this.diagSetService, this.notifyService);
    this.dataSource.loadtables(this.office_id, '', '', 0, 15);
  }

  ngAfterViewInit() {
    if (this.tgroupUnit == undefined) {
      this.tgroupUnit = new DeptSet();
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();

  }

  officeidUpdated(data: string) {
    this.office_id = data;
  }


  loadTablePage() {
    this.selection.clear()

    if (this.tgroupUnit == undefined) {
      this.tgroupUnit = new DeptSet();
    }

    this.dataSource.loadtables(
      this.office_id,
      this.input_cate,
      this.input_text,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: DeptSet): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  search() {
    this.paginator.pageIndex = 0
    this.loadTablePage()
  }
  delete_selected() {
    let selected = this.selection.selected
    if (selected.length < 1) {
      this.notifyService.showWarning('尚未选择任何数据！', '警告')
      return
    }
    const dialogRef = this.dialog.open(BaseDialogComponent, {
      width: '300px',
      data: {
        content: '是否删除已选中的客户数据?'
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.diagSetService.delete_selected(this.selection.selected)
          .subscribe(res => {
            if (res.flag) {
              this.notifyService.showSuccess('删除选中诊断术语成功', '成功')
              this.loadTablePage()
            } else {
              this.notifyService.showError(res.value, '删除失败')
            }
          })
      }
    })

  }

  delete_item(row) {
    const dialogRef = this.dialog.open(BaseDialogComponent, {
      width: '300px',
      data: {
        content: '是否删除已选中的客户数据?'
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.diagSetService.delete_selected([row])
          .subscribe(res => {
            if (res.flag) {
              this.notifyService.showSuccess('删除术语信息成功', '成功')
              this.loadTablePage()
            } else {
              this.notifyService.showError(res.value, '删除失败')
            }
          })
      }
    })

  }

  add_diag_set() {
    const dialogRef = this.dialog.open(DiagSetPopComponent, {
      width: '90%',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(res => {

      if (res) {
        this.loadTablePage()
      }
    })
  }
  edit_diag_set(row, edit_flag) {
    const dialogRef = this.dialog.open(DiagSetPopComponent, {
      width: '90%',
      height: 'auto',
      data: {
        data: row,
        edit_flag: edit_flag
      }
    })
    dialogRef.afterClosed().subscribe(res => {

      if (res) {
        this.loadTablePage()
      }
    })
  }

  replay() {
    this.input_text = ''
    this.search()
  }

  to_import() {

    let selected = this.selection.selected
    this.dialogRef.close(selected)
  }
  exit() {
    this.dialogRef.close()
  }


}
