import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import { merge, Subscriber } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { ComboDatasource } from './combo-datsource';
import { DialogModelsService } from '../dialog-models.service';

@Component({
  selector: 'app-meal-project-import',
  templateUrl: './meal-project-import.component.html',
  styleUrls: ['./meal-project-import.component.css'],
  providers: [DatePipe]
})
export class MealProjectImportComponent implements OnInit {
  title = ''
  subtitle = ''
  tbutton_status: boolean = false;

  tcombo: any ={}
  dataSource: ComboDatasource;
  session_physical_type=sessionStorage.getItem('physical_type') 
  displayedColumns = this.session_physical_type=='职业体检'?[ 'select','name',  'hazard_factors_text']:[ 'select','name'];

  selection = new SelectionModel<any>(true, []);
  selectedRowIndexAuto: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  input_text=''
  input_cate: string = 'name';
  career_stage_list = []
  section_id = ''

  constructor(
    private datepipe: DatePipe,
    private router: Router,
    private groupOrderService: DialogModelsService,
    private notifyService: NotificationService,
    private commonService:CommonService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MealProjectImportComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data:any,
  ) { 
    this.get_career_stage_list()
    this.title = this.dialog_data.title
    this.subtitle = this.dialog_data.subtitle
  }

  ngOnInit(): void {
    this.dataSource = new ComboDatasource(this.groupOrderService);
    this.dataSource.loadtables(this.session_physical_type,'','', '', 0, 15);

  }

  ngAfterViewInit() {
    if (this.tcombo == undefined) {
      this.tcombo = {}
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();
  }


  loadTablePage() {
    this.selection.clear()
    if (this.tcombo == undefined) {
      this.tcombo ={}
    }
    this.dataSource.loadtables(
      this.session_physical_type,
      this.section_id,
      this.input_cate,
      this.input_text,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
  }


  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
  }
  search() {
    this.paginator.pageIndex=0
    this.loadTablePage()
  }

  add_combo() {
    
   this.router.navigate(['sysset/combo/detail'])
  }
  edit_combo(row, edit_flag) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: row,
        edit_flag: edit_flag
      }
    };
    this.router.navigate(['sysset/combo/detail'],navigationExtras)
  }


  get_career_stage_list() {
    this.commonService.get_dict_data_by_type('workState').subscribe(res => {
      if (res.flag) {
        this.career_stage_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

  import(){
    
    let selected  = this.selection.selected
    this.dialogRef.close(selected)
    // let already_meals = this.dialog_data.already_meals
    // let already_items = this.dialog_data.already_items
    // let repeat_meals = already_meals.filter(meal=>{
    //   return selected.some(select=>{
    //     return select.id === meal.id
    //   })
    // })
    // repeat_meals.forEach(meal=>{
    //   this.notifyService.showInfo('体检套餐'+meal.name+'已存在','提示')
    // })
    // let deal_selected = selected.filter(select=>{
    //   return !already_meals.some(meals=>{
    //     return meals.id === select.id
    //   })
    // })
    // 
    // if (deal_selected.length>0){
    //   let input_data={
    //     'combo_list':deal_selected,
    //     'already_meals':already_meals,
    //     'already_items':already_items,
    //     'must_discount':this.dialog_data.must_discount,
    //     "persons":this.dialog_data.persons,
    //     'group_order_id':this.dialog_data.group_order_id,
    //     "group_id":this.dialog_data.group_id
    //   }
    //   this.groupOrderService.import_order_group_meal(input_data).subscribe(res=>{
        
    //     if(res.flag){
    //       this.dialogRef.close()
    //       this.notifyService.showSuccess('添加必检套餐成功','成功')
    //     }else{
    //       this.notifyService.showError(res.value,'失败')
    //     }
    //   })
    // }
   
  }
}
