import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeBracket'
})
export class RemoveBracketPipe implements PipeTransform {

  transform(value: string): string {
    let result=value.split("(")[0]
    return result;
  }

}
