<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h2>--{{title}}--</h2>
</div>
<form (ngSubmit)="close()" >
    <mat-form-field appearance="fill" class="example-form-field">
        <mat-label>体检日期</mat-label>
        <input matInput [matDatepicker]="deliveryDatePicker" placeholder="请选择体检日期" [(ngModel)]="delivery_time"
            name="deliveryTime" required >
        <mat-datepicker-toggle matSuffix [for]="deliveryDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #deliveryDatePicker></mat-datepicker>
    </mat-form-field>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
        <button mat-raised-button color="warn" [mat-dialog-close]="delivery_time">确定</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false">取消</button>
    </div>
</form>
