<app-layout>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!--in here all the content must reside. We will add a navigation header as well-->
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header> 
      <main>
        <router-outlet></router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>