
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProPositiveResultPLRoutingModule } from './proPositiveResultPL-routing.module';
import { ProPositiveResultPLComponent } from './proPositiveResultPL.component';
import { NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { MaterialModule } from 'src/app/shared/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ErrorInterceptor } from 'src/app/utils/error.interceptor';
import { JwtInterceptor } from 'src/app/utils/jwt.interceptor';


@NgModule({
  declarations: [ProPositiveResultPLComponent],
  imports: [
    CommonModule,
    ProPositiveResultPLRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    WebcamModule,
    NgxPrintModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
  { provide: LOCALE_ID, useValue: "zh-cn" },
  ]
})
export class ProPositiveResultPLModule { }    
