import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public authService: AuthService, public router: Router) { }

  /*async canActivate() {
    
    
    
    if (!await this.authService.currentUserValue) {
      await this.router.navigate(['login']);
      return false;
    }
    return true;
  }*/
  
  async canActivate() {
    //
    //
    if (JSON.stringify(this.authService.currentUserValue)=="{}"){
      await this.router.navigate(['login']);
      return false;
    }else if (this.authService.currentUserValue.access_token!='') {
      return true;
    }else{
      await this.router.navigate(['login']);
      return false;
    }
 
  }   
}
