<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--术语及异常项目导入--</h1>
</div>
<mat-tab-group   mat-stretch-tabs="false" mat-align-tabs="center"  color="primary">
    <mat-tab label="术语导入">
        <app-term-import [parent]="this"></app-term-import>
    </mat-tab>
    <mat-tab label="异常项目导入">
       <app-abnormal-import [parent]="this"></app-abnormal-import>
    </mat-tab>
   
</mat-tab-group>