import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

export function ApiHandleError(error:HttpErrorResponse) {
    let errorMessage = '';
    
    if (error.error instanceof ErrorEvent) {
      errorMessage = `客户端错误:${error.error.message}`;
    } else {
      errorMessage = `服务器错误：${error.status}  ${error.error.text}`;
      if(error.status==200){
        const parser = new DOMParser()
        const xmlData = error.error.text
        const xmlDoc = parser.parseFromString(xmlData,'text/xml')
        //获取RETCODE的值
        const retcodeElement = xmlDoc.getElementsByTagName('RETCODE')[0]
        const retCodeValue = retcodeElement.textContent
        
        if (retCodeValue=='00'){
          errorMessage = ''
        }else{
          errorMessage = `传入文件参数错误：${error.status}  ${error.error.text}`;
        }
      }
    }
    return throwError(errorMessage);
  }