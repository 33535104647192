<mat-toolbar color="primary">
    <!-- <span class="fill-remaining-space">
        <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            (mouseover)="menuTrigger.openMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="fill-remaining-space">体检费用管理</span>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <span (mouseleave)="menuTrigger.closeMenu()">
                <button mat-menu-item
                    (click)="onMenuAction('proc-going-order')"><mat-icon>note_add</mat-icon>团检订单缴费</button>
                <button mat-menu-item
                    (click)="onMenuAction('technology')"><mat-icon>trending_up</mat-icon>团队订单补缴</button>
                <button mat-menu-item
                    (click)="onMenuAction('cost_return')"><mat-icon>delete_forever</mat-icon>团检订单退费</button>
                <button mat-menu-item
                    (click)="onMenuAction('cost_return')"><mat-icon>delete_forever</mat-icon>团体费用清单补打</button>
            </span>
        </mat-menu>
    </span> -->
    <span class="fill-remaining-space">体检费用管理</span>
</mat-toolbar>
<mat-card class="reg-card" *appAuth="['管理员','体检科','财务']">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image_order"></div>
        <mat-card-title><b>体检费用管理</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary"
                (click)="onMenuAction('individual-order')" *appAuth="['管理员','体检科','财务']"><mat-icon>perm_identity</mat-icon>个人体检费用结算</button>
                <button mat-raised-button color="primary"
                (click)="onMenuAction('individual-order-sum')" *appAuth="['管理员','体检科','财务']"><mat-icon>perm_identity</mat-icon>个人体检费用信息查询</button>
            <button mat-raised-button color="primary"
                (click)="onMenuAction('group-unit-orders')" *appAuth="['管理员','体检科']"><mat-icon>group</mat-icon>团检客户费用信息查询</button>
            <div *appAuth="['管理员','体检科','财务']">
                <button mat-raised-button color="primary" (click)="onMenuAction('check-transfer')"
                    *ngIf="session_physical_type=='健康体检'"><mat-icon>autorenew</mat-icon>体检名额转让</button>
            </div>

            <!-- <button mat-raised-button color="primary"
                (click)="onMenuAction('technology')"><mat-icon>trending_up</mat-icon>个人订单补缴</button>
            <button mat-raised-button color="primary"
                (click)="onMenuAction('technology')"><mat-icon>trending_up</mat-icon>个人订单退费</button>
            <button mat-raised-button color="primary"
                (click)="onMenuAction('technology')"><mat-icon>trending_up</mat-icon>个人费用清单补打</button> -->

        </div>
    </mat-card-actions>
</mat-card>
<br />
<!-- <mat-card class="reg-card">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image_order"></div>
        <mat-card-title><b>团检费用管理</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary"
                (click)="onMenuAction('group-unit-orders')"><mat-icon>note_add</mat-icon>团检客户费用信息查询</button>
        </div>
    </mat-card-actions>
</mat-card> -->
<br />