<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color:blue"><b>查询与统计</b></h1>
    <h3><b>健康体检结果汇总</b></h3>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" [formGroup]="search_form">
    <mat-form-field appearance="fill" style="width: 19%;">
        <mat-label>登记时间</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start_date" placeholder="Start date">
            <input matEndDate formControlName="end_date" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="search_form.controls.start_date.hasError('matStartDateInvalid')">Invalid start
            date</mat-error>
        <mat-error *ngIf="search_form.controls.end_date.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 39%;">
        <mat-label>团检单位名称</mat-label>
        <input matInput placeholder="请选择人员所在企业名称" formControlName="group_unit_id" [matAutocomplete]="enterprise" />
        <mat-autocomplete requireSelection #enterprise="matAutocomplete" [displayWith]="displayFn_e.bind(this)">
            <mat-option *ngFor="let e of unit_list |async" [value]="e">{{e.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>订单类型</mat-label>
        <mat-select formControlName="order_type">
            <mat-option value="">全部</mat-option>
            <mat-option value="团检">团检</mat-option>
            <mat-option value="个检">个检</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>性别</mat-label>
        <mat-select formControlName="person_gender" multiple>
            <mat-option value=1>男</mat-option>
            <mat-option value=2>女</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>姓名</mat-label>
        <input type="text" matInput placeholder="请输入部分内容.." formControlName="person_name">
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>体检编号</mat-label>
        <input type="text" matInput placeholder="请输入部分内容.." formControlName="test_num">
    </mat-form-field>
    <div style="width:39%">
        <nz-slider [nzMarks]="marks" nzRange formControlName="age_range"></nz-slider>
        <nz-input-group nzPrefix="年龄范围：" nzSuffix="岁">
            <nz-input-number [formControl]="min_age" [nzMin]="0" [nzMax]="100" [nzStep]="1"
                style="width:40%"></nz-input-number>
            <nz-input-number [formControl]="max_age" [nzMin]="0" [nzMax]="100" [nzStep]="1"
                style="width:40%"></nz-input-number>
        </nz-input-group>
    </div>

</div>
<br>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <button mat-raised-button color="primary" (click)="search()">开始统计</button>
    <button mat-stroked-button color="warn" (click)="replay()" matTooltip="重置搜索项"><mat-icon>replay</mat-icon>重置</button>
    <button mat-raised-button color="primary" (click)="export_excel()">导出为excel</button>

</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" style="width: 100%;">
    <table>
        <tr style="font-size: 18px;">
            <th>健康体检结果汇总记录共：{{dataSource.data.length}}</th>
            <th>健康体检结果汇总人数：{{recheck_person_count}}</th>
        </tr>
    </table>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div style="min-height:100px;overflow: auto;max-height: 80vh; width: 100%; ">
        <table mat-table [dataSource]='dataSource' matSort (matSortChange)="sortChange($event)" style="width: 100%;">
            <ng-container matColumnDef="group_unit_name">
                <th *matHeaderCellDef mat-sort-header>所属单位</th>
                <td *matCellDef="let sa">{{sa.group_unit_name}}</td>
            </ng-container>
            <ng-container matColumnDef="order_id">
                <th *matHeaderCellDef mat-sort-header>订单号</th>
                <td *matCellDef="let sa">{{sa.order_id}}</td>
            </ng-container>
            <ng-container matColumnDef="test_num">
                <th *matHeaderCellDef mat-sort-header>体检号</th>
                <td *matCellDef="let sa">{{sa.test_num}}</td>
            </ng-container>>
            <ng-container matColumnDef="person_name">
                <th *matHeaderCellDef mat-sort-header>姓名</th>
                <td *matCellDef="let sa">{{sa.person_name}}</td>
            </ng-container>
            <ng-container matColumnDef="gender">
                <th *matHeaderCellDef mat-sort-header>性别</th>
                <td *matCellDef="let sa">{{sa.gender|sexStates}}</td>
            </ng-container>
            <ng-container matColumnDef="age">
                <th *matHeaderCellDef mat-sort-header>年龄</th>
                <td *matCellDef="let sa">{{sa.age}}</td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th *matHeaderCellDef mat-sort-header>体检综述</th>
                <td *matCellDef="let sa">{{sa.title}}</td>
            </ng-container>
            <ng-container matColumnDef="suggest">
                <th *matHeaderCellDef mat-sort-header>体检建议</th>
                <td *matCellDef="let sa">{{sa.suggest}}</td>
            </ng-container>
            <ng-container matColumnDef="register_date">
                <th *matHeaderCellDef mat-sort-header>体检登记时间</th>
                <td *matCellDef="let sa">{{sa.register_date|date:"yyyy-MM-dd HH:mm:ss"}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='displayedColumns;sticky: true'></tr>
            <tr mat-row *matRowDef='let row; columns:displayedColumns'></tr>
        </table>
    </div>
</div>
<br><br><br><br>