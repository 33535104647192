import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-abnormal-import',
  templateUrl: './abnormal-import.component.html',
  styleUrls: ['./abnormal-import.component.css'],
  providers: [DatePipe]

})
export class AbnormalImportComponent implements OnInit {
  @Input() parent:any;
  
  dataSource=new MatTableDataSource([])
  selection = new SelectionModel<any>(true, []);

  constructor(
    private notifyService:NotificationService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private matDialogRef:MatDialogRef<AbnormalImportComponent>,
  ) {   
  }
  displayedColumns=['select','value']

  ngOnInit(): void {
    
    this.dataSource = new MatTableDataSource(this.dialog_data.data)
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  import(){
    
    let result = this.selection.selected.map(m=>m.value).reduce((acc,value)=>(acc?acc+'\n':'')+value.trim(),'')
    this.matDialogRef.close(result)
  }

}
