<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>体检信息管理</b></h1>
    <h3><b>职业体检网报（个人）</b></h3>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" [formGroup]="search_form">
    <mat-form-field appearance="fill" style="width: 19%;">
        <mat-label>体检日期</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="search_form.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="search_form.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <app-group-unit-selecter style="width: 49%;" #group_unit_selecter [parent]="this"></app-group-unit-selecter>
    <mat-form-field style="width:29%" appearance="fill">
        <mat-label>在岗状态</mat-label>
        <mat-select multiple formControlName="work_state_codes">
            <mat-option *ngFor="let i of work_state_list" [value]="i.value">{{i.title}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
        <mat-form-field appearance="fill" style="width: 19%;">
            <mat-label>危害因素查询</mat-label>
            <mat-select (selectionChange)="hazard_search_type_change($event)" [value]="hazard_search_type">
                <mat-option value="1">根据危害因素种类查询</mat-option>
                <mat-option value="2">根据危害因素查询</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width:80%" *ngIf="hazard_search_type=='1'">
            <mat-label>危害类型</mat-label>
            <mat-select formControlName="hazard_factor_types" (selectionChange)="type_select($event)" multiple>
                <mat-option *ngFor="let i of type_list" [value]="i.id">{{i.type_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width:80%" appearance="fill" (dblclick)="hazard_factors_add()"
            *ngIf="hazard_search_type=='2'">
            <mat-label>危害因素(双击添加)</mat-label>
            <mat-chip-list formArrayName="hazard_factors">
                <mat-chip *ngFor="let hazard_factor of hazard_factors.controls;let j =index" selectable removable
                    (removed)="delete_hazard_factor(j)">
                    {{hazard_factor.get('type_name').value}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>
    </div>
    <mat-form-field appearance="fill" style="width: 99%;" >
        <mat-label>工种名称</mat-label>
        <mat-chip-list #chipList8 >
            <mat-chip *ngFor="let type of work_types.controls;let i=index" selectable removable 
            (removed)="remove_form_list_items(i)"  [matAutocompon]>
                {{type.get('title').value}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-label>|</mat-label>
            <input placeholder="请选择工种名称,输入部分内容可检索......" matInput [matChipInputFor]="chipList8"
                [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="add_2($event)" [matAutocomplete]="work_type"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [formControl]="work_type_control">
            <mat-autocomplete requireSelection #work_type="matAutocomplete" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let w of work_type_list |async" [value]="w"
                    (onSelectionChange)="add_effect($event)">{{w.value}}:{{w.title}}</mat-option>
            </mat-autocomplete>
        </mat-chip-list>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>搜索字段</mat-label>
        <mat-select formControlName="input_cate">
            <mat-option value="test_num">体检编号</mat-option>
            <mat-option value="person_name">姓名</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width:19%">
        <mat-label>请输入查询内容..</mat-label>
        <input matInput formControlName="input_text"  (keyup.enter)="search()"/>
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="search()"><mat-icon>search</mat-icon>搜索</button>
    <button mat-stroked-button color="warn" (click)="replay()" matTooltip="重置搜索项"><mat-icon>replay</mat-icon>重置</button>
    <button mat-stroked-button color="primary" (click)="upload_persons(null,'1')"><mat-icon><span
                class="material-symbols-outlined">
                arrow_upward_alt
            </span></mat-icon>上传选中</button>

</div>
<div *ngIf="print_flag">
    <mat-spinner></mat-spinner>
</div>
<br>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-table class="drug-insert-table mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="test_num">
        <mat-header-cell *matHeaderCellDef><b>体检编号</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.test_num}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="person_name">
        <mat-header-cell *matHeaderCellDef><b>姓名</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.anonymity==1?(row.person_name|nameHide):row.person_name}}<span style="color:blue">{{row.is_recheck==1?'（复检）':''}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="sex">
        <mat-header-cell *matHeaderCellDef><b>性别</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.sex|sexStates}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef><b>年龄</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.age}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dept">
        <mat-header-cell *matHeaderCellDef><b>单位名称</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.dept}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="hazard_factors_text">
        <mat-header-cell *matHeaderCellDef><b>危害因素</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.hazard_factors_text}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="other_hazard_factors">
        <mat-header-cell *matHeaderCellDef><b>其他危害因素名称</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.other_hazard_factors}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="work_type_text">
        <mat-header-cell *matHeaderCellDef><b>工种名称</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.work_type_text}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="work_state_text">
        <mat-header-cell *matHeaderCellDef><b>在岗状态</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.work_state_text}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="check_date">
        <mat-header-cell *matHeaderCellDef><b>体检日期</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.check_date|date:"yyyy-MM-dd"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="regist_date">
        <mat-header-cell *matHeaderCellDef><b>登记时间</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.regist_date|date:"yyyy-MM-dd HH:mm:ss"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="operate">
        <mat-header-cell *matHeaderCellDef><b>操作</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            <button mat-button (click)="upload_person(row,'1')" color="primary">{{row.upload_status==0?'上传':'重新上传'}}</button>
            <button mat-button (click)="upload_person(row,'3')" color="warn" *ngIf="row.upload_status!=0">删除</button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [length]="dataSource.length" [pageSize]="15" [pageSizeOptions]="[15,30, 60]" showFirstLastButtons>
</mat-paginator>