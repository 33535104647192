import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-time-change',
  templateUrl: './time-change.component.html',
  styleUrls: ['./time-change.component.css']
})
export class TimeChangeComponent implements OnInit {
  delivery_time = ''
  title = ''
  constructor(
    public dialogRef: MatDialogRef<TimeChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.delivery_time = this.data.delivery_time
    this.title = this.data.title
  }
  close(){
    this.dialogRef.close(this.delivery_time)
  }

}
