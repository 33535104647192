import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-zone-select',
  templateUrl: './zone-select.component.html',
  styleUrls: ['./zone-select.component.css']
})
export class ZoneSelectComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ZoneSelectComponent>,
    private fb: FormBuilder,
    private commonService: CommonService,
    private notifyService: NotificationService,

  ) { }
  title = '单位所属地区选择'
  city_list = []
  county_list = []
  street_list = []

  provinces = ['辽宁省', '内蒙古自治区', '天津市', '北京市', '河北省', '山西省', '吉林省', '黑龙江省', '上海市', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省',
    '广西壮族自治区', '海南省', '重庆市', '四川省', '贵州省', '云南省', '西藏自治区', '陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区']; // 假设这里是从后端获取的省份列表，目前只有两个省需要体检。

  zone_group = this.fb.group({
    // province: '辽宁省',
    // province:  new FormControl('', []),
    province: '', //初始化为空的先
    city: '',
    county: '',
    street: ''
  })
  result = false
  ngOnInit(): void {
    this.get_city_list()
  }
  close() {

    if (this.result) {
      this.dialogRef.close(this.result)
    } else {
      this.notifyService.showWarning('请选择到最末级', '警告')
    }
  }
  get_select_zone_list(value, zone_type) {

    let input_data = {
      'title': value.title,
      'zone_type': zone_type
    }

    this.commonService.get_select_zone_list(input_data).subscribe(res => {

      if (zone_type == 'county') {
        this.county_list = res.value
      } else if (zone_type == 'street') {
        this.street_list = res.value
      } else if (zone_type == 'end') {
        this.result = value
      }
    })
  }
  get_city_list() {
    const selectedProvince = this.zone_group.get('province').value;
    let input_data = {
      // 'title':'辽宁省',
      // 'zone_type': 'city',
      'title': selectedProvince,
      'zone_type': 'city'
    }
    this.commonService.get_select_zone_list(input_data).subscribe(res => {

      this.city_list = res.value
    })

  }

  //选择省份，更新对应值
  onProvinceChange(province: string) {
    this.zone_group.patchValue({ province });  // 更新 province 的值
    this.get_city_list(); // 调用获取城市列表的方法
  }

}
