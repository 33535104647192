import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PeResultService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }

  sch_pe_result_check_list(group_unit_name,section_code,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,start_date,end_date,print_flag): Observable<any> {
    let params = new HttpParams()
    .set('section_code',section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('start_date',start_date)
      .set('end_date',end_date)
      .set('physical_type',sessionStorage.getItem('physical_type'))
      .set('print_flag',print_flag)
      .set('group_unit_name',group_unit_name)

    return this.http.get<any>(`${environment.apiUrl}/basic/sch_pe_result_check_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  generate_total_check_sheet(input_data){
    return this.http.post<any>(`${environment.apiUrl}/basic/generate_total_check_sheet/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }


  generate_total_check_sheet_list(input_data){
    return this.http.post<any>(`${environment.apiUrl}/basic/generate_total_check_sheet_list/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  sch_result_check_list(input_data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/basic/sch_result_check_list/`, input_data,this.httpOptions)
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
}
