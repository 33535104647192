import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  emrUrl = environment.emrUrl; //住院电子病历入口
  nuseUrl = environment.nuseUrl; //护理病历入口
  outemrUrl = environment.outemrUrl; //门诊电子病历入口
  pass_Url = environment.passUrl; //合理用药主入口
  constructor() { }

  ngOnInit() {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

}
