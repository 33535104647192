import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pePlan'
})
export class PePlanPipe implements PipeTransform {

  transform(value:number): string {
    if (value==0){
      return '未登记'
    }else if(value==1){
      return '已登记'
    }else if(value==2){
      return '在检'
    }else if(value==3){
      return '总检'
    }else if(value==4){
      return '完成'
    }else if(value==-1){
      return '未交款'
    }else if(value==-2){
      return '预约'
    }else if(value==-3){
      return '已转让'
    }
    else{
      return '错误'
    }
  }

}
