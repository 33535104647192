<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>体检信息管理</b></h1> 
    <h3><b>体检报告查询与打印</b></h3>
</div>
<mat-tab-group  mat-stretch-tabs="false" mat-align-tabs="center" color="accent" backgroundColor="primary" #tabs>
    <mat-tab label="体检人员列表">
        <app-list [parent]="this" #list></app-list>
    </mat-tab>
    <mat-tab label="体检报告详情">
        <app-detail [parent]="this" #detail *ngIf="session_physical_type=='职业体检'"></app-detail>
        <app-detail-health [parent]="this" #detail *ngIf="session_physical_type=='健康体检'"></app-detail-health>

    </mat-tab>
  </mat-tab-group>

