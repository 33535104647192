<mat-toolbar color="primary">
    <span class="fill-remaining-space">体检信息管理</span>
</mat-toolbar>

<mat-card class="reg-card"  *appAuth="['管理员','体检科']">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image"></div>
        <mat-card-title><b>客户信息维护</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>

    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" [routerLink]="['/group-unit']">团检客户信息维护</button>            
            <button mat-raised-button color="primary" [routerLink]="['/group-order']">团检订单信息维护</button>  
            <button mat-raised-button color="primary" [routerLink]="['/individual-order']">个人体检订单信息维护</button>  
            <button mat-raised-button color="primary" class="half-external-button" [routerLink]="['/group-persons']">体检人员信息维护</button>
        </div>
    </mat-card-actions>
</mat-card>
<br />
<mat-card class="reg-card"  >
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image"></div>
        <mat-card-title><b>查询与打印</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>

    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" [routerLink]="['/basic/group-nav-sheets-print']"  *appAuth="['管理员','体检科']">团检提前打印导检单</button>  
            <button mat-raised-button color="primary"[routerLink]="['/basic/peplan']"  *appAuth="['管理员','体检科','分检科室','总检科室','前台','问诊科室']">体检人员进度查询</button>            
            <button mat-raised-button color="primary" [routerLink]="['/basic/peresult']"  *appAuth="['管理员','体检科']"><mat-icon>person</mat-icon>体检报告查询与打印</button>  
            <div  *appAuth="['管理员','体检科']">
                <button mat-raised-button color="primary" [routerLink]="['/basic/group-unit-summary']"   *ngIf="this.session_physical_type=='职业体检'" ><mat-icon>groups</mat-icon> 团检单位职业体检报告</button>

            </div>
        </div>
    </mat-card-actions>
</mat-card>
<br />
<div *appAuth="['管理员','体检科']">
 <mat-card class="reg-card"  *ngIf="this.session_physical_type=='职业体检'"  >
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image"></div>
        <mat-card-title><b>职业体检网报</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <!-- <button mat-raised-button color="primary" [routerLink]="['/basic/test-upload']">测试数据上传</button>  -->
            <button mat-raised-button color="primary"  [routerLink]="['/basic/enterprise-information-upload']">企业信息上传</button>    
            <button mat-raised-button color="primary" [routerLink]="['/basic/person-infor-load']">人员体检数据上传</button>         
        </div>
    </mat-card-actions>
</mat-card>   
</div>

<br />
<br />
<br />
<br />
