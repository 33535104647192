<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>{{title}}</h1>
</div>
<hr>
<div [formGroup]="term_set" style="width:100%;height:84%;">
    <div style="width: 100%" fxLayout="row wrap" fxLayoutGap="1%">
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>执行科室</mat-label>
            <mat-select formControlName="office_id" >
                <mat-option value="">所有科室</mat-option>
                <mat-option *ngFor="let i of his_dept_list" [value]="i.id">{{i.section_code}}:{{i.section_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>体检类型</mat-label>
            <mat-select formControlName="inspect_type" multiple (selectionChange)="inspect_type_change($event)">
                <mat-option *ngFor="let i of inspect_type_list" [value]="i.title">{{i.title}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>术语类型</mat-label>
            <mat-select formControlName="type"   (selectionChange)="type_change($event)" >
                <mat-option value="">全部</mat-option>
                <mat-option *ngFor="let i of type_list" [value]="i.value">{{i.title}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width:24%" appearance="fill">
            <mat-label>状态</mat-label>
            <mat-select formControlName="status" >
                <mat-option value="0">启用</mat-option>
                <mat-option value="1">停用</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 49%;" *ngIf="zy_flag&&not_qtjbhycjg">
            <mat-label>在岗状态(不填写时，默认为全部)</mat-label>
            <mat-select formControlName="work_state" multiple >
                <mat-option *ngFor="let i of work_state_text_list" [value]="i">{{i.title}}</mat-option>
            </mat-select>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="start center" style="width: 49%;" *ngIf="zy_flag&&not_qtjbhycjg">
            <mat-form-field appearance="fill" style="width:40%">
                <mat-label>危害因素</mat-label>
                <mat-select formControlName="hazard_type" (selectionChange)="type_select()">
                    <mat-option value="">全部</mat-option>
                    <mat-option *ngFor="let i of hazard_type_list" [value]="i.id">{{i.type_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width:60%">
                <mat-label>危害因素子项</mat-label>
                <input matInput type="text" formControlName="hazard_factors"  [matAutocomplete]="type_name"  />
                <mat-autocomplete #type_name = "matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions |async" [value]="option">
                    {{option.type_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <mat-form-field style="width:100%" appearance="fill">
            <mat-label>术语内容</mat-label>
            <textarea matInput formControlName="content" placeholder="请输入术语内容" matTextareaAutosize></textarea>
        </mat-form-field>
        
    </div>
    <hr>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="20px"
        style="height: 10%;width: 100% !important;">
        <button mat-raised-button color="warn" [matDialogClose]="false">取消</button>
        <button mat-raised-button color="primary"  [disabled]="submit_disabled" (click)="submit()">确定</button>
    </div>
</div>