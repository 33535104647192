<mat-toolbar color="primary">
    <!-- <span class="fill-remaining-space">
        <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            (mouseover)="menuTrigger.openMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="fill-remaining-space">体检中心</span>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <span (mouseleave)="menuTrigger.closeMenu()">
                <button mat-menu-item  (click)="onMenuAction('pe-register')"><mat-icon>note_add</mat-icon>体检登记</button>
                <button mat-menu-item   (click)="onMenuAction('technology')"><mat-icon>trending_up</mat-icon>体检诊台（分诊）</button>
                <button mat-menu-item   (click)="onMenuAction('cost_return')"><mat-icon>delete_forever</mat-icon>医生总检</button>
            </span>
        </mat-menu>
    </span> -->
    <span class="fill-remaining-space">体检中心</span>
</mat-toolbar>
<mat-card class="reg-card" *appAuth="['管理员','体检科','分检科室','总检科室','问诊科室']">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image_order"></div>
        <mat-card-title><b>体检中心</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" (click)="onMenuAction('pe-register')"  *appAuth="['管理员','体检科']"><mat-icon>note_add</mat-icon>体检登记</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('ask-check')"  *appAuth="['管理员','体检科','问诊科室']"><mat-icon>mood</mat-icon>体检问诊</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('single-check')" *appAuth="['管理员','体检科','分检科室']"><mat-icon>trending_up</mat-icon>体检诊台（分诊）</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('lis-check')" *appAuth="['管理员','体检科','化验室']"><mat-icon>trending_up</mat-icon>化验科分诊审核</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('total-check')" *appAuth="['管理员','体检科','总检科室']"><mat-icon>trending_up</mat-icon>医生总检</button>
        </div>
    </mat-card-actions>
</mat-card>
<br />

<mat-card class="reg-card"  *appAuth="['管理员','体检科','前台']">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image_order"></div>
        <mat-card-title><b>前台登记回收</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" (click)="onMenuAction('reprint')"><mat-icon>print</mat-icon>导检单&化验单条码补打</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('guidance-recycle')"><mat-icon>print</mat-icon>导检单登记回收</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('confirm-inspect')"><mat-icon>done</mat-icon>送检确认</button>
            <!-- <button mat-raised-button color="primary" (click)="onMenuAction('major-anomaly')"><mat-icon>grade</mat-icon>重大异常项目管理</button> -->

        </div>
    </mat-card-actions>
</mat-card>