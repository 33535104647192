import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  async onMenuAction(tpage: string) {
    switch (tpage) {
      case "pe-register": { // 体检登记
        this.router.navigate(['/content/register']);
        break;
      }
      case "single-check":{//体检分检
        this.router.navigate(['/content/singlecheck']);
        break;
      }
      case "total-check":{//体检总检
        this.router.navigate(['/content/totalcheck']);
        break;
      }
      case "term-import":{ // 术语信息导入，测试
        this.router.navigate(['content/term-import'])
        break;
      }
      case "ask-check":{ // 术语信息导入，测试
        this.router.navigate(['/content/askcheck'])
        break;
      }
      case "reprint":{ // 补打
        this.router.navigate(['/content/reprint'])
        break;
      }
      case "confirm-inspect":{ // 送检确认
        this.router.navigate(['/content/confirm-inspect'])
        break;
      }
      case "guidance-recycle":{
        this.router.navigate(['/content/reprint/guidance-recycle'])
        break;
      } 
      case "major-anomaly":{
        this.router.navigate(['/content/reprint/guidance-recycle'])
        break;
      }
      case "lis-check":{
        this.router.navigate(['/content/lis-check'])
        break;
      }
      default: {
        break;
      }
    }
  }
}