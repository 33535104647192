import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/utils/notification.service';
import { DialogModelsService } from '../dialog-models.service';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

  constructor(
    private dialogRef : MatDialogRef<UserValidationComponent>,
    private fb:FormBuilder,
    private dialogModelsService:DialogModelsService,
    private notifyService: NotificationService,

  ) { }
    user :FormGroup
  ngOnInit(): void {
    this.user = this.fb.group({
      'password':''
    })
  }

    close(s){
      let input_data ={
        'password':this.user.value.password
      }
      this.dialogModelsService.validation_user(input_data).subscribe(res=>{
        
        if(res.flag){
          this.dialogRef.close(s)
        }else{
          this.notifyService.showError('密码错误','错误')
        }
      })
    }
}
