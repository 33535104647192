import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TotalCheckService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }
  sch_total_check_list(start_date,end_date,section_code, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,recheck_flag): Observable<any> {
    let params = new HttpParams()
      .set('section_code', section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('physical_type', sessionStorage.getItem('physical_type'))
      .set('recheck_flag',recheck_flag)
      .set('start_date',start_date)
      .set('end_date',end_date)


    return this.http.get<any>(`${environment.apiUrl}/content/sch_total_check_list/`, { params })
      .pipe(
        map(res => {

          return res
        }),
        catchError(handleError)
      )
  }

  search_order_group_by_id(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/search_order_group_by_id/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  confirm_arrival(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/confirm_arrival/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  generate_person_navigation_sheet(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_sheet/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  generate_person_navigation_bar_code(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_bar_code/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }


  item_search_all_data(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/item_search_all_data/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  update_depart_item_result(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/update_depart_item_result/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_combo_others_for_person(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/search_combo_others_for_person/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }


  search_total_check_combo_data(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/search_total_check_combo_data/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }


  search_total_check_result_by_id(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/search_total_check_result_by_id/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  save_total_check_result(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/save_total_check_result/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  start_or_cancel_total_check(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/start_or_cancel_total_check/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  /*
  整合这四个查询放到一个查询里：
  search_order_group_by_id
  search_total_check_combo_data
  search_total_check_result_by_id
  search_combo_others_for_person
  */
  search_total_check_data(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/search_total_check_data/`,input_data,this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }


  insert_mongo_recheck_items(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/insert_mongo_recheck_items/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  get_recheck_items_by_previous_test_num(previous_test_num): Observable<any> {
    let params = new HttpParams()
      .set('previous_test_num', previous_test_num)

    return this.http.get<any>(`${environment.apiUrl}/content/get_recheck_items_by_previous_test_num/`, { params })
      .pipe(
        map(res => {

          return res
        }),
        catchError(handleError)
      )
  }

  del_recheck_item_by_id(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/del_recheck_item_by_id/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  edit_recheck_item_by_id(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/content/edit_recheck_item_by_id/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  
  get_group_unit_detail_by_id(unit_id): Observable<any> {
    let params = new HttpParams()
      .set('unit_id', unit_id)

    return this.http.get<any>(`${environment.apiUrl}/content/get_group_unit_detail_by_id/`, { params })
      .pipe(
        map(res => {

          return res
        }),
        catchError(handleError)
      )
  }
}
