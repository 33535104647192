import { HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-images-result-dialog',
  templateUrl: './images-result-dialog.component.html',
  styleUrls: ['./images-result-dialog.component.css']
})
export class ImagesResultDialogComponent implements OnInit {
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;

  file_list = []
  index = 0
  previous_button_disable = false
  next_button_disable = false
  constructor(
    public dialogRef: MatDialogRef<ImagesResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService
  ) {
    
    this.file_list = data.file_list

  }
  url = ''
  ngOnInit(): void {
    if (this.file_list.length > 0) {
      this.nav_button_change()
    }
  }

  previous_page() {
    
    this.index -= 1
    this.nav_button_change()
  }
  next_page() {
    
    this.index += 1
    this.nav_button_change()
  }

  nav_button_change() {
    if (this.index <= 0) {
      this.previous_button_disable = true
    } else {
      this.previous_button_disable = false
    }
    if (this.index >= this.file_list.length - 1) {
      this.next_button_disable = true
    } else {
      this.next_button_disable = false
    }
    
    let pdfUrl = this.file_list[this.index].fileurl
    this.commonService.get_nopaper_pdf_by_path(pdfUrl).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/pdf' });
      this.pdfViewerAutoLoad.pdfSrc = window.URL.createObjectURL(blob);
      this.pdfViewerAutoLoad.refresh();
    });
  }
}
