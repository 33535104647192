<div  style="width: 100%;" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1%">
    <button mat-icon-button color="warn" [mat-dialog-close]="false" matTooltip="取消"  ><mat-icon>cancel</mat-icon> </button>
</div>
<div style="width: 100%" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
    <img style="width: 300px;height: 150px; " *ngIf="avatarUrl" [src]="avatarUrl" />
    <canvas id="canvas" [style.display]="'none'">
    </canvas>
</div>
<br>
<div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
    <input type="file" name="file" accept="image/*" #fileInput style="display: none" (change)="handleFiles($event)"
        hidden="true" />
    <button mat-stroked-button color="primary" (click)="fileInput.click()" matTooltip="上传照片"><mat-icon>image</mat-icon></button>
    <button mat-stroked-button color="accent" (click)="save()" matTooltip="确定并保存"><mat-icon>done</mat-icon> </button>
</div>