import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SingleCheckService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }
  sch_single_check_list(section_code,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number): Observable<any> {
    let params = new HttpParams()
    .set('section_code',section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('physical_type',sessionStorage.getItem('physical_type'))

    return this.http.get<any>(`${environment.apiUrl}/content/sch_single_check_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  
  search_order_group_by_id(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/search_order_group_by_id/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  confirm_arrival(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/confirm_arrival/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  generate_person_navigation_sheet(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_sheet/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  generate_person_navigation_bar_code(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_bar_code/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  

  item_search_all_data(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/item_search_all_data/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  get_person_item_data(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/get_person_item_data/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  update_depart_item_result(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/update_depart_item_result/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  
  search_combo_others_for_person(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/search_combo_others_for_person/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }


  get_lis_result(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/get_lis_result/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  sch_single_check_list_limit(start_date,end_date,group_unit_name,section_code,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,recheck_flag): Observable<any> {
    let params = new HttpParams()
    .set('section_code',section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('physical_type',sessionStorage.getItem('physical_type'))
      .set('recheck_flag',recheck_flag)
      .set('group_unit_name',group_unit_name)
      .set('start_date',start_date)
      .set('end_date',end_date)



    return this.http.get<any>(`${environment.apiUrl}/content/sch_single_check_list_limit/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }

  search_single_check_items_by_id(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/search_single_check_items_by_id/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  
  get_nopaper_results_by_condition(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/get_nopaper_results_by_condition/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  sync_lis_result_data(input_data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/content/sync_lis_result_data/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
}
