import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-indus-type-select',
  templateUrl: './indus-type-select.component.html',
  styleUrls: ['./indus-type-select.component.css']
})
export class IndusTypeSelectComponent implements OnInit {
  
  constructor(
    private dialogRef :MatDialogRef<IndusTypeSelectComponent>,
    private fb:FormBuilder,
    private commonService:CommonService,
    private notifyService: NotificationService,

  ) { }
  title = '行业类别选择'
  level_1_list = []
  level_2_list = []
  level_3_list = []
  level_4_list =[]
  zone_group = this.fb.group({
    level_1:'',
    level_2:'',
    level_3:'',
    level_4:''
  })
  result = false
  ngOnInit(): void {
    this.get_city_list()
  }
  close() {
    if(this.result){
      this.dialogRef.close(this.result)
    }else{
      this.notifyService.showWarning('请选择到最末级','警告')
    }
  }
  get_select_indus_type_list(event,indus_type){
    let input_data ={
      'title':event.title,
      'indus_type':indus_type
    }
    
    this.commonService.get_select_indus_type_list(input_data).subscribe(res=>{
      
      if (indus_type=='2'){
        this.level_2_list = res.value
        this.level_3_list = []
        this.level_4_list = []
        
        if(this.level_2_list.length==0){
          this.result = event
        }
      }else if(indus_type=='3'){
        this.level_3_list = res.value
        this.level_4_list = []
        
        if(this.level_3_list.length==0){
          this.result = event
        }
      }else if(indus_type=='4'){
        this.level_4_list = res.value
        
        if(this.level_4_list.length==0){
          this.result = event
        }
      }else{
        this.result = event
      }
    })
  }
  get_city_list(){
    let input_data ={
      'title':'',
      'indus_type':'1'
    }
    this.commonService.get_select_indus_type_list(input_data).subscribe(res=>{
      
      this.level_1_list = res.value
    })
    
  }
}
