import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermSetService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }
  // 查询术语列表
  sch_term_set_list(search_condition, tpage_nmb: number, tn_per_page: number): Observable<any> {

    let input_data = {
      'search_condition': search_condition,
      'tpage_nmb': String(tpage_nmb),
      'tn_per_page': String(tn_per_page)
    }
    
    return this.http.post<any>(`${environment.apiUrl}/sysset/sch_term_set_list/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  //删除选中的团检客户信息
  delete_selected(selected: any) {

    let input_data = {
      "selected": selected
    }
    
    return this.http.post<any>(`${environment.apiUrl}/sysset/delete_selected_term_set_list/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  //新增术语信息
  insert_term_set(term_set) {
    let input_data = {
      'term_set': term_set
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/insert_term_set/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  //修改术语信息
  edit_term_set(term_set) {
    let input_data = {
      'term_set': term_set
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/edit_term_set/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  get_term_set(term_set){
    let input_data={
      'term_set': term_set
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/get_term_set/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  get_hazard_type_by_hazard_factors(input_data){
    return this.http.post<any>(`${environment.apiUrl}/sysset/get_hazard_type_by_hazard_factors/`,input_data,this.httpOptions)
    .pipe(
      map(res=>{
        return res  
      }),catchError(handleError)
    )
  }
}
