import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Hsp } from "src/app/models/hsp";
import { NotificationService } from "src/app/utils/notification.service";
import { PeResultService } from "../../basic/pe-result/pe-result.service";

export class PeResultDatasource implements DataSource<any>{
    private patSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: any[];
    public length: number

    constructor(private peResultService: PeResultService,    private notifyService: NotificationService,
        ) { }

    loadtables(input_data) {

        this.loadingSubject.next(true);
        this.peResultService.sch_result_check_list(input_data)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(res => {
                
                if(res.flag){
                    let thsp = res.value
                    let t_page_nmb = input_data.tpage_nmb
                    let tn_per_page = input_data.tn_per_page
                    
                    
                    this.data = <any[]>thsp.data.slice(t_page_nmb*tn_per_page,(t_page_nmb+1)*tn_per_page);
                    this.length =thsp.data.length
                    this.patSubject.next( this.data)
                }else{
                    this.notifyService.showError(res.value,'错误')
                    this.data =[]
                    
                    this.length = 0
                    this.patSubject.next([])
                }
              
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}