<div style="width: 100%;" [ngClass]="{'unclickable':(loading_count!=4||loading)}">
    <div [formGroup]="group_person" style="margin-left:1%;">
        <hr>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1%" *ngIf="group_person.value.is_recheck=='1'">
            <h2 style="color:blue">上次体检编号：{{group_person.value.last_test_num}}</h2>
            <h2 style="color: red;background-color: rgb(243, 205, 37);width: 120px;"><b>复检</b></h2>
        </div>
        <table id="group_person_id">
            <tr>
                <td rowspan="7">
                    <img style="width: auto;height: 160px; " *ngIf="group_person.value.avatar"
                        [src]="group_person.value.avatar" />
                </td>
            </tr>
            <tr>
                <td>体检编号:</td>
                <td>{{group_person.value.test_num}}</td>
                <td>姓名:</td>
                <td>{{group_person.value.anonymity==1?(group_person.value.person_name|nameHide):group_person.value.person_name}}
                </td>
                <td>性别:</td>
                <td>{{group_person.value.sex|sexStates}} </td>
                <td>年龄:</td>
                <td>{{group_person.value.age}}</td>

            </tr>
            <tr>
                <td>身份证号:</td>
                <td>{{group_person.value.anonymity==1?(group_person.value.id_card|idcardHide):group_person.value.id_card}}
                </td>
                <td>体检类型:</td>
                <td>{{group_person.value.physical_type}}</td>
                <td>单位名称:</td>
                <td>{{group_person.value.dept}}</td>
                <td>分组名称:</td>
                <td>{{order_group.name}}</td>
            </tr>
            <tr>
                <td *ngIf="session_physical_type=='职业体检'">工种名称:</td>
                <td *ngIf="session_physical_type=='职业体检'">{{group_person.value.work_type_text}}</td>
                <td *ngIf="session_physical_type=='职业体检'">检查种类:</td>
                <td *ngIf="session_physical_type=='职业体检'">{{group_person.value.work_state_text}}</td>
                <td>登记时间:</td>
                <td>{{group_person.value.regist_date|date:'yyyy-MM-dd HH:mm:ss'}}</td>
                <td>体检日期:</td>
                <td>{{group_person.value.check_date|slice:0:10}}</td>
            </tr>
            <tr *ngIf="session_physical_type=='职业体检'">
                <td>危害因素:</td>
                <td colspan="7">{{group_person.value.hazard_factors_text}}</td>
            </tr>
            <tr *ngIf="session_physical_type=='职业体检'">
                <td>职业病:</td>
                <td colspan="7">{{occupational_diseases}}</td>
            </tr>
            <tr *ngIf="session_physical_type=='职业体检'">
                <td colspan="1">职业禁忌证:</td>
                <td colspan="7">{{occupational_taboo}}</td>
            </tr>
        </table>
        <div *ngIf="loading_count!=4||loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <hr>
    </div>
    <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1%">
        <button mat-raised-button color="primary" (click)="generate_total_check_sheet()" [disabled]="loading_count!=4">
            <mat-icon>print</mat-icon>{{group_person.value.print_flag==1?'补打':'打印'}}职业健康体检报告</button>
        <button mat-stroked-button color="primary" (click)="refresh()" matTooltip="刷新当前体检结果"
            [disabled]="loading_count!=4"><mat-icon>refresh</mat-icon>刷新</button>
        <button mat-stroked-button color="primary"
            (click)="return_list()"><mat-icon>arrow_back</mat-icon>返回体检人员列表</button>
    </div>
    <br>
    <div [formGroup]="group_item_form">
        <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="space-between">
            <div style="text-align: left;font-size: 18px;color: blue;font-weight: bold;">
                分检项目结果
            </div>
            <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" style="width: 70%;"
                fxLayoutAlign="end center">
                <button mat-stroked-button color="accent" (click)="open_ask_check_detail()"
                    [disabled]="loading_count!=4">查看问诊详情</button>
                <button mat-stroked-button (click)="open_images()" *ngIf="protfolio_project?.rpt_type"
                    [disabled]="loading_count!=4">获取影像图文报告</button>

            </div>

        </div>

        <br>

        <div style="border-top-style: inset;">
            <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                <mat-form-field appearance="fill" style="width:24%;">
                    <mat-label>检查医生</mat-label>
                    <input matInput required formControlName="check_doc" readonly />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width:24%;">
                    <mat-label>检查日期 </mat-label>
                    <input matInput [ngxMatDatetimePicker]="check_date" formControlName="check_date" readonly>
                    <ngx-mat-datetime-picker #check_date showSeconds=0 color='primary'>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field style="width:50%;" appearance="fill">
                    <mat-label>分检项目选择</mat-label>
                    <mat-select formControlName="single_item" (valueChange)="item_search_all_data($event,4)">
                        <mat-option [value]="t"
                            *ngFor="let t of order_group.items;let i =index ">{{t.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%"
                *ngIf="group_item_form.value.abandon">
                <h3 style="color: red;">当前项目已弃检</h3>
                <mat-form-field style="width: 100%;" appearance="fill">
                    <mat-label>弃检原因</mat-label>
                    <textarea matInput style="width: 100%;" cdkTextareaAutosize formControlName="abandon_reason"
                        readonly></textarea>
                </mat-form-field>
            </div>

            <div formArrayName="base_projects" class="content" *ngIf="!group_item_form.value.abandon">
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="operate">
                        <th mat-header-cell *matHeaderCellDef>是否跳过该项检查</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">{{sa.value.skip_check_flag?'是':'否'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="index">
                        <th mat-header-cell *matHeaderCellDef>#</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">{{i}}</td>
                    </ng-container>
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef>职业网报编码</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">{{sa.value.code}}</td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>体检项目</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">{{sa.value.name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef>明细结果</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">
                            <mat-form-field style="width: 90%;" floatLabel="never" *ngIf="!com_disabled">
                                <input matInput type="text" formControlName="detail">
                            </mat-form-field>
                            <mat-form-field style="width: 90%;" floatLabel="never" *ngIf="com_disabled">
                                <input matInput type="text" formControlName="detail" [readonly]="true">
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="unit_name">
                        <th mat-header-cell *matHeaderCellDef>单位</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">{{sa.value.unit_name}}</td>
                    </ng-container>
                    <ng-container matColumnDef="unusual">
                        <th mat-header-cell *matHeaderCellDef>异常</th>
                        <td mat-cell *matCellDef="let sa;let i =index" [formGroup]="sa">
                            <mat-checkbox formControlName="unusual" *ngIf="!com_disabled"></mat-checkbox>
                            <mat-checkbox [checked]="sa.value.unusual" *ngIf="com_disabled"
                                [disabled]="com_disabled"></mat-checkbox>&nbsp;
                            <mat-form-field style="width:50%"
                                *ngIf="sa.value.unusual&&(xp_item_codes.includes(sa.value.code))">
                                <mat-label>结果判定</mat-label>
                                <mat-select formControlName="rst_flag">
                                    <mat-option value="1">尘肺样改变</mat-option>
                                    <mat-option value="2">其他异常</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                    <tr mat-row *matRowDef="let row;let i=index; columns: displayedColumns"
                        [ngClass]="{ 'warn': row.value.unusual }"></tr>
                </table>
            </div>
        </div>
        <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center"
            *ngIf="!group_item_form.value.abandon">
            <!-- <mat-form-field appearance="fill" style="width: 49%;">
            <mat-label>体检提醒或影像所见</mat-label>
            <textarea matInput formControlName="diagnose_tip" rows="5" readonly>
        </textarea>
        </mat-form-field> -->
            <mat-form-field appearance="fill" style="width: 99%;">
                <mat-label>体检小结</mat-label>
                <textarea matInput formControlName="diagnose_sum" rows="5" matTextareaAutosize readonly>
        </textarea>
            </mat-form-field>
        </div>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
    </div>
    <div>
        <div style="text-align: left;font-size: 18px;color: blue;font-weight: bold">
            总检结果
        </div>
        <br>
        <div [formGroup]="total_check_form" style="border-top-style: inset;">
            <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                <mat-form-field appearance="fill" style="width: 49%;">
                    <mat-label>总检医生</mat-label>
                    <input matInput required formControlName="check_doc" readonly />
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 49%;">
                    <mat-label>总检日期 </mat-label>
                    <input matInput [ngxMatDatetimePicker]="check_date1" formControlName="check_date" readonly>
                    <ngx-mat-datetime-picker #check_date1 showSeconds=0 color='primary'>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field style="width:99%">
                    <mat-label>主检结论</mat-label>
                    <mat-select formControlName="total_exam_conclusion_code">
                        <mat-option *ngFor="let exam of exam_conclusion_list"
                            [value]="exam.value">{{exam.title}}</mat-option>
                    </mat-select>
                    <mat-error>主检结论不可为空*</mat-error>
                </mat-form-field>
                <mat-card style="width: 100%;">
                    <mat-card-header>
                        <mat-card-title>体检危害因素</mat-card-title>
                    </mat-card-header>
                    <mat-card-content formArrayName="td_tj_badrsns">
                        <mat-card *ngFor="let element of td_tj_badrsns.controls;let i = index" [formGroup]="element"
                            style="border: 1px black solid;">
                            <mat-card-header>
                                <mat-card-title>{{element.get('other_label').value}}</mat-card-title>
                                <mat-card-subtitle>{{element.get('other_badrsn').value}}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <mat-form-field style="width:99%">
                                    <mat-label>体检结论</mat-label>
                                    <mat-select formControlName="exam_conclusion_code">
                                        <mat-option *ngFor="let exam of exam_conclusion_list"
                                            [value]="exam.value">{{exam.title}}</mat-option>
                                    </mat-select>
                                    <mat-error>体检结论不可为空*</mat-error>
                                </mat-form-field>
                                <mat-form-field style="width:99%" *ngIf="element.get('yszyb_view').value">
                                    <mat-label>疑似职业病代码</mat-label>
                                    <mat-select formControlName="yszyb_code">
                                        <mat-option *ngFor="let yszyb of yszyb_list"
                                            [value]="yszyb.code">{{yszyb.value}}</mat-option>
                                    </mat-select>
                                    <mat-error>当结论为疑似职业病时，该项必填</mat-error>
                                </mat-form-field>
                                <mat-form-field style="width:99%"
                                    *ngIf="element.get('yszyb_view').value && group_person.value.work_state_code=='1001'">
                                    <mat-label>接触相应职业病危害因素的用人单位名称</mat-label>
                                    <input matInput type="text" formControlName="enterprise_name">
                                </mat-form-field>
                                <mat-form-field style="width:99%" *ngIf="element.get('zyjjz_view').value">
                                    <mat-label>职业禁忌证代码</mat-label>
                                    <mat-select formControlName="zyjjz_code">
                                        <mat-option *ngFor="let zyjjz of zyjjz_list"
                                            [value]="zyjjz.code">{{zyjjz.value}}</mat-option>
                                    </mat-select>
                                    <mat-error>当结论为职业禁忌证时，该项必填</mat-error>
                                </mat-form-field>
                                <mat-form-field style="width:99%" *ngIf="element.get('otjb_view').value">
                                    <mat-label>其他疾病或异常描述</mat-label>
                                    <textarea type="text" matInput formControlName="otjb_name" rows="5"
                                        cdkTextareaAutosize readonly></textarea>
                                    <mat-error>当结论为其他疾病或异常时，该项必填</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>
                    </mat-card-content>
                </mat-card>
                <br>
            </div>

            <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center"
                style="width: 100%;" *ngIf="session_physical_type=='职业体检'">
                <mat-form-field appearance="fill" style="width: 49%;">
                    <mat-label>职业健康体检结果</mat-label>
                    <textarea matInput formControlName="conclusion" rows="5" readonly>
                </textarea>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 49%;">
                    <mat-label>职业健康体检建议</mat-label>
                    <textarea matInput formControlName="handle_opinion" rows="5" readonly>
                </textarea>
                </mat-form-field>
            </div>
            <br>
            <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center"  style="background-color: white;width: 100%;">
                    <div formArrayName=other_abnormal_results style="width: 100%;">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
                            <h3>其他疾病或异常及建议：</h3>
                        </div>
                        <div  style="width: 100%;" *ngFor="let result of other_abnormal_results.controls ;let i = index" cdkDrag
                            [formGroup]="result" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%">
                            <mat-form-field appearance="fill" style="width: 49%;">
                                <mat-label>其他疾病或异常描述</mat-label>
                                <textarea type="text" matInput formControlName="other_abnormal" rows="5"
                                    cdkTextareaAutosize readonly></textarea>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="width: 49%;">
                                <mat-label>异常结果建议</mat-label>
                                <textarea type="text" matInput formControlName="suggest" rows="5"
                                    cdkTextareaAutosize readonly></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" (click)="generate_total_check_sheet()"
                [disabled]="loading_count!=4">
                <mat-icon>print</mat-icon>{{group_person.value.print_flag==1?'补打':'打印'}}职业健康体检报告</button>
        </div>
    </div>
    <br>
    <br>
    <br>
</div>