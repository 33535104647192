<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h2 style="color:red;">{{type_name}}网报上传</h2>
</div>
<div  fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center"  fxLayoutGap="1%">
    <button mat-raised-button color="primary"  *ngIf="this.error_list.length>0" (click)="export_xml()" >导出错误日志</button>
    <button mat-raised-button color="warn" mat-dialog-close="false">退出</button>
</div>
<br>
<table  [formGroup]="upload_group" >
    <tr>
        <th style="width: 20%;">主键信息</th>
        <th style="width: 10%;">上传状态</th>
        <th style="width: 70%;">错误信息</th>        
    </tr>
    <tr *ngFor="let detail of upload_detail_list.controls" formArrayName="upload_detail_list">
        <td>{{detail.get('ids').value}}</td>
        <td [ngClass]="{red:detail.get('upload_status').value==4||detail.get('upload_status').value==0,orange:detail.get('upload_status').value==2,green:detail.get('upload_status').value==3}">{{detail.get('upload_status').value|uploadStatus}}</td>
        <td style="color: red;">{{detail.get('error_text').value}}</td>
    </tr>
</table>
<br>