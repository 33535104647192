import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { handleError } from '../data-services/comm-func';

@Injectable({
  providedIn: 'root'
})
export class DialogModelsService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  validation_user(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/validation_user/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  save_sign_photo(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/save_sign_photo/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  sch_combo_list_by_career_stage(type,career_stage, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number): Observable<any> {
    let params = new HttpParams()
      .set('type', type)
      .set('career_stage', career_stage)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page));

    return this.http.get<any>(`${environment.apiUrl}/common/sch_combo_list_by_career_stage/`, { params })
      .pipe(
        map(res => {
          console.log(res)
          return res
        }),
        catchError(handleError)
      )
  }

  update_person(input_data):Observable<any>{
    return this.http.put<any>(`${environment.apiUrl}/common/update_person/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
}
