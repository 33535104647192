import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PortfolioProjectDatasource } from 'src/app/home/sysset/portfolio-project/portfolio-project-list/portfolio-project-list.datasource';
import { PortfolioProjectService } from 'src/app/home/sysset/portfolio-project/portfolio-project.service';
import { PortfolioProject } from 'src/app/models/sysset/portfolio_project';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-portfolio-project-import',
  templateUrl: './portfolio-project-import.component.html',
  styleUrls: ['./portfolio-project-import.component.css'],
  providers: [DatePipe]

})
export class PortfolioProjectImportComponent implements OnInit {
  title = ''
  subtitle = ''
  tbutton_status: boolean = false;

  tportfolioProject: any = {}
  dataSource: PortfolioProjectDatasource;
  displayedColumns = ['select', 'name', 'short_name', 'sale_price', 'cost_price', 'office_name', 'address'];

  selection = new SelectionModel<any>(true, []);
  selectedRowIndexAuto: number = 0;
  dept_list = []
  his_dept_list = []
  section_id = ''

  @ViewChild(MatPaginator) paginator: MatPaginator;
  input_text=''
  input_cate: string = 'name';
  session_physical_type = sessionStorage.getItem('physical_type')
  constructor(
    private datepipe: DatePipe,
    private router: Router,
    private portfolioProjectService: PortfolioProjectService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    public dialogRef: MatDialogRef<PortfolioProjectImportComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
  
  ) { 
    this.get_dept_list()
    this.get_his_dept_list()
    this.title = this.dialog_data.title
    this.subtitle = this.dialog_data.subtitle
  }

  ngOnInit(): void {
    this.dataSource = new PortfolioProjectDatasource(this.portfolioProjectService);
    this.dataSource.loadtables('', '', '', 0, 15,this.session_physical_type);

  }
  ngAfterViewInit() {
    if (this.tportfolioProject == undefined) {
      this.tportfolioProject = new PortfolioProject();
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();
  }


  loadTablePage() {
    this.selection.clear()
    if (this.tportfolioProject == undefined) {
      this.tportfolioProject = new PortfolioProject();
    }
    this.dataSource.loadtables(
      this.section_id,
      this.input_cate,
      this.input_text,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.session_physical_type);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      // this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    // this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: PortfolioProject): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    // this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  search() {
    this.paginator.pageIndex = 0
    this.loadTablePage()
  }

  get_dept_list() {
    this.commonService.get_dept_List().subscribe(res => {
      if (res.flag) {
        this.dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息', '错误')
      }
    })
  }
  import_selected() {
    let selected = this.selection.selected
    this.dialogRef.close(selected)
  }
  get_his_dept_list() {
    this.commonService.get_his_dept_List().subscribe(res => {
      if (res.flag) {
        this.his_dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息' + res.value, '错误')
      }
    })
  }

}
