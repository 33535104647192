<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
    <mat-icon color="warn">warning</mat-icon>消息提示
</div>
<br>
<div  fxLayout="row wrap" fxLayout.xs="column"
    fxLayoutAlign="center center" fxLayoutGap="20px" class="div_layout">
    <h2>{{data.content}}</h2>
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
    <button mat-raised-button color="warn" [mat-dialog-close]="true">确定</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="false">取消</button>
</div>