import { SelectionChange } from '@angular/cdk/collections';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
@Component({
  selector: 'app-hazard-add-multiple',
  templateUrl: './hazard-add-multiple.component.html',
  styleUrls: ['./hazard-add-multiple.component.css']
})
export class HazardAddMultipleComponent implements OnInit {
  type_list = []
  form: FormGroup
  filteredOptions = [];
  filteredOptions_auto: Observable<any[]>
  filteredOptions_single = []
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private commonService: CommonService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<HazardAddMultipleComponent>,


  ) {
    this.get_type_list()
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      type: '',
      type_name: [],
      type_name_single: { value: '', disabled: true },
      type_name_single_one: [],
      hazards_temp: ''
    })
    this.filteredOptions_auto = this.form.get('hazards_temp').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const name = typeof value == 'string' ? value : ''
          
          return this.filteredOptions.filter(f => f.type_name.includes(name))
        })
      )
  }

  //导入
  import_selected() {
    
    
    let result = {
      'type_name': this.form.get('type_name').value,
      'type_name_single': this.form.get('type_name_single_one').value?this.form.get('type_name_single_one').value[0]:null
    }
    this.dialogRef.close(result)

  }
  //获取危害类型列表
  get_type_list() {

    this.commonService.get_t_pro_type_by_tree().subscribe(res => {

      if (res.flag) {
        this.type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

  //选中危害类型列表的监听
  type_select(event) {
    this.commonService.get_t_pro_type_by_tree_and_condition(event.value, '')
      .subscribe(data => {
        if (data.flag) {
          if (event.value == '11') {
            this.form.get('type_name_single').enable()
            this.filteredOptions = data.value.filter(f => f.remark != '1')
            this.filteredOptions_single = data.value.filter(f => f.remark == '1')
          } else {
            this.form.get('type_name_single').disable()
            this.filteredOptions = data.value
            this.filteredOptions_single = []
            this.form.get('type_name_single').reset()

          }
          this.form.get('hazards_temp').setValue('')
        } else {
          this.notifyService.showError(data.value, '失败')
        }
      })
  }

  //移除危害因素chip操作
  remove_hazard_by_type_name(i) {
    let hazrds = this.form.get('type_name').value as Array<Object>
    
    hazrds.splice(i, 1)
    this.form.get('hazards_temp').setValue('')
    this.form.get('type_name').updateValueAndValidity()
  }
  //移除主要粉尘chip操作
  remove_hazard_one_by_type_name(i) {
    let hazrds = this.form.get('type_name_single_one').value as Array<Object>
    hazrds.splice(i, 1)
  }
  //危害因素增加（autocomponent）optionSelected监听调用
  add_hazards(event) {
    
    let value = event.option.value
    const input = event.isUserInput
    if (!this.form.get('type_name').value) {
      this.form.get('type_name').setValue([])
    }
    
    if (!this.form.get('type_name').value.find(f => f.id == value.id)) {
      let hazrds = this.form.get('type_name').value as Array<Object>
      hazrds.push({ ...value })
    }
    this.form.get('hazards_temp').setValue('')
    this.form.get('type_name').updateValueAndValidity()
  }
  single_select(event) {
    
    let value = event.value
    this.form.get('type_name_single_one').setValue([{ ...value }])
  }
}
