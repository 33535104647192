<mat-toolbar color="primary">
    <span class="fill-remaining-space">查询与统计</span>
</mat-toolbar>
<mat-card class="reg-card">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image"></div>
        <mat-card-title><b>查询与统计</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <span *ngIf="this.session_physical_type=='职业体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('recheck')"
                    *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>复检信息统计</button>
            </span>
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('proPositiveResultPL')"
                    *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>体检阳性结果汇总</button>
            </span>
            <span *ngIf="this.session_physical_type=='健康体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('singleDiagSum')"
                    *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>健康体检诊断查询汇总</button>
            </span>
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('singleCheckSum')"
                    *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>体检分诊小结查询汇总</button>
            </span>
            <span  *ngIf="this.session_physical_type=='健康体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('healthCheckResultSum')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>健康体检结果汇总</button>
            </span>
            <span *ngIf="this.session_physical_type=='职业体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('proResultSuggest')"
                    *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>职业体检结果及建议汇总</button>
            </span>
            <span *ngIf="session_physical_type=='职业体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('proHazardSum')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>职业体检接触有害因素统计表</button>
            </span>
        </div>
    </mat-card-actions>
</mat-card>
<mat-card class="reg-card">
    <mat-card-header>
        <mat-card-title><b></b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('peisFee')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>体检人员费用明细汇总</button>
            </span>
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('comboSum')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>体检人员套餐汇总</button>
            </span>
          
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('checkingItemSum')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>（已检）未检项目汇总</button>
            </span>
          
            <span>
                <button mat-raised-button color="primary" (click)="onMenuAction('checkPersonList')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>人员体检清单</button>
            </span>
        </div>
    </mat-card-actions>
</mat-card>
<br />