<div [formGroup]="input_form">
    <mat-form-field>
        <mat-label>缩小倍数</mat-label>
        <input matInput type="number" formControlName="times">
    </mat-form-field>
    <mat-form-field>
        <mat-label>条码中心x轴坐标</mat-label>
        <input matInput type="number" formControlName="x">
    </mat-form-field>
    <mat-form-field>
        <mat-label>条码中心y轴坐标</mat-label>
        <input matInput type="number" formControlName="y">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="submit()"> 开始打印
    </button>
    <button mat-raised-button color="wran" [matDialogClose]="false"> 取消
    </button>
</div>