<h2>20231101</h2>
<ul>
  <li>套餐报错后，不退出，再次保存时，报错的问题；</li>
  <li>个人信息添加时,危害因素按字母排序</li>
</ul>
<hr>
<h2>20231031</h2>
<ul>
  <li>基础项目和组合项目维护界面，项目代码改为职业网报项目代码</li>
  <li>团检人员信息维护中，危害因素下拉列表排序方式按名称排序</li>
  <li>团检人员信息维护中，工种代码直接输入</li>
</ul>
<hr>