import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PeRegisterService } from 'src/app/home/content/pe-register/pe-register.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { DialogModelsService } from '../dialog-models.service';

@Component({
  selector: 'app-upload-sign',
  templateUrl: './upload-sign.component.html',
  styleUrls: ['./upload-sign.component.css']
})
export class UploadSignComponent implements OnInit {
  constructor(
    private dialogModelsService:DialogModelsService,
    private notifyService: NotificationService,
    public dialogRef: MatDialogRef<UploadSignComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,

  ) { }

  ngOnInit(): void {
  }
  //------------
  avatarUrl
  genzjl: any = {};
  selectable = true;

  handleFiles(event) {
    const reader = new FileReader();
    reader.onload = (e) => {
      // @ts-ignore
      this.avatarUrl = e.target.result;
      this.selectable = false;
      this.genzjl.zhaop = this.avatarUrl;
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  save() {
    let input_data = {
      "sign_photo": this.avatarUrl,
    }
    this.dialogModelsService.save_sign_photo(input_data).subscribe(res => {
      if (res.flag) {
        this.dialogRef.close(this.avatarUrl)
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

}
