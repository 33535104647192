import { Component, EventEmitter ,Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
// import { EventEmitter } from 'stream';

@Component({
  selector: 'app-his-dept-list',
  templateUrl: './his-dept-list.component.html',
  styleUrls: ['./his-dept-list.component.css']
})
export class HisDeptListComponent implements OnInit {
  his_dept_list = []
  constructor(
    private commonService: CommonService,
    private notifyService: NotificationService,
  ) {
    this.get_his_dept_list()

  }
  @Input() parent: any
  @Input() title: any
  @Input() office_id: any
  @Output() office_id_send = new EventEmitter<string>();

  ngOnInit(): void {
  }

  search() {
    console.log(this.office_id)
    this.office_id_send.emit(this.office_id)
    this.parent.search()
  }
  get_his_dept_list() {
    this.commonService.get_his_dept_List().subscribe(res => {
      if (res.flag) {
        this.his_dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息' + res.value, '错误')
      }
    })
  }
}
