<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--问诊详情--</h1>
</div>
<hr>
<mat-progress-bar *ngIf="data_loading" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1%" style="color: blue;">
    <h3>人员姓名:{{ask_check_group.value.anonymity==1?(ask_check_group.value.person_name|nameHide):ask_check_group.value.person_name}}</h3>
   <h3>检查医生:{{ask_check_group.value.ask_check_doc}}</h3>
   <h3>检查日期:{{ask_check_group.value.ask_check_date|date:"yyyy-MM-dd HH:mm"}}</h3>
</div>
<form [formGroup]="ask_check_group">
    <div style="width:100%">
        <br>
        <mat-card>
            <mat-card-header>
                <mat-card-title>基本信息</mat-card-title>
            </mat-card-header>
            <mat-card-content formGroupName="basic_info">
                <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                    <mat-form-field *ngIf="session_physical_type=='职业体检'">
                        <mat-label>总工龄年数</mat-label>
                        <input matInput type="number" formControlName="workExperienceYears" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="session_physical_type=='职业体检'">
                        <mat-label>总工龄月数</mat-label>
                        <input matInput type="number" formControlName="workExperienceMonths" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="session_physical_type=='职业体检'">
                        <mat-label>接害工龄年数</mat-label>
                        <input matInput type="number" formControlName="exposureYears" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="session_physical_type=='职业体检'">
                        <mat-label>接害工龄月数</mat-label>
                        <input matInput type="number" formControlName="exposureMonths" readonly>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>个人生活史</mat-card-title>
            </mat-card-header>
            <mat-card-content formGroupName="personalLifeHistory" class="unclickable">
                <h2>月经史：</h2>
                <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center"
                    formGroupName="menstrualHistory">
                    <mat-form-field style="width: 19%;">
                        <mat-label>是否经期</mat-label>
                        <mat-select formControlName="isxmns">
                            <mat-option></mat-option>
                            <mat-option value='1'>是</mat-option>
                            <mat-option value="0">否</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 19%;">
                        <mat-label>初潮年龄</mat-label>
                        <input matInput type="text" formControlName="menarcheAge">
                    </mat-form-field>
                    <mat-form-field style="width: 19%;">
                        <mat-label>经期</mat-label>
                        <input matInput type="text" formControlName="menstrualPeriod">
                    </mat-form-field>
                    <mat-form-field style="width: 19%;">
                        <mat-label>周期</mat-label>
                        <input matInput formControlName="cyc">
                    </mat-form-field>
                    <mat-form-field style="width: 19%;">
                        <mat-label>停经年龄</mat-label>
                        <input matInput type="number" formControlName="menopauseAge">
                    </mat-form-field>
                </div>
                <h2>生育史：</h2>
                <div formGroupName="childbearingHistory">
                    <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                        <h3>现有子</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfSons">
                        </mat-form-field>
                        <h3>女</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfDaughters">
                            <span matSuffix>人</span>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                        <h3>流产</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfAbortions">
                            <span matSuffix>次</span>
                        </mat-form-field>
                        <h3>早产</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfPrematureBirths">
                            <span matSuffix>次</span>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                        <h3>死产</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfStillbirths">
                            <span matSuffix>次</span>
                        </mat-form-field>
                        <h3>畸胎</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="numberOfAbnormalPregnancies">
                            <span matSuffix>次</span>
                        </mat-form-field>
                    </div>
                </div>
                <h2>烟酒史：</h2>
                <div formGroupName="tobaccoAndWineHistory">
                    <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                        <mat-form-field appearance="fill" style="width: 19%;">
                            <mat-label>吸烟史-吸烟情况</mat-label>
                            <mat-select formControlName="smksta" >
                                <mat-option value='1'>现在每天吸</mat-option>
                                <mat-option value="2">现在吸，但不是每天吸</mat-option>
                                <mat-option value="3">过去吸，现在不吸</mat-option>
                                <mat-option value="4">从不吸</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field style="width:10%">
                            <mat-label>平均每天吸烟量</mat-label>
                            <input matInput type="number" formControlName="smkdayble">
                            <span matSuffix>支</span>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkdayble').hasError('required')">该项必填*</mat-error>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkdayble').hasError('smk_validator')">若吸烟情况为“从不吸烟”则必须为0，否则大于0</mat-error>
                        </mat-form-field>
                        <mat-form-field style="width:10%">
                            <mat-label>吸烟史-年</mat-label>
                            <input matInput type="number" formControlName="smkyerqty" >
                            <span matSuffix>年</span>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkyerqty').hasError('required')">该项必填*</mat-error>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkyerqty').hasError('smk_validator')">若吸烟情况为“从不吸烟”则必须为0，否则总月数大于0</mat-error>
                        </mat-form-field>
                        <mat-form-field style="width:10%">
                            <mat-label>吸烟史-月</mat-label>
                            <input matInput type="number" formControlName="smkmthqty" >
                            <span matSuffix>月</span>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkmthqty').hasError('required')">该项必填*</mat-error>
                            <mat-error
                                *ngIf="tobaccoAndWineHistory.get('smkmthqty').hasError('smk_validator')">小于等于 11 的整数若吸烟情况为“从不吸烟”则必须为 0，否则总月数大于 0</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row warp" fxLayout.xs="column" fxLayoutGap="1%" fxLayoutAlign="start center">
                        <mat-radio-group formControlName="drinkingStatus">
                            <mat-radio-button value="0">不饮酒</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="1">偶饮酒</mat-radio-button>&nbsp;&nbsp;
                            <mat-radio-button value="2">经常饮</mat-radio-button>&nbsp;&nbsp;
                        </mat-radio-group>
                        <mat-form-field style="width: 10%;">
                            <mat-label>饮酒种类</mat-label>
                            <mat-select formControlName="drinkingType">
                                <mat-option value=""></mat-option>
                                <mat-option value="白酒">白酒</mat-option>
                                <mat-option value="啤酒">啤酒</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="alcoholConsumptionPerDay">
                        </mat-form-field>
                        <h3>ml/日、共</h3>
                        <mat-form-field style="width:10%">
                            <mat-label></mat-label>
                            <input matInput type="number" formControlName="drinkingAge">
                        </mat-form-field>
                        <h3>年;</h3>
                    </div>
                </div>
                <h2>家族史:</h2>
                <mat-form-field style="width: 100%;">
                    <mat-label></mat-label>
                    <textarea matInput matTextareaAutosize formControlName="familyHistory"></textarea>
                </mat-form-field>


            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="session_physical_type=='职业体检'">
            <mat-card-header>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%" style="width: 100%;">
                    <mat-card-title>职业史</mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="content">
                    <table mat-table [dataSource]="datasource_zy">

                        <ng-container matColumnDef="start_end_date">
                            <th mat-header-cell *matHeaderCellDef>起止日期</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput formControlName='start_end_date' readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="work_dept">
                            <th mat-header-cell *matHeaderCellDef>工作单位名称</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="work_dept" readonly>
                                    <mat-error>工作单位不可为空</mat-error>
                                </mat-form-field>

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="work_shop">
                            <th mat-header-cell *matHeaderCellDef>部门车间</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="work_shop" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="work_type">
                            <th mat-header-cell *matHeaderCellDef>工种</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="work_type" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="hazard_factors">
                            <th mat-header-cell *matHeaderCellDef>接触有害因素</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="hazard_factors" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="safe_measure">
                            <th mat-header-cell *matHeaderCellDef>(非放射)防护措施</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="safe_measure" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="freq">
                            <th mat-header-cell *matHeaderCellDef>接触频次</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="freq" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns_zy"></tr>
                        <tr mat-row *matRowDef="let row;columns:displayedColumns_zy"></tr>
                    </table>
                </div>

            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%" style="width: 100%;">
                    <mat-card-title>既往病史</mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="content">
                    <table mat-table [dataSource]=datasource_jwbs style="width: 100%;">
                        <ng-container matColumnDef="operate">
                            <th mat-header-cell *matHeaderCellDef>操作</th>
                            <td mat-cell *matCellDef="let element;let i =index;">
                                <button mat-button (click)="delete_jwbs(i,element)" readonly><mat-icon
                                        color="warn">delete</mat-icon></button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="disease_name">
                            <th mat-header-cell *matHeaderCellDef>患病名称</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="disease_name" readonly>
                                    <mat-error>患病名称不可为空</mat-error>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="diagnose_date">
                            <th mat-header-cell *matHeaderCellDef>诊断日期</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput [matDatepicker]="diagnose_date" placeholder="请选择诊断日期"
                                        formControlName="diagnose_date" readonly>
                                    <mat-datepicker #diagnose_date startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="diagnose_dept">
                            <th mat-header-cell *matHeaderCellDef>诊断单位</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="diagnose_dept" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="process">
                            <th mat-header-cell *matHeaderCellDef>治疗经过</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="process" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="end">
                            <th mat-header-cell *matHeaderCellDef>转归</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="end" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns_jwbs"></tr>
                        <tr mat-row *matRowDef="let row;columns:displayedColumns_jwbs"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>其他</mat-card-title>
            </mat-card-header>
            <mat-card-content class="unclickable">
                <mat-form-field style="width: 100%;">
                    <mat-label></mat-label>
                    <textarea matInput matTextareaAutosize [readonly]="com_disabled"
                        formControlName="other_content"></textarea>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%" style="width: 100%;">
                    <mat-card-title>症状</mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%" class="content">
                    <div style="width: 99%;">
                        <br>
                        <mat-chip-list >
                            <mat-chip *ngFor="let positive of get_positive()" color="warn"
                                selected>{{positive.projectName}}</mat-chip>
                        </mat-chip-list>
                        <br>
                        <hr>
                    </div>
                    <div style="width: 49%;">
                        <div style="width: 99%;" class="unclickable">
                            <h2>套餐内绑定症状：</h2>
                            <table mat-table [dataSource]="datasource_sym_data1" style="width: 100%;">
                                <ng-container matColumnDef="index">
                                    <th mat-header-cell *matHeaderCellDef>序号</th>
                                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                                        {{i+1}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="projectName">
                                    <th mat-header-cell *matHeaderCellDef>项目名称</th>
                                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                                        {{element.value.projectName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="degree">
                                    <th mat-header-cell *matHeaderCellDef>症状情况</th>
                                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                                        <mat-form-field floatLabel="never" style="width: 90%;">
                                            <mat-select formControlName="degree">
                                                <mat-option value="-">无症状(-)</mat-option>
                                                <mat-option value="+">有症状(+)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayenColumns_symptom;sticky:true"></tr>
                                <tr mat-row *matRowDef="let row;columns:displayenColumns_symptom"
                                    [ngClass]="{ 'warn': row.value.degree=='+' }"></tr>
                            </table>
                        </div>
                        <div style="width: 99%;" class="unclickable">
                            <h2>套餐外症状：</h2>
                            <table mat-table [dataSource]="datasource_sym_data2" style="width: 100%;">
                                <ng-container matColumnDef="index">
                                    <th mat-header-cell *matHeaderCellDef>序号</th>
                                    <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                                        {{i+1}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="projectName">
                                    <th mat-header-cell *matHeaderCellDef>项目名称</th>
                                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                                        {{element.value.projectName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="degree">
                                    <th mat-header-cell *matHeaderCellDef>症状情况</th>
                                    <td mat-cell *matCellDef="let element" [formGroup]="element">
                                        <mat-form-field floatLabel="never" style="width: 90%;">
                                            <mat-select formControlName="degree">
                                                <mat-option value="-">无症状(-)</mat-option>
                                                <mat-option value="+">有症状(+)</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayenColumns_symptom_other;sticky:true" sticky>
                                </tr>
                                <tr mat-row *matRowDef="let row;columns:displayenColumns_symptom_other"
                                    [ngClass]="{ 'warn': row.value.degree=='+' }"></tr>
                            </table>
                        </div>
                    </div>

                    <div style="width: 49%;" class="unclickable">
                        <h2>其他症状：</h2>
                        <table mat-table [dataSource]="datasource_sym_data3" style="width: 100%;">
                            <ng-container matColumnDef="operate">
                                <th mat-header-cell *matHeaderCellDef>操作</th>
                                <td mat-cell *matCellDef="let element;let i = index" [formGroup]="element">
                                    <button mat-button (click)="delete_other_symptom(i,element)"><mat-icon
                                            color="warn">delete</mat-icon></button>

                                </td>
                            </ng-container>
                            <ng-container matColumnDef="projectName">
                                <th mat-header-cell *matHeaderCellDef>项目名称</th>
                                <td mat-cell *matCellDef="let element" [formGroup]="element">
                                    <mat-form-field floatLabel="never" style="width: 90%;">
                                        <input matInput type="text" formControlName="projectName" readonly>
                                        <mat-error>存在其他症状时，必填*</mat-error>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="degree">
                                <th mat-header-cell *matHeaderCellDef>症状情况</th>
                                <td mat-cell *matCellDef="let element" [formGroup]="element">
                                    <mat-form-field floatLabel="never" style="width: 90%;">
                                        <mat-select formControlName="degree">
                                            <mat-option value="-">无症状(-)</mat-option>
                                            <mat-option value="+">有症状(+)</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayenColumns_symptom_other;sticky:true" sticky>
                            </tr>
                            <tr mat-row *matRowDef="let row;columns:displayenColumns_symptom_other"
                                [ngClass]="{ 'warn': row.get('degree').value=='+' }"></tr>
                        </table>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
        <button mat-raised-button color="primary" [mat-dialog-close]="false">退出</button>
    </div>
</form>
<br>
<br>
<br>
<br>