import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-one-input',
  templateUrl: './dialog-one-input.component.html',
  styleUrls: ['./dialog-one-input.component.css']
})
export class DialogOneInputComponent implements OnInit {
  name: any
  constructor(
    public dialogRef: MatDialogRef<DialogOneInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.name = data.name
  }

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close(this.name)
  }
}
