import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sysset',
  templateUrl: './sysset.component.html',
  styleUrls: ['./sysset.component.css']
})
export class SyssetComponent implements OnInit {
  session_physical_type = sessionStorage.getItem('physical_type')

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  async onMenuAction(tpage: string) {
    switch (tpage) {
      case "dept-set": {
        this.router.navigate(['/sysset/dept-set']);
        break;
      }
      case "diag-set": {
        this.router.navigate(['/sysset/diag-set']);
        break;
      }
      case "base-project": {
        this.router.navigate(['/sysset/base-project']);
        break;
      }
      case "portfolio-project": {
        this.router.navigate(['/sysset/portfolio-project']);
        break;
      }
      case "combo": {
        this.router.navigate(['sysset/combo'])
        break;
      }
      case "term-set": {
        this.router.navigate(['/sysset/term-set'])
        break;
      } case "base-agreement": {
        this.router.navigate(['/sysset/base-agreement'])
        break;
      }
      case "authority-management": {
        this.router.navigate(['sysset/authority-management'])
        break;
      }
      default: {
        break;
      }
    }
  }

}
