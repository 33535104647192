import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';

@Component({
  selector: 'app-hazard-add-multiple2',
  templateUrl: './hazard-add-multiple2.component.html',
  styleUrls: ['./hazard-add-multiple2.component.css']
})
export class HazardAddMultiple2Component implements OnInit {
  type_list = []
  form: FormGroup
  filteredOptions = []
  filteredOptions_auto: Observable<any[]>
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private commonService: CommonService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<HazardAddMultiple2Component>,
  ) {
    this.get_type_list()
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      type: '',
      type_name: [],
      hazards_temp: ''
    })
    this.filteredOptions_auto = this.form.get('hazards_temp').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const name = typeof value == 'string' ? value : ''
          
          return this.filteredOptions.filter(f => f.type_name.includes(name))
        })
      )
  }

  import_selected() {

    let result = {
      'type_name': this.form.get('type_name').value,
    }
    this.dialogRef.close(result)

  }
  get_type_list() {

    this.commonService.get_t_pro_type_by_tree().subscribe(res => {

      if (res.flag) {
        this.type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  displayFn(option: any): string {

    return option && option.type_name ? option.type_name : '';
  }

  type_select(event) {
    this.commonService.get_t_pro_type_by_tree_and_condition(event.value, '')
      .subscribe(data => {
        if (data.flag) {
          this.filteredOptions = data.value
          this.form.get('hazards_temp').setValue('')
        } else {
          this.notifyService.showError(data.value, '失败')
        }
      })
  }
  //危害因素增加（autocomponent）optionSelected监听调用
  add_hazards(event) {
    let value = event.option.value
    const input = event.isUserInput
    if (!this.form.get('type_name').value) {
      this.form.get('type_name').setValue([])
    }

    if (!this.form.get('type_name').value.find(f => f.id == value.id)) {
      let hazrds = this.form.get('type_name').value as Array<Object>
      hazrds.push({ ...value })
    }
    this.form.get('hazards_temp').setValue('')
    this.form.get('type_name').updateValueAndValidity()
  }
  //移除危害因素chip操作
  remove_hazard_by_type_name(i) {
    let hazrds = this.form.get('type_name').value as Array<Object>
    hazrds.splice(i, 1)
    this.form.get('hazards_temp').setValue('')
    this.form.get('type_name').updateValueAndValidity()
  }
}
