import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/data-services/auth.service';
import { UploadSignComponent } from 'src/app/dialog-models/upload-sign/upload-sign.component';
import { UserValidationComponent } from 'src/app/dialog-models/user-validation/user-validation.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  user: FormGroup
  sign_photo: ''
  version = require('../../../../package.json').version
  constructor(
    public authService: AuthService,
    private notifyService: NotificationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private commonService: CommonService,
  ) { }


  ngOnInit(): void {
    //初始化用户信息
    this.user = this.fb.group({
      userid: JSON.parse(localStorage.getItem('currentUser'))["opcode"],
      username: JSON.parse(localStorage.getItem('currentUser'))["opname"],
      dept: JSON.parse(localStorage.getItem('currentUser'))["dept_code"],
      passwd_old: '',
      passwd_new1: '',
      passwd_new2: '',
    })
    this.get_sign_photo()

  }
  public flag: boolean = false;
  //初始化用户信息
  public passwd_flag: boolean = true;
  public passwd_new_flag: boolean = true;

  public pwdShow: boolean = false;
  public button_flag: boolean = false;
  changePass() {
    this.flag = true;
  }
  cancel() {
    this.flag = false;
    this.user.get('passwd_old').setValue('')
    this.user.get('passwd_new1').setValue('')
    this.user.get('passwd_new2').setValue('')
    this.passwd_flag = true;
    this.passwd_new_flag = true;
    this.button_flag = false;
  }
  //设置用户信息
  saveInfo() {
    this.authService.saveChange(this.user.value.userid, this.user.value.passwd_new1)
      .subscribe(data => {
        if (data) {
          this.notifyService.showSuccess("成功", "修改信息成功")
          this.authService.logout()
        } else {
          this.notifyService.showError("失败", "修改信息失败")
        }
      });

  }
  //校验用户密码是否正确
  verify() {
    this.authService.Verify(this.user.value.userid, this.user.value.passwd_old)
      .subscribe(
        data => {
          this.passwd_flag = data
          if (this.passwd_flag && this.passwd_new_flag && this.user.value.passwd_old != '' && this.user.value.passwd_new1 != '' && this.user.value.passwd_new2 != '') {
            this.button_flag = true
          } else {
            this.button_flag = false
          }
        });
  }
  //比较两次输入的密码
  compare() {
    if (this.user.value.passwd_new1 != this.user.value.passwd_new2) {
      this.passwd_new_flag = false;
    } else {
      this.passwd_new_flag = true;
    }
    if (this.passwd_flag && this.passwd_new_flag && this.user.value.passwd_old != '' && this.user.value.passwd_new1 != '' && this.user.value.passwd_new2 != '') {
      this.button_flag = true
    } else {
      this.button_flag = false
    }
  }
  upload_signature() {

    const dialog = this.dialog.open(UserValidationComponent, {
      width: '400px',
    })
    dialog.afterClosed().subscribe(res => {
      if (res) {
        const dialog_1 = this.dialog.open(UploadSignComponent, {
          width: '400px',
        })
        dialog_1.afterClosed().subscribe(res => {
          if (res) {
            this.sign_photo = res
          }
        })
      }
    })
  }

  get_sign_photo() {
    this.commonService.get_sign_photo().subscribe(res => {
      if (res.flag) {
        this.sign_photo = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

}