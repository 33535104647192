<mat-toolbar color="primary">
    <!-- <span class="fill-remaining-space">
        <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
            (mouseover)="menuTrigger.openMenu()">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="fill-remaining-space">系统设置</span>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <span (mouseleave)="menuTrigger.closeMenu()">
                <button mat-menu-item  (click)="onMenuAction('agreement-type-set')"><mat-icon>bookmark</mat-icon>基础项目维护</button>
                <button mat-menu-item   (click)="onMenuAction('agreement-detail-set')"><mat-icon>book</mat-icon>组合项目维护</button>
            </span>
        </mat-menu>
    </span> -->
    <span class="fill-remaining-space">系统设置</span>
</mat-toolbar>
<mat-card class="reg-card">
    <mat-card-header>
        <div mat-card-avatar class="reg-header-image"></div>
        <mat-card-title><b>系统设置</b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <!-- <button mat-raised-button color="primary"
                (click)="onMenuAction('dept-set')"><mat-icon>bookmark</mat-icon>科室信息维护</button> -->
            <button mat-raised-button color="primary" (click)="onMenuAction('base-project')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>基础项目信息维护</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('base-agreement')"
                *appAuth="['管理员','体检科']"><mat-icon>bookmark</mat-icon>自定义协定维护</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('portfolio-project')"
                *appAuth="['管理员','体检科']"><mat-icon>book</mat-icon>组合项目信息维护</button>
            <button mat-raised-button color="primary" (click)="onMenuAction('combo')"
                *appAuth="['管理员','体检科']"><mat-icon>book</mat-icon>体检套餐信息维护</button>
        </div>
    </mat-card-actions>
</mat-card>
<mat-card class="reg-card">
    <mat-card-header>
        <mat-card-title><b></b></mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
            <button mat-raised-button color="primary" (click)="onMenuAction('term-set')"
                *appAuth="['管理员','体检科','分检科室','总检科室']"><mat-icon>book</mat-icon>术语信息维护</button>
            <div *ngIf="session_physical_type=='健康体检'">
                <button mat-raised-button color="primary" (click)="onMenuAction('diag-set')"
                    *appAuth="['管理员','体检科','分检科室','总检科室']"><mat-icon>book</mat-icon>诊断术语维护</button>
            </div>
            <button mat-raised-button color="primary" (click)="onMenuAction('authority-management')"
                *appAuth="['管理员']"><mat-icon>build</mat-icon>用户权限管理</button>

        </div>
    </mat-card-actions>
</mat-card>
<br />