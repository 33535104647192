import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-barcode-input-size',
  templateUrl: './barcode-input-size.component.html',
  styleUrls: ['./barcode-input-size.component.css']
})
export class BarcodeInputSizeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BarcodeInputSizeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb:FormBuilder
  ) {
   }
   input_form:FormGroup

  ngOnInit(): void {
    this.input_form = this.fb.group({
      times:2.2,
      x:0.5,
      y:0.68,
    })
    
  }
  submit(){
    console.log(this.input_form.value)
    this.dialogRef.close(this.input_form.value)
  }

}
