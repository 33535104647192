<mat-card>
    <mat-card-header>
      <img mat-card-avatar src="/assets/eastwill_logo.png">
      <mat-card-title>人员信息维护</mat-card-title>
      <mat-card-subtitle>体检管理系统PEIS-{{version}}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content >
      <form [formGroup]="user">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="用&nbsp;户&nbsp;ID"  formControlName='userid'  readonly >
        </mat-form-field>
        <mat-form-field class="full-width-input">
            <input matInput placeholder="姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名" formControlName="username" readonly >
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input matInput placeholder="所属科室" formControlName="dept" readonly>
          </mat-form-field>
        <mat-form-field class="full-width-input" *ngIf="flag">
          <mat-label *ngIf="passwd_flag">旧&nbsp;密&nbsp;码</mat-label>
          <mat-label *ngIf="!passwd_flag" style="color:red ;">!旧密码有误</mat-label>
          <input matInput type="password" placeholder="请输入旧的登录密码"  formControlName="passwd_old" (blur)="verify()"  autocomplete="off" >
        </mat-form-field>
        <mat-form-field class="full-width-input" *ngIf="flag">
          <mat-label>密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码</mat-label>
          <input matInput type="password" placeholder="请输入新的登录密码" formControlName="passwd_new1"  autocomplete="off" >
        </mat-form-field>
        <mat-form-field class="full-width-input" *ngIf="flag">
          <mat-label *ngIf="passwd_new_flag">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码</mat-label>
          <mat-label *ngIf="!passwd_new_flag" style="color:red ;">!输入密码不一致</mat-label>
          <input matInput type="password" placeholder="请再次输入新的登录密码" formControlName="passwd_new2" (blur)="compare()" autocomplete="off" >
        </mat-form-field>
          <img  style="width: 300px;height: 150px; "  [src]="sign_photo" />
        </form>
    </mat-card-content>
    <mat-card-content>
     <div>
      <button mat-flat-button color="primary" (click)="changePass()" *ngIf="!flag">修改密码</button>
      &nbsp;&nbsp;
      <button mat-flat-button color="primary" (click)="upload_signature()" *ngIf="!flag">上传签名图片</button>
      &nbsp;&nbsp;
       <button mat-flat-button color="primary" (click)="saveInfo()" *ngIf="flag"  [disabled]="!button_flag">保存</button>
       &nbsp;&nbsp;
       <button mat-flat-button color="primary" (click)="cancel()" *ngIf="flag">取消修改</button>
     </div>
    </mat-card-content>
  </mat-card>

  