<!-- 所属地区选择框 -->
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h3>--{{title}}--</h3>
</div>
<div >
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="1%" style="width: 100% ;" [formGroup]="zone_group">
        <mat-form-field style="width: 24%;" appearance="fill">
            <mat-label>省份</mat-label>
            <!-- <mat-select formControlName="province" >
                <mat-option value="辽宁省">辽宁省</mat-option>
            </mat-select> -->
            <mat-select formControlName="province" (selectionChange)="onProvinceChange($event.value)">
                <mat-option *ngFor="let province of provinces" [value]="province">{{province}}</mat-option>
            </mat-select>
        </mat-form-field >
        <mat-form-field style="width: 24%;" appearance="fill">
            <mat-label>市</mat-label>
            <mat-select formControlName="city" (selectionChange)="get_select_zone_list($event.value,'county')">
                <mat-option *ngFor="let c of city_list" [value] = 'c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 24%;" appearance="fill">
            <mat-label>县/区</mat-label>
            <mat-select formControlName="county" (selectionChange)="get_select_zone_list($event.value,'street')">
                <mat-option *ngFor="let c of county_list" [value] ='c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 24%;" appearance="fill"  >
            <mat-label>街道/乡镇</mat-label>
            <mat-select formControlName="street" (selectionChange)="get_select_zone_list($event.value,'end')">
                <mat-option *ngFor="let c of street_list" [value] ='c'>{{c.description}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
        <button mat-raised-button color="warn" (click)="close()">确定</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false">取消</button>
    </div>
</div>