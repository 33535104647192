<mat-toolbar color="primary">
    <div fxFlex fxLayout fxLayoutAlign="space-between"   >
        <div fxFlex fxLayout fxLayoutAlign="start" >
            <button mat-button (click)="returnHome()">
                <mat-icon>home</mat-icon>
                主页
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div style=" border-style:solid;border-width:1px;padding: 2px;color: white;"
                *ngIf="physical_type!='' && physical_type!=undefined">
                <span>&nbsp;{{physical_type}}&nbsp;</span>
                <span style="color: red;">{{font_version_code!=back_version_code?'版本信息错误，请清空缓存':''}}</span>
            </div>
        </div>
        <div fxFlex fxLayout fxLayoutAlign="center" style="font-weight: bold;" fxHide.xs>
            凌源凌钢医院体检管理系统PEIS
        </div>
        <div fxFlex fxLayout fxLayoutAlign="end">
            <ul fxLayout fxLayoutGap="15px" class="navigation-items">
                <li>
                    <button mat-button (click)="gouser()">
                        <mat-icon>person</mat-icon>
                        {{userid?userid+'|'+username:'已退出'}}
                    </button>
                </li>
                <li>
                    <button mat-button (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        退出登录
                    </button>
                </li>
            </ul>
        </div>
    </div>
   
   
</mat-toolbar>