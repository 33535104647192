import { NgModule,LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './shared/material.module';
import {NgxPrintModule} from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import {WebcamModule} from 'ngx-webcam';
import { HoverContainerComponent } from './utils/hover-container/hover-container.component';
import { JwtInterceptor } from './utils/jwt.interceptor';
import { ErrorInterceptor } from './utils/error.interceptor';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { LoginComponent } from './login/login.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule,MAT_DATE_LOCALE} from '@angular/material/core';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { HomeComponent } from './home/home.component';
import { SyssetComponent } from './home/sysset/sysset.component';
import { registerLocaleData } from '@angular/common'; 
import zh from '@angular/common/locales/zh';
import { UsersComponent } from './home/users/users.component';
import { BasicComponent } from './home/basic/basic.component';
import { ContentComponent } from './home/content/content.component';
import { CostComponent } from './home/cost/cost.component';
import { BaseAgreementComponent } from './home/sysset/base-agreement/base-agreement.component';
import { StatisticsComponent } from './home/statistics/statistics.component';


registerLocaleData(zh);

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    HoverContainerComponent,
    SidenavListComponent,
    LoginComponent,    
    AppComponent,
    HomeComponent,
    SyssetComponent,
    UsersComponent,
    BasicComponent,
    ContentComponent,
    CostComponent,
    BaseAgreementComponent,
    StatisticsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    WebcamModule,
    NgxPrintModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
	ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
    { provide: LOCALE_ID, useValue: "zh-cn" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
