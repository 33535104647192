import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marryStates'
})
export class MarryStatesPipe implements PipeTransform {

  transform(value:number): string {
    if (value===0){
      return '未婚'
    }else if(value==1){
      return '已婚'
    }else if(value==2){
      return '离异'
    }else if(value==3){
      return '丧偶'
    }else if(value==4){
      return '其他'
    }else{
      return ''
    }
  }

}
