<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>体检登记(健康)</b></h1>
    <h3><b>修改人员信息</b></h3>
</div>
<hr>
<form [formGroup]="tGroupPersonForm" style="width:100%;height:84%;">
    <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>人员姓名</mat-label>
            <input matInput type="text" [maxlength]="50" formControlName="person_name" placeholder="请输入人员姓名" required />
            <mat-error>人员姓名不可为空</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>证件号码</mat-label>
            <input matInput type="text" formControlName="id_card" placeholder="请输入证件号码"  (ngModelChange)="generate_other_by_id_card($event)">
            <mat-error *ngIf="tGroupPersonForm.get('id_card').value!=''&&(tGroupPersonForm.get('id_card').hasError('pattern')||tGroupPersonForm.get('id_card').hasError('dateOfBirthInvalid')||tGroupPersonForm.get('id_card').hasError('idcardInvalid'))">证件号码格式错误</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>性别</mat-label>
            <mat-select required formControlName="sex">
                <mat-option value="1">男</mat-option>
                <mat-option value="2">女</mat-option>
            </mat-select>
            <mat-error>性别不可为空，请输入</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>出生日期</mat-label>
            <input matInput [matDatepicker]="birthPicker" placeholder="请选择" style="width: 100%;"
                formControlName="birth" />
            <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>年龄</mat-label>
            <input matInput type="number" formControlName="age" placeholder="请输入年龄" />
            <mat-error>年龄不可为空，请输入</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>结婚状况</mat-label>
            <mat-select formControlName="is_marry" placeholder="请选择">
                <mat-option value=""></mat-option>
                <mat-option value="0">未婚</mat-option>
                <mat-option value="1">已婚</mat-option>
                <mat-option value="2">离异</mat-option>
                <mat-option value="3">丧偶</mat-option>
                <mat-option value="4">其他</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>联系电话</mat-label>
            <input matInput type="text" formControlName="mobile" placeholder="请输入手机号码" />
            <mat-error *ngIf="tGroupPersonForm.get('mobile').value!=''&&tGroupPersonForm.get('mobile').hasError('pattern')">联系电话格式错误</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>生产单元（厂区）</mat-label>
            <input matInput type="text" formControlName="production_cell" > 
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>人员工号</mat-label>
            <input matInput type="text" formControlName="work_num" > 
        </mat-form-field>

        <mat-slide-toggle formControlName="banonymity">信息隐匿</mat-slide-toggle>
    </div>
    <hr>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="20px"
        style="height: 10%;width: 100% !important;">
        <button mat-raised-button color="warn" [matDialogClose]="false">取消</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="submit_disabled"
            (click)="submit()">提交</button>
    </div>
</form>