import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortfolioProjectService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }
  sch_portfolio_project_list(section_code, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number, physical_type): Observable<any> {
    let params = new HttpParams()
      .set('section_code', section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('physical_type', physical_type)

    return this.http.get<any>(`${environment.apiUrl}/sysset/sch_portfolio_project_list/`, { params })
      .pipe(
        map(res => {

          return res
        }),
        catchError(handleError)
      )
  }
  add_portfolio_project(portfolio_project) {
    let input_data = {
      'portfolio_project': portfolio_project
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/add_portfolio_project/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  insert_portfolio_project(portfolio_project, relationBasePortfolios) {
    let input_data = {
      'portfolio_project': portfolio_project,
      'relationBasePortfolios': relationBasePortfolios,
    }

    return this.http.post<any>(`${environment.apiUrl}/sysset/insert_portfolio_project/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  edit_portfolio_project(portfolio_project, relationBasePortfolios) {
    let input_data = {
      'portfolio_project': portfolio_project,
      'relationBasePortfolios': relationBasePortfolios
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/edit_portfolio_project/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_relation_base_portfolios_by_p(id: string) {
    let input_data = {
      'id': id
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/search_relation_base_portfolios_by_p/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  delete_selected(selected: any) {

    let input_data = {
      "selected": selected
    }

    return this.http.post<any>(`${environment.apiUrl}/sysset/delete_selected_portfolio_project_list/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_his_itemdt_by_dept_and_part(sortid, filterValue) {
    let params = new HttpParams()
      .set('sortid', sortid)
      .set('item_code', filterValue)
    return this.http.get<any>(`${environment.apiUrl}/sysset/search_his_itemdt_by_dept_and_part/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_his_itemdt_by_sortid_and_itemid(sortid, filterValue) {
    let params = new HttpParams()
      .set('sortid', sortid)
      .set('item_code', filterValue)
    return this.http.get<any>(`${environment.apiUrl}/sysset/search_his_itemdt_by_sortid_and_itemid/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }


  search_his_itemdt_by_itemid(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/sysset/search_his_itemdt_by_itemid/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }


  search_base_agreement_detail(itemid) {
    let params = new HttpParams()
      .set('itemid', itemid)
    return this.http.get<any>(`${environment.apiUrl}/sysset/search_base_agreement_detail/`, { params })
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_base_agreement_list(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/sysset/search_base_agreement_list/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  base_agreement_list_selected(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/sysset/base_agreement_list_selected/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  search_base_agreement_list_custom(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/sysset/search_base_agreement_list_custom/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }

  custom_agreement_list_selected(input_data) {
    return this.http.post<any>(`${environment.apiUrl}/sysset/custom_agreement_list_selected/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
}
