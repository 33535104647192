import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { PeResultService } from "../pe-result.service";

export class PeResultDatasource implements DataSource<any>{
    private patSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: any[];
    public length: number

    constructor(private peResultService: PeResultService) { }

    loadtables(group_unit_name,section_code:string,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,start_date,end_date,print_flag:string = '') {

        this.loadingSubject.next(true);
        this.peResultService.sch_pe_result_check_list(group_unit_name,section_code,q_condition, q_value, tpage_nmb, tn_per_page,start_date,end_date,print_flag)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(thsp => {
                this.data = <any[]>thsp.data;
                this.length = thsp.count_num

                this.patSubject.next(thsp.data)
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}