import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { PortfolioProject } from "src/app/models/sysset/portfolio_project";
import { PortfolioProjectService } from "../portfolio-project.service";

export class PortfolioProjectDatasource implements DataSource<PortfolioProject>{
    private patSubject = new BehaviorSubject<PortfolioProject[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: PortfolioProject[];
    public length: number

    constructor(private portfolioProjectService: PortfolioProjectService) { }

    loadtables(section_code:string,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,physical_type:string='职业体检') {

        this.loadingSubject.next(true);
        this.portfolioProjectService.sch_portfolio_project_list(section_code,q_condition, q_value, tpage_nmb, tn_per_page,physical_type)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(thsp => {
                this.data = <PortfolioProject[]>thsp.data;
                this.length = thsp.count_num

                this.patSubject.next(thsp.data)
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<PortfolioProject[] | readonly PortfolioProject[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}
