import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router, NavigationExtras } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { SingleCheckService } from 'src/app/home/content/single-check/single-check.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { PeResultDatasource } from './list.datasource';
import { PeResultService } from '../pe-result.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [DatePipe]

})
export class ListComponent implements OnInit {
  tbutton_status: boolean = false;

  tcombo: any = {}
  dataSource: PeResultDatasource;
  displayedColumns = ['select', 'test_num', 'person_name', 'sex', 'age', 'physical_type', 'dept', 'check_date', 'regist_date','print_flag'];
  print_loading = false

  selection = new SelectionModel<any>(true, []);
  selectedRowIndexAuto: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("group_unit_selecter") group_unit_selecter :any;
  input_text = ''
  input_cate: string = 'test_num';
  combo_type_list = []
  section_id = '4'
  @Input() parent: any;
  lastWeekStartDate: Date;
  tomorrow: Date;
  range: FormGroup
  session_physical_type = sessionStorage.getItem('physical_type')
  print_flag = '0'
  print_button_status = false
  print_button_text = '打印选中'

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private singleCheckService: SingleCheckService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    private peResultService: PeResultService,

    public dialog: MatDialog,
  ) {
    this.get_combo_type_list()
    const today = new Date()
    this.lastWeekStartDate = new Date(today)
    this.tomorrow = new Date(today)
    this.lastWeekStartDate.setDate(today.getDate() - 7);
    this.tomorrow.setDate(today.getDate() + 1)
  }

  ngOnInit(): void {
    this.dataSource = new PeResultDatasource(this.peResultService);
    this.range = new FormGroup({
      start: new FormControl(this.lastWeekStartDate),
      end: new FormControl(this.tomorrow)
    });
    this.dataSource.loadtables('',this.section_id, '', '', 0, 15, this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd HH:mm:ss'), this.print_flag);

  }

  ngAfterViewInit() {
    if (this.tcombo == undefined) {
      this.tcombo = {}
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();
  }


  loadTablePage() {
    this.selection.clear()
    if (this.tcombo == undefined) {
      this.tcombo = {}
    }
    this.dataSource.loadtables(
      this.group_unit_selecter.unit,
      this.section_id,
      this.input_cate,
      this.input_text,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd HH:mm:ss'),
      this.print_flag,
    );

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  search() {
    this.paginator.pageIndex = 0
    this.loadTablePage()
  }

  add_combo() {

    this.router.navigate(['sysset/combo/detail'])
  }
  edit_combo(row, edit_flag) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: row,
        edit_flag: edit_flag
      }
    };
    this.router.navigate(['sysset/combo/detail'], navigationExtras)
  }
  get_combo_type_list() {
    this.commonService.get_dict_data_by_type('checkType').subscribe(res => {
      if (res.flag) {
        this.combo_type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  generate_total_check_sheet_list() {
    this.print_loading = true

    let input_data = {
      "persons": this.selection.selected.filter(f=>f.print_flag!=-1),
      "physical_type": this.session_physical_type,
    }
    this.peResultService.generate_total_check_sheet_list(input_data).subscribe(res => {
      if (res.flag) {
        this.commonService.print_pdf(res.value)
        this.notifyService.showSuccess('打印选中体检报告完成', '成功')
        this.search()
      } else {
        this.notifyService.showError(res.value, '失败')
      }
      this.print_loading = false
    })
  }

  replay() {
    // this.input_cate = 'test_num'
    this.input_text = ''
    this.print_button_status = false
    // this.print_button_text = '0'
    this.group_unit_selecter.input_form.get('unit').setValue('')
    // this.range.get('start').setValue(this.lastWeekStartDate)
    // this.range.get('end').setValue(this.tomorrow)
    this.search()
  }

  print_flag_change(event) {
    
    let print_flag = event.value
    if (print_flag == '-1') {
      this.print_button_status = true
    }else{
      this.print_button_status =false
    }
    if(print_flag =='1'){
      this.print_button_text = '补打选中'
    }else{
      this.print_button_text = '打印选中'
    }
    this.dataSource.loadtables(
      this.group_unit_selecter.unit,
      this.section_id,
      this.input_cate,
      this.input_text,
      this.paginator.pageIndex,
      this.paginator.pageSize,
      this.datePipe.transform(this.range.value.start, 'yyyy-MM-dd HH:mm:ss'), this.datePipe.transform(this.range.value.end, 'yyyy-MM-dd HH:mm:ss'),
      print_flag
    );
  }
}
