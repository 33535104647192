import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recheckStatus'
})
export class RecheckStatusPipe implements PipeTransform {

  transform(value: unknown): string {
      if (value==0){
        return '未登记'
      }else if(value==1){
        return '已登记'
      }else if(value==2){
        return '复检中'
      }else if(value==3){
        return '总检中'
      }else if(value==4){
        return '已完成总检'
      }
    }
}
