<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%" *ngIf="!dialog_data">
    <h1 style="color: blue;"><b>系统设置</b></h1>
    <h3><b>诊断术语维护</b></h3>
</div>
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;" *ngIf="dialog_data">
    <h1>--{{title}}--</h1>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <!-- <app-his-dept-list [parent]="this" [title]="'科室'" [office_id]="office_id" #his_dept_list></app-his-dept-list> -->
    <app-his-dept-list [parent]="this" [title]="'科室'" [office_id]="office_id" (office_id_send)="officeidUpdated($event)" #his_dept_list></app-his-dept-list>
    <mat-form-field appearance="fill">
        <mat-label>搜索字段</mat-label>
        <mat-select [(ngModel)]="input_cate">
            <mat-option value="diag_brief_code">诊断简码</mat-option>
            <mat-option value="diag_name">诊断名称</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>请输入查询内容..</mat-label>
        <input matInput [(ngModel)]="input_text" (keyup.enter)="search()" />
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="search()"><mat-icon>search</mat-icon>搜索</button>
    <button mat-stroked-button color="warn" (click)="replay()" matTooltip="重置搜索项"><mat-icon>replay</mat-icon>重置</button>
    <span></span>
    <span></span>
    <button mat-raised-button color="warn" (click)="exit()" *ngIf="dialog_data">退出</button>
    <button mat-raised-button color="primary" (click)="to_import()" *ngIf="dialog_data">导入</button>
</div>
<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <button mat-stroked-button color="primary" (click)="add_diag_set()"> <mat-icon>add</mat-icon>新增</button>
    <button mat-stroked-button color="primary" (click)="delete_selected()"><mat-icon>delete</mat-icon>批量删除</button>
</div>
<br>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-table class="drug-insert-table mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="office_id"> <mat-header-cell *matHeaderCellDef>科室id</mat-header-cell> <mat-cell
            *matCellDef="let sa">{{sa.office_id}}</mat-cell></ng-container>
    <ng-container matColumnDef="office_name"> <mat-header-cell *matHeaderCellDef>科室名称</mat-header-cell> <mat-cell
            *matCellDef="let sa">{{sa.office_name}}</mat-cell></ng-container>
    <ng-container matColumnDef="diag_brief_code"> <mat-header-cell *matHeaderCellDef>诊断简码</mat-header-cell> <mat-cell
            *matCellDef="let sa">{{sa.diag_brief_code}}</mat-cell></ng-container>
    <ng-container matColumnDef="diag_name"> <mat-header-cell *matHeaderCellDef>诊断名称</mat-header-cell> <mat-cell
            *matCellDef="let sa">{{sa.diag_name}}</mat-cell></ng-container>
    <ng-container matColumnDef="advice_suggest"> <mat-header-cell *matHeaderCellDef>参考建议</mat-header-cell> <mat-cell
            *matCellDef="let sa">{{sa.advice_suggest?.slice(0,50)}}</mat-cell></ng-container>

    <ng-container matColumnDef="operate">
        <mat-header-cell *matHeaderCellDef><b>操作</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">
            <button mat-icon-button matTooltip="查看" (click)="edit_diag_set(row,false)"><mat-icon
                    color="primary">visibility</mat-icon></button>
            <button mat-icon-button matTooltip="修改" (click)="edit_diag_set(row,true)"><mat-icon
                    color="primary">edit</mat-icon></button>
            <button mat-icon-button matTooltip="删除" (click)="delete_item(row)"><mat-icon
                    color="warn">delete</mat-icon></button>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<mat-paginator [length]="dataSource.length" [pageSize]="15" [pageSizeOptions]="[15,30, 60]" showFirstLastButtons>
</mat-paginator>

<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%">
    <button mat-raised-button color="warn" (click)="exit()" *ngIf="dialog_data">退出</button>
    <button mat-raised-button color="primary" (click)="to_import()" *ngIf="dialog_data">导入</button>
</div>