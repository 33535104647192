
<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--问诊详情--</h1>
</div>
<hr>
<mat-progress-bar *ngIf="data_loading" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="1%" style="color: blue;">
   <!-- <h3>人员姓名:{{ask_check_group.get('person_name').value}}</h3>
   <h3>检查医生:{{ask_check_group.get('ask_check_doc').value}}</h3>
   <h3>检查日期:{{ask_check_group.get('ask_check_date').value|date:"yyyy-MM-dd HH:mm"}}</h3> -->
   <h3>人员姓名:{{ask_check_group.value.anonymity==1?(ask_check_group.value.person_name|nameHide):ask_check_group.value.person_name}}</h3>
   <h3>检查医生:{{ask_check_group.value.ask_check_doc}}</h3>
   <h3>检查日期:{{ask_check_group.value.ask_check_date|date:"yyyy-MM-dd HH:mm"}}</h3>
</div>
<form [formGroup]="ask_check_group" >
    <div style="width:100%">
        <br>
        <mat-card>
            <mat-card-header>
                <mat-card-title>家族史</mat-card-title>
            </mat-card-header>
            <mat-card-content formGroupName="personalLifeHistory" class="unclickable">
                <mat-form-field style="width: 100%;" >
                    <mat-label></mat-label>
                    <textarea matInput matTextareaAutosize formControlName="familyHistory"></textarea>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="1%" style="width: 100%;">
                    <mat-card-title>既往病史</mat-card-title>
                </div>
            </mat-card-header>
            <mat-card-content>
                <div class="content">
                    <table mat-table [dataSource]=datasource_jwbs style="width: 100%;">
                        <ng-container matColumnDef="operate">
                            <th mat-header-cell *matHeaderCellDef>操作</th>
                            <td mat-cell *matCellDef="let element;let i =index;">
                                <button mat-button (click)="delete_jwbs(i,element)" readonly><mat-icon
                                        color="warn">delete</mat-icon></button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="disease_name">
                            <th mat-header-cell *matHeaderCellDef>患病名称</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="disease_name" readonly>
                                    <mat-error>患病名称不可为空</mat-error>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="diagnose_date">
                            <th mat-header-cell *matHeaderCellDef>诊断日期</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput [matDatepicker]="diagnose_date" placeholder="请选择诊断日期"
                                        formControlName="diagnose_date" readonly>
                                    <mat-datepicker #diagnose_date startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="diagnose_dept">
                            <th mat-header-cell *matHeaderCellDef>诊断单位</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="diagnose_dept" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="process">
                            <th mat-header-cell *matHeaderCellDef>治疗经过</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="process" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="end">
                            <th mat-header-cell *matHeaderCellDef>转归</th>
                            <td mat-cell *matCellDef="let element" [formGroup]="element">
                                <mat-form-field floatLabel="never" style="width: 90%;">
                                    <input matInput type="text" formControlName="end" readonly>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns_jwbs"></tr>
                        <tr mat-row *matRowDef="let row;columns:displayedColumns_jwbs"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>其他</mat-card-title>
            </mat-card-header>
            <mat-card-content class="unclickable">
                <mat-form-field style="width: 100%;" >
                    <mat-label></mat-label>
                    <textarea matInput matTextareaAutosize [readonly]="com_disabled"
                        formControlName="other_content"></textarea>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <br>
    <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1%">
        <button mat-raised-button color="primary" [mat-dialog-close]="false">退出</button>
    </div>
</form>
<br>
<br>
<br>
<br>