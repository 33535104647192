import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { handleError } from './comm-func';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) { }

  get_version(): Observable<any> {
    const headers = new HttpHeaders()
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache'); 
    return this.http.get(`${environment.apiUrl}/get_version/`,{headers})
      .pipe(
        map(res => {
          return res
        }),
        catchError(handleError)
      )
  }
}