import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { DeptSetPopComponent } from '../../dept-set/dept-set-pop/dept-set-pop.component';
import { DeptSetService } from '../../dept-set/dept-set.service';
import { DiagSetService } from '../diag-set.service';

@Component({
  selector: 'app-diag-set-pop',
  templateUrl: './diag-set-pop.component.html',
  styleUrls: ['./diag-set-pop.component.css'],
  providers: [DatePipe]

})
export class DiagSetPopComponent implements OnInit {

  diag_set: FormGroup
  edit_flag: boolean = false
  submit_disabled: boolean = false
  his_dept_list = []

  constructor(
    public dialogRef: MatDialogRef<DeptSetPopComponent>,
    private fb: FormBuilder,
    private diagSetService: DiagSetService,
    private commonService: CommonService,
    private notifyService: NotificationService, 
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
  ) {
    this.get_his_dept_list()

  }

  ngOnInit(): void {
    if (this.dialog_data) {

      let data = this.dialog_data.data
      this.diag_set = this.fb.group(
        {
          id: data.id,
          office_id: data.office_id,
          office_name: data.office_name,
          diag_brief_code: data.diag_brief_code,
          diag_name: data.diag_name,
          advice_suggest: data.advice_suggest
        }
      )
      this.edit_flag = this.dialog_data.edit_flag
      if (!this.edit_flag) {
        this.diag_set.disable()
        this.submit_disabled = true
      }
    } else {
      this.diag_set = this.fb.group({
        office_id: '',
        office_name: '',
        diag_brief_code: '',
        diag_name: '',
        advice_suggest: ''

      })
    }

  }
  submit() {

    this.dialogRef.close(true)

    if (this.edit_flag) {
      this.diagSetService.edit_diag_set(this.diag_set.value)
        .subscribe(res => {
          if (res.flag) {
            this.notifyService.showSuccess('修改诊断术语信息成功', '修改成功')
            this.dialogRef.close(true)
          } else {
            this.notifyService.showError(res.value, '失败')

          }
        })
    } else {
      this.diagSetService.insert_diag_set(this.diag_set.value)
        .subscribe(res => {
          if (res.flag) {
            this.notifyService.showSuccess('新增诊断术语信息成功', '新增成功')
            this.dialogRef.close(true)
          } else {
            this.notifyService.showError(res.value, '失败')

          }
        })

    }

  }
  get_his_dept_list() {
    this.commonService.get_his_dept_List().subscribe(res => {
      if (res.flag) {
        this.his_dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息' + res.value, '错误')
      }
    })
  }
  set_office_name(event){
    let dept_code = event.value
    let dept_dict = this.his_dept_list.find(f=>f.section_code==dept_code)
    let office_name = dept_dict.section_name
    this.diag_set.get('office_name').setValue(office_name)
  }

}
