import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeChangeComponent } from './time-change/time-change.component';
import { BaseDialogComponent } from './base-dialog/base-dialog.component';
import { NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { PeResultRoutingModule } from '../home/basic/pe-result/pe-result-routing.module';
import { MaterialModule } from '../shared/material.module';
import { ErrorInterceptor } from '../utils/error.interceptor';
import { JwtInterceptor } from '../utils/jwt.interceptor';
import { UserValidationComponent } from './user-validation/user-validation.component';
import { UploadSignComponent } from './upload-sign/upload-sign.component';
import { ZoneSelectComponent } from './zone-select/zone-select.component';
import { IndusTypeSelectComponent } from './indus-type-select/indus-type-select.component';
import { EconomySelectComponent } from './economy-select/economy-select.component';
import { LoadErrorComponent } from './load-error/load-error.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { TermOrAbnormalComponent } from './term-or-abnormal/term-or-abnormal.component';
import { TermImportComponent } from './term-or-abnormal/term-import/term-import.component';
import { AbnormalImportComponent } from './term-or-abnormal/abnormal-import/abnormal-import.component';
import { BaseDialogAlertComponent } from './base-dialog-alert/base-dialog-alert.component';
import { ImagesResultDialogComponent } from './images-result-dialog/images-result-dialog.component';
import { DiagNameImportComponent } from './diag-name-import/diag-name-import.component';
import { DiagSetImportComponent } from './diag-set-import/diag-set-import.component';
import { ComponentModelsModule } from '../component-models/component-models.module';
import { HazardAddMultiple2Component } from './hazard-add-multiple2/hazard-add-multiple2.component';
import { PortfolioProjectImportComponent } from './portfolio-project-import/portfolio-project-import.component';
import { DialogOneInputComponent } from './dialog-one-input/dialog-one-input.component';
import { GroupUnitSelectComponent } from './group-unit-select/group-unit-select.component';
import { MealProjectImportComponent } from './meal-project-import/meal-project-import.component';
import { BarcodeInputSizeComponent } from './barcode-input-size/barcode-input-size.component';
import { EditPersonComponent } from './edit-person/edit-person.component';
import { EditHealthPersonComponent } from './edit-health-person/edit-health-person.component';

@NgModule({
  declarations: [TimeChangeComponent, BaseDialogComponent, UserValidationComponent, UploadSignComponent,
    ZoneSelectComponent, IndusTypeSelectComponent, EconomySelectComponent,
    LoadErrorComponent, UploadDialogComponent, TermOrAbnormalComponent,
    TermImportComponent, AbnormalImportComponent, BaseDialogAlertComponent,
    ImagesResultDialogComponent, DiagNameImportComponent, DiagSetImportComponent,
    HazardAddMultiple2Component, PortfolioProjectImportComponent, DialogOneInputComponent,
    GroupUnitSelectComponent, MealProjectImportComponent, BarcodeInputSizeComponent,
    EditPersonComponent, EditHealthPersonComponent],
  imports: [
    CommonModule,
    PeResultRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    WebcamModule,
    NgxPrintModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatDatetimePickerModule,
    ComponentModelsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: MAT_DATE_LOCALE, useValue: 'zh-CN' },
  { provide: LOCALE_ID, useValue: "zh-cn" },
  ],
  exports: [TimeChangeComponent, BaseDialogComponent, BaseDialogAlertComponent,
    ImagesResultDialogComponent, UserValidationComponent,
    DiagNameImportComponent, DialogOneInputComponent,
    GroupUnitSelectComponent,
    MealProjectImportComponent,
    BarcodeInputSizeComponent,
    EditPersonComponent]
})
export class DialogModelsModule { }
