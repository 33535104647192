<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px">
    <h2>--{{data.title}}--</h2>
</div>
<div>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>文件名称</mat-label>
        <input matInput placeholder="请输入文件名称" [(ngModel)]="name"  >
    </mat-form-field>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20%">
        <button mat-raised-button color="warn" (click)="close()">确定</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="false">取消</button>
    </div>
</div>
