import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentModelsModule } from '../component-models/component-models.module';
import { DialogModelsModule } from '../dialog-models/dialog-models.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ComponentModelsModule,
    DialogModelsModule
  ],
  exports:[
    ComponentModelsModule,
    DialogModelsModule
  ]
})
export class SharedModule { }
