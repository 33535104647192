import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jzType'
})
export class JzTypePipe implements PipeTransform {

  transform(value:number): string {
    if (value==1){
      return '常规监测'
    }else if(value==2){
      return '主动监测'
    }else{
      return ''
    }
  }
}
