<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h2>--{{title}}--</h2>
</div>
<mat-form-field appearance="fill" style="width: 100%;" [formGroup]="input_form">
    <mat-label>团检单位名称</mat-label>
    <input matInput placeholder="请选择人员所在企业名称" formControlName="unit"  [matAutocomplete]="enterprise" />
    <mat-autocomplete requireSelection #enterprise="matAutocomplete" [displayWith]="displayFn_e.bind(this)">
        <mat-option *ngFor="let e of unit_list |async" [value]="e">{{e.name}}</mat-option>
    </mat-autocomplete>
</mat-form-field>
<div style="width: 100%;" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="1%">
    <button mat-raised-button color="warn" [matDialogClose]="false">取消</button>
    <button mat-raised-button color="primary" type="submit" (click)="submit()">提交</button>
</div>