import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../data-services/auth.service';
import { NotificationService } from './notification.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,
        private notifyService: NotificationService,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        //兼容低版本火狐和ie,实现退出浏览器后，需要重新登录
        let is_login = sessionStorage.getItem('is_login')
        if (is_login==null||is_login==undefined||is_login=='logout') {
            this.notifyService.showWarning('登录已过期，请重新登录','登录失效')
            this.authenticationService.logout();
        }
        if (currentUser && currentUser.access_token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.access_token}`
                }
            });
        }

        return next.handle(request);
    }
}