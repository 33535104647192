<form fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1%" (submit)="close(true)" [formGroup]="user">
    <h3 style="color: red;">请输入您的用户密码，确认是您本人操作</h3>
    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>用户密码</mat-label>
        <input type="password" matInput formControlName="password">
    </mat-form-field>
    <div  fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1%" style="width: 100%;">
        <button mat-raised-button color="primary" (click)="close(true)">确定</button>
        <button mat-raised-button color="primary" [matDialogClose]="false" >取消</button>
    </div>
</form>