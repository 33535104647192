import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../data-services/auth.service';
import { NotificationService } from './notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,
        private notifyService : NotificationService) { }
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 || err.status === 403) {
                this.authenticationService.logout();
                this.notifyService.showError(err.error.detail,'错误')
            }else if (err.status === 423){
                this.notifyService.showError(err.error.detail,'错误')
            }else if(err.status === 200){
                
            }else{
                const error =  err.status +' | ' +err.message +'|' +err.statusText ;
                this.notifyService.showError("提交失败",error)
            }
            return throwError(err)           
        }))
    }
}