import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { NotificationService } from "src/app/utils/notification.service";
import { TermImportService } from "./term-import.service";

export class TermImportDatasource implements DataSource<any>{
    private patSubject = new BehaviorSubject<any[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();
    public data: any[];
    public length: number

    constructor(private termImportService: TermImportService,private notifyService:NotificationService) { }

    loadtables(search_condition, tpage_nmb: number, tn_per_page: number) {

        this.loadingSubject.next(true);
        this.termImportService.sch_term_set_list(search_condition, tpage_nmb, tn_per_page)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(thsp => {
                if (thsp.flag){
                    this.data = <any>thsp.value.data;
                    this.length = thsp.value.count_num
                    this.patSubject.next(thsp.value.data)
                }else{
                    this.notifyService.showError(thsp.value,'错误')
                    this.data =[]
                    this.length = 0
                    this.patSubject.next([])
                }
            });

    }

    connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
        return this.patSubject.asObservable();
    }
    disconnect(collectionViewer: CollectionViewer): void {
        this.patSubject.complete();
        this.loadingSubject.complete();
    }


}
