import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../data-services/auth.service';
import { first } from 'rxjs/operators';
import { HeaderComponent } from '../navigation/header/header.component';
import { ShareService } from '../data-services/share-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  public loginInvalid: boolean = false;
  private formSubmitAttempt: boolean = false;
  private returnUrl!: string;
  error = '';
  @ViewChild('header')
  header: HeaderComponent;
  physical_type = "健康体检";
  submit_protect = true
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private shareService: ShareService) {
    //如果已经登录则跳转到主页面
    //if (this.authService.currentUserValue) {
    //  this.router.navigate(['/home']);
    //}
  }

  async ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    //if (await this.authService.checkAuthenticated()) {
    await this.router.navigate([this.returnUrl]);
    //}
  }

  onSubmit() {
    if (this.submit_protect) {
      this.submit_protect = false
      this.loginInvalid = false;
      this.formSubmitAttempt = false;
      sessionStorage.setItem('is_login', 'login')
      if (this.form.valid) {
        const username = this.form?.get('username')?.value;
        const password = this.form?.get('password')?.value;
        this.authService.login(username, password)
          .pipe(first())
          .subscribe(
            data => {              
              this.submit_protect = true
              sessionStorage.setItem('physical_type', this.physical_type)
              this.shareService.data.next(true)
              this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home'
              this.router.navigate([this.returnUrl]);
            },
            error => {
              this.submit_protect = true
              this.error = error.error.detail;
              this.loginInvalid = true;
              sessionStorage.setItem('is_login', 'login')
            });

      } else {
        this.formSubmitAttempt = true;
      }
    }

  }

}

