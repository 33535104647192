<div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px"
    style="height: 6%;width: 100% !important;">
    <h1>--设置诊断术语--</h1>
</div>
<hr>
<form [formGroup]="diag_set" style="width:100%;height:84%;">
    <div style="width: 100%" fxLayout="row wrap" fxLayoutGap="1%">
        <mat-form-field appearance="fill" style="width: 32%;">
            <mat-label>诊断科室</mat-label>
            <mat-select formControlName="office_id" (selectionChange)="set_office_name($event)">
                <mat-option value="">所有科室</mat-option>
                <mat-option *ngFor="let i of his_dept_list"
                    [value]="i.id">{{i.section_code}}:{{i.section_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"  style="width: 32%;"> <mat-label>诊断简码</mat-label> <input type="text"
                formControlName="diag_brief_code" matInput /></mat-form-field>
        <mat-form-field appearance="fill"  style="width: 32%;"> <mat-label>诊断名称</mat-label> <input type="text"
                formControlName="diag_name" matInput /></mat-form-field>
        <mat-form-field appearance="fill" style="width: 100%;"> <mat-label>参考建议</mat-label>
            <textarea type="text" formControlName="advice_suggest" matInput></textarea>
        </mat-form-field>

    </div>
    <hr>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="20px"
        style="height: 10%;width: 100% !important;">
        <button mat-raised-button color="warn" [matDialogClose]="false">取消</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="submit_disabled"
            (click)="submit()">提交</button>
    </div>
</form>