<section fxLayout="column" fxFlexAlign="stretch">
  <div fxFlexAlign="center">
    <p>凌源凌钢医院体检管理系统PEIS</p>
  </div>
  <div fxFlexAlign="center" style="text-align: center;">
    <hover-container (click)="nav_page('basic')" [class]="page =='basic'?'overlay':''" *appAuth="['管理员','体检科','分检科室','总检科室','前台','问诊科室']">
      <p>体检信息管理</p>
      <ng-container overlay>体检信息管理</ng-container>
      <img src="./assets/reg_hsp.jpg" alt="体检信息管理" class="circular--square" width="220" height="220">
    </hover-container>
    <hover-container (click)="nav_page('cost')" [class]="page =='cost'?'overlay':''" *appAuth="['管理员','体检科','财务']">
      <p>体检费用管理</p>
      <ng-container overlay>体检费用管理</ng-container>
      <img src="./assets/s05.jpg" alt="体检费用管理" width="300" height="250">
    </hover-container>
    <hover-container (click)="nav_page('content')" [class]="page =='content'?'overlay':''"  *appAuth="['管理员','体检科','分检科室','总检科室','前台','问诊科室']">
      <p>体检中心</p>
      <ng-container overlay>体检中心</ng-container>
      <img src="./assets/authority_assign.jpg" alt="体检中心" width="300" height="250">
    </hover-container>
    <hover-container (click)="nav_page('statistics')" [class]="page =='statistics'?'overlay':''"  *appAuth="['管理员','体检科']">
      <p>查询与统计</p>
      <ng-container overlay>查询与统计</ng-container>
      <img src="./assets/dashboard.jpg" alt="查询与统计" width="300" height="250">
    </hover-container>
    <hover-container (click)="nav_page('sysset')" [class]="page =='sysset'?'overlay':''"  *appAuth="['管理员','体检科','分检科室','总检科室']">
      <p>系统设置</p>
      <ng-container overlay>系统设置</ng-container>
      <img src="./assets/sysset.jpg" alt="系统设置" width="300" height="250">
    </hover-container>
  </div>
</section>
<hr />
<div #scroll_target></div>