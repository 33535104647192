import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'printStatus'
})
export class PrintStatusPipe implements PipeTransform {

  transform(value:number): string {
    if(value==0){
      return '未打印'
    }else if(value==1){
      return '已打印'
    }else if(value==-1){
      return '打印已过期'
    }else{
      return ''
    }
  }

}
