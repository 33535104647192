<br>
<br>
<br>
<br>
<mat-card>
  <mat-card-header>
    <img mat-card-avatar src="./assets/eastwill_logo.png">
    <mat-card-title>登录系统</mat-card-title>
    <mat-card-subtitle>体检管理系统PEIS
    </mat-card-subtitle>
    <mat-form-field  appearance="fill">
      <mat-label style="color: blue;">体检类型</mat-label>
      <mat-select [(ngModel)]="physical_type">
        <mat-option value="健康体检">健康体检</mat-option>
        <mat-option value="职业体检">职业体检</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-header>
  
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-error *ngIf="loginInvalid">
        {{error}}
      </mat-error>
      <mat-form-field class="full-width-input">
        <input matInput placeholder="用户名" formControlName="username" required>
        <mat-error>
          请输入正确的用户名,长度不能少于4位
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="密码" formControlName="password" required autocomplete="off">
        <mat-error>
          请输入正确的密码
        </mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary">登录</button>
    </form>
  </mat-card-content>
</mat-card>