import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TermImportDatasource } from 'src/app/home/content/term-import/term-import-datasource';
import { TermImportComponent } from 'src/app/home/content/term-import/term-import.component';
import { TermImportService } from 'src/app/home/content/term-import/term-import.service';
import { TermSetPopComponent } from 'src/app/home/sysset/term-set/term-set-pop/term-set-pop.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-term-or-abnormal',
  templateUrl: './term-or-abnormal.component.html',
  styleUrls: ['./term-or-abnormal.component.css'],
  providers: [DatePipe]

})
export class TermOrAbnormalComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private matDialogRef:MatDialogRef<TermOrAbnormalComponent>,
  ) { }

  ngOnInit(): void {
  }
}
