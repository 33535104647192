import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { DialogModelsService } from '../dialog-models.service';
//身份证号校验正则规则
const _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
const _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/

@Component({
  selector: 'app-edit-health-person',
  templateUrl: './edit-health-person.component.html',
  styleUrls: ['./edit-health-person.component.css'],
  providers: [DatePipe]

})
export class EditHealthPersonComponent implements OnInit {

  tGroupPersonForm: FormGroup
  edit_flag: boolean = false
  submit_disabled: boolean = false
  session_physical_type = sessionStorage.getItem('physical_type')
  constructor(
    public dialogRef: MatDialogRef<EditHealthPersonComponent>,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private dialogModelsService:DialogModelsService

  ) {
  }

  ngOnInit(): void {
    if (this.dialog_data.data) {
      let data = this.dialog_data.data
      this.tGroupPersonForm = this.fb.group(
        {
          id: data.id,
          person_name: data.person_name,
          id_card: [data.id_card, [
            Validators.pattern(_IDRe18 || _IDre15),
            this.varifyIdCard()
          ]],
          sex: data.sex,
          birth: data.birth,
          age: data.age,
          is_marry: data.is_marry,
          mobile: [data.mobile, [Validators.pattern(/^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/||/^(0\d{2,3})-?(\d{7,8})$/)]],
          home_address: data.home_address,
          hazard_factors: data.hazard_factors,
          hazard_factors_remark: data.hazard_factors_remark,
          hazard_factors_text: data.hazard_factors_text,
          other_hazard_factors: data.other_hazard_factors,
          work_type_code: data.work_type_code,
          work_type_text: data.work_type_text,
          work_name: data.work_name,
          work_state_code: data.work_state_code,
          work_state_text: data.work_state_text,
          exposure_start_date: data.exposure_start_date,
          jc_type: data.jc_type,
          is_pass: data.is_pass,
          is_check: data.is_check,
          is_wz_check: data.is_wz_check,
          physical_type: data.physical_type,
          order_id: data.order_id,
          group_id: data.group_id,
          dept: data.dept,
          check_date: data.check_date,
          work_state: '',
          unit_id: data.unit_id,
          exposure_years: data.exposure_years,
          exposure_months: data.exposure_months,
          department: data.department,
          work_num: data.work_num,
          anonymity: data.anonymity ,
          banonymity: data.anonymity == '1'?true:false,
          test_num:data.test_num,
          production_cell: data.production_cell
        }
      )
      this.edit_flag = this.dialog_data.edit_flag
      if (!this.edit_flag) {
        this.tGroupPersonForm.disable()
        this.submit_disabled = true
      }
    } else {
      this.tGroupPersonForm = this.fb.group({
        id: '',
        person_name: '',
        id_card: ['', [
          Validators.pattern(_IDRe18 || _IDre15),
          this.validateDateOfBirth(),
          this.varifyIdCard()
        ]],
        sex: '',
        birth: '',
        age: '',
        is_marry: '',
        mobile: [null, [Validators.pattern(/^((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/||/^(0\d{2,3})-?(\d{7,8})$/)]],
        home_address: '',
        hazard_factors: '',
        hazard_factors_text: '',
        other_hazard_factors: null,
        work_type_code: '',
        work_type_text: '',
        work_name: '',
        work_state_code: '',
        work_state_text: '',
        exposure_start_date: '',
        jc_type: '',
        is_pass: -2,//是否通过检查(0-未登记，1-登记，2-在检,3-总检,4-已完成，-1-未交款,-2 订单未确认)
        is_check: 0, //是否检查(0-否，1-是)
        is_wz_check: 0, //问诊科是否检查
        physical_type: this.session_physical_type,
        order_id: this.dialog_data.order_id,
        group_id: this.dialog_data.group_id,
        dept: this.dialog_data.dept,
        check_date: this.dialog_data.check_date,
        work_state: '',
        unit_id: this.dialog_data.unit_id,
        exposure_years: null,
        exposure_months: null,
        department: null,
        work_num: null,
        anonymity: 0 ,
        banonymity: false,
        test_num:''
      })
    }
  }
  submit() {
    this.tGroupPersonForm.markAllAsTouched()
    let person_dict = {}
    Object.keys(this.tGroupPersonForm.controls).map(key => {
      const control = this.tGroupPersonForm.get(key);
      person_dict[key] = control ? control.value : null
    })
    person_dict['hazard_factors'] =''
    person_dict['hazard_factors_remark'] = ''
    person_dict['hazard_factors_text'] = ''
    this.tGroupPersonForm.get('hazard_factors_text').setValue('')
    let birth = this.datePipe.transform(this.tGroupPersonForm.value.birth, 'yyyy-MM-dd')
    person_dict['birth'] = birth
    person_dict['exposure_start_date'] = null
    person_dict['work_state_code'] = ''
    person_dict['work_state_text'] = ''
    person_dict['other_hazard_factors'] = ''
    person_dict['other_hazard_factors_dict'] = ''

    //处理个人信息是否隐匿
    if (this.tGroupPersonForm.value.banonymity==true) {
      this.tGroupPersonForm.get('anonymity').setValue(1)
      person_dict['anonymity'] = 1
    }else{
      this.tGroupPersonForm.get('anonymity').setValue(0)
      person_dict['anonymity'] = 0
    }
    const controls = this.tGroupPersonForm.controls
    let ok_flag = true
    Object.keys(controls).forEach(key => {
      const control = controls[key] as FormControl;
      if (control.hasError('required')) {
        ok_flag = false
        this.notifyService.showWarning('请输入必填项', '警告')
        return
      }
      if (control.hasError('data_overflow')||control.hasError('pattern')||control.hasError('dateOfBirthInvalid')||control.hasError('data_overflow')) {
        ok_flag = false
        this.notifyService.showWarning('输入格式错误，请检查', '警告')
        return
      }
    })
    if (ok_flag) {
      let input_data = {
        person:person_dict
      }
      this.dialogModelsService.update_person(input_data).subscribe(res=>{
        console.log(res);
        if(res.flag){
          this.dialogRef.close(res.value)
          this.notifyService.showSuccess('更新人员信息成功','成功')
        }else{
          this.notifyService.showError('更新人员信息失败'+res.value,'失败')
        }
        
      })
    }
  }

  generate_other_by_id_card(event) {
    
    
    if (!this.tGroupPersonForm.get('id_card').errors) {
      //证件号码格式无误后，再进行其他数据处理
      //获取性别
      if (event.length == 18) {
        
        
        
        
        let birthYear = event.slice(6, 10)
        let birthMonth = event.slice(10, 12)
        let birthDay = event.slice(12, 14)
        let sex_num = event.slice(16, 17)
        let sex = sex_num % 2 == 0 ? '2' : '1'
        let birth = new Date(birthYear + '/' + birthMonth + '/' + birthDay)
        this.tGroupPersonForm.get('birth').setValue(birth)
        this.tGroupPersonForm.get('sex').setValue(sex)
        let now = new Date()
        const nowMonth = now.getMonth() + 1;
        const nowDay = now.getDay();
        // 计算年龄
        let age = now.getFullYear() - birthYear;
        if (nowMonth < birthMonth || (nowMonth === birthMonth && nowDay < birthDay)) {
          age -= 1;
        }
        this.tGroupPersonForm.get('age').setValue(age)
      } else if (event.length == 15) {
        
        
        
        
      }
    }


  }
  //创建自定义函数
  validateDateOfBirth(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      
      let id_card = control.value
      if (id_card.length == 18) {
        let year = id_card.slice(6, 10)
        let month = id_card.slice(10, 12)
        let day = id_card.slice(12, 14)
        let birth = new Date(year + '/' + month + '/' + day)
        if (birth.getMonth() + 1 != parseInt(month) || birth.getDate() != parseInt(day)) {
          
          return { dateOfBirthInvalid: true }
        } else {
          return null
        }
      } else if (id_card.length == 15) {
        let year = id_card.slice(6, 8)
        let month = id_card.slice(8, 10)
        let day = id_card.slice(10, 12)
        let birth = new Date(year + '/' + month + '/' + day)
        if (birth.getMonth() + 1 != parseInt(month) || birth.getDate() != parseInt(day)) {
          
          return { dateOfBirthInvalid: true }
        } else {
          return null
        }
      }
    }
  }

   //身份证号校验
   varifyIdCard(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let code = control.value

      let s: number = 0;

      for (let i: number = 0; i < 17; i++) {
        let j: number = parseInt(code.charAt(17 - i - 1));
        s += j * Math.pow(2, i + 1) % 11;
      }

      let cd: number = (12 - (s % 11)) % 11;
      let last: string = code.charAt(17);

      let res: string;

      if (last === 'X'||last === 'x') {
        if (cd === 10) {
          res = 'correct';
        } else {
          res = 'error';
        }
      } else {
        if (cd === parseInt(last)) {
          res = 'correct';
        } else {
          res = 'error';
        }
      }
      if(res=='correct'){
        return null
      }else{
        return { idcardInvalid: true }
      }
    }
  }

}
