import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TermImportDatasource } from 'src/app/home/content/term-import/term-import-datasource';
import { TermImportService } from 'src/app/home/content/term-import/term-import.service';
import { TermSetPopComponent } from 'src/app/home/sysset/term-set/term-set-pop/term-set-pop.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { BaseDialogComponent } from '../../base-dialog/base-dialog.component';

@Component({
  selector: 'app-term-import',
  templateUrl: './term-import.component.html',
  styleUrls: ['./term-import.component.css'],
  providers: [DatePipe]

})
export class TermImportComponent implements OnInit {
  tbutton_status: boolean = false;

  ttermImport: any;
  dataSource: TermImportDatasource;
  displayedColumns = [
    'select',
    'type_name',
    'hazard_factors_text',
    'work_state_text',
    'content',
    'operate'];

  selection = new SelectionModel<any>(true, []);
  selectedRowIndexAuto: number = 0;
  dept_list = []
  section_id = ''
  hazard_type_list = []

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() parent:any;

  input_text=''
  input_cate: string = 'name';
  search_condition: FormGroup
  inspect_type_list = []
  type_list = []
  hazard_factors_list = []
  work_state_text_list = []
  content=''
  constructor(
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private router: Router,
    private termImportService: TermImportService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    private matDialogRef:MatDialogRef<TermImportComponent>,
  ) {

  }

  ngOnInit(): void {
    this.search_condition = this.fb.group({
      'office_id':	this.dialog_data.office_id,
      'inspect_type':this.dialog_data.inspect_type,
      'type':this.dialog_data.type,
      'work_state_text':this.dialog_data.work_state_text,
      'hazard_factors':this.dialog_data.hazard_factors,
      'content':''
    })
    
    this.dataSource = new TermImportDatasource(this.termImportService, this.notifyService);
    this.dataSource.loadtables(this.search_condition.value, 0, 15);
  }

  ngAfterViewInit() {
    if (this.ttermImport == undefined) {
      this.ttermImport = '';
    }
    merge(this.paginator.page)
      .pipe(
        tap(() => this.loadTablePage())
      )
      .subscribe();
  }


  loadTablePage() {
    this.search_condition.get('content').setValue(this.content)
    this.selection.clear()
    if (this.ttermImport == undefined) {
      this.ttermImport = '';
    }
    
    this.dataSource.loadtables(
      this.search_condition.value,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    if (this.dataSource.data == undefined) {
      this.dataSource.data = [];
    }
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.notifyService.showWarning("选中记录数:0", "清除选中")
      return;
    }

    this.selection.select(...this.dataSource.data);
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "全部选中")
  }


  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  changedRows(event) {
    let trec_num = this.selection.selected.length;
    this.notifyService.showInfo("记录数：" + trec_num, "选择记录")
  }
  search() {
    this.paginator.pageIndex = 0
    this.loadTablePage()
  }
  delete_selected() {
    let selected = this.selection.selected
    if (selected.length < 1) {
      this.notifyService.showWarning('尚未选择任何数据！', '警告')
      return
    }
    const dialogRef = this.dialog.open(BaseDialogComponent, {
      width: '300px',
      data: {
        content: '是否删除已选中的术语信息?'
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        this.termImportService.delete_selected(this.selection.selected)
          .subscribe(res => {
            if (res.flag) {
              this.notifyService.showSuccess('删除选中术信息成功', '成功')
              this.loadTablePage()
            } else {
              this.notifyService.showError(res.value, '删除失败')
            }
          })
      }
    })

  }

  delete_item(row) {
    const dialogRef = this.dialog.open(BaseDialogComponent, {
      width: '300px',
      data: {
        content: '是否删除已选中的术语信息?'
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        this.termImportService.delete_selected([row])
          .subscribe(res => {
            if (res.flag) {
              this.notifyService.showSuccess('删除术语信息成功', '成功')
              this.loadTablePage()
            } else {
              this.notifyService.showError(res.value, '删除失败')
            }
          })
      }
    })

  }

  add_term_set() {
    const dialogRef = this.dialog.open(TermSetPopComponent, {
      width: '90%',
      height: 'auto',
      data:this.search_condition.value
    })
    dialogRef.afterClosed().subscribe(res => {
      
      if (res) {
        this.loadTablePage()
      }
    })
  }
  edit_term_set(row, edit_flag) {
    this.termImportService.get_term_set(row).subscribe(res=>{
      
      if(res.flag){
        const dialogRef = this.dialog.open(TermSetPopComponent, {
          width: '90%',
          height: 'auto',
          data: {
            data: res.value,
            edit_flag: edit_flag
          }
        })
        dialogRef.afterClosed().subscribe(res => {
          
          if (res) {
            this.loadTablePage()
          }
        })
      }else{
        this.notifyService.showError(res.value,'错误')
      } 
    })
    
  }

  import(){
    let selected_terms = this.selection.selected
    
    let text  = selected_terms.map(t=>t.content).reduce((acc,value)=>acc+value+'\n','')
    this.matDialogRef.close(text)
  }

}
