import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AskCheckService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }
  sch_ask_check_list(start_date,end_date,group_unit_name,section_code,q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number,recheck_flag): Observable<any> {
    let params = new HttpParams()
    .set('section_code',section_code)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page))
      .set('physical_type',sessionStorage.getItem('physical_type'))
      .set('recheck_flag',recheck_flag)
      .set('group_unit_name',group_unit_name)
      .set('start_date',start_date)
      .set('end_date',end_date)


    return this.http.get<any>(`${environment.apiUrl}/content/sch_ask_check_list/`, { params })
      .pipe(
        map(res => {
          
          return res
        }),
        catchError(handleError)
      )
  }
  
  search_ask_check_group_and_order_group_by_id(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/search_ask_check_group_and_order_group_by_id/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        
        return res
      }), catchError(handleError)
    )
  }
  confirm_arrival(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/confirm_arrival/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  generate_person_navigation_sheet(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_sheet/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  generate_person_navigation_bar_code(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/generate_person_navigation_bar_code/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  

  item_search_all_data(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/item_search_all_data/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  update_ask_check_group(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/update_ask_check_group/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }
  
  search_combo_others_for_person(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/search_combo_others_for_person/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }

  get_last_ask_check_group_records(input_data){
    return this.http.post<any>(`${environment.apiUrl}/content/get_last_ask_check_group_records/`, input_data, this.httpOptions)
    .pipe(
      map(res => {
        return res
      }), catchError(handleError)
    )
  }


}
