
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HazardAddMultiple2Component } from 'src/app/dialog-models/hazard-add-multiple2/hazard-add-multiple2.component';
import { PortfolioProjectImportComponent } from 'src/app/dialog-models/portfolio-project-import/portfolio-project-import.component';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { CheckPersonListService } from './checkPersonList.service';
import * as pinyin from 'tiny-pinyin';
import * as XLSX from 'xlsx';
import { DialogOneInputComponent } from 'src/app/dialog-models/dialog-one-input/dialog-one-input.component';
import { NzMarks } from 'ng-zorro-antd/slider';

@Component({
  selector: 'app-checkPersonList',
  templateUrl: './checkPersonList.component.html',
  styleUrls: ['./checkPersonList.component.css'],
  providers: [DatePipe]
})
export class CheckPersonListComponent implements OnInit {
  work_state_list = []
  work_type_list: Observable<any[]>
  startDate: Date;
  endDate: Date;
  unit_list: Observable<any[]>
  his_dept_list = []
  search_form: FormGroup
  type_list = []
  filteredOptions = [];
  session_physical_type = sessionStorage.getItem('physical_type')
  hazard_search_type = '1'
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  work_type_control = this.fb.control('')
  recheck_person_count = ''
  dataSource = new MatTableDataSource([])
  displayedColumns = this.session_physical_type == '职业体检' ? ['group_unit_name', 'order_id', 'test_num', 'name', 'id_card', 'gender', 'age', 'is_marry', 'mobile',
    'jc_type', 'hazard_factors', 'work_state', 'work_type', 'department', 'work_num', 'register_date'] : ['group_unit_name', 'order_id', 'test_num', 'name', 'id_card', 'gender', 'age', 'is_marry', 'mobile', 'register_date']
  loading = false
  min_age: any
  max_age: any
  exam_conclusion_list = []

  constructor(
    private dialog: MatDialog,
    private notifyService: NotificationService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private checkPersonListService: CheckPersonListService,
    private datePipe: DatePipe,

  ) {
    this.get_work_state_list()
    this.get_type_list()
    this.get_his_dept_list()

    const today = new Date()
    this.startDate = new Date(today)
    this.endDate = new Date(today)
    this.startDate.setMonth(today.getMonth() - 1);
    this.endDate.setDate(today.getDate())
    this.startDate.setHours(0, 0, 0, 0)
    this.endDate.setHours(23, 59, 59, 999)
  }

  ngOnInit(): void {
    this.min_age = this.fb.control(0)
    this.max_age = this.fb.control(100)
    this.search_form = this.fb.group({
      group_unit_id: '',
      order_type: '',
      jc_type: '',
      is_recheck: '0',
      physical_type:this.session_physical_type,
      hazard_factor_types: [],
      hazard_factors: this.fb.array([]),
      work_types: this.fb.array([]),
      work_state_codes: [],
      is_pass: [],
      person_gender: '',
      age_range: this.fb.control([0, 100]),
      person_name: '',
      id_card: '',
      test_num: '',
      order_id: '',
      production_cell: '',
      start_date: this.startDate,
      end_date: this.endDate,
    })
    this.work_type_list = this.work_type_control.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.title
          return this._filter(title)
        }),
      );
    this.unit_list = this.search_form.get('group_unit_id').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const title = typeof value === 'string' ? value : value?.name
          return this._filter_e(title)
        }),
      );
    this.min_age.valueChanges.subscribe(value => {
      
      let age_range = this.search_form.get('age_range').value
      if (age_range && value && age_range[0] != value) {
        age_range[0] = value
        this.search_form.get('age_range').setValue(age_range)
      }
    })
    this.max_age.valueChanges.subscribe(value => {
      
      let age_range = this.search_form.get('age_range').value
      if (age_range && value && age_range[1] != value) {
        age_range[1] = value
        this.search_form.get('age_range').setValue(age_range)
      }
    })
    this.search_form.get('age_range').valueChanges.subscribe(value => {
      let age_range = this.search_form.get('age_range').value
      if (age_range) {
        this.min_age.setValue(age_range[0])
        this.max_age.setValue(age_range[1])
      }
    })
    this.search_form.get('is_recheck').valueChanges.subscribe(value => {
      if (value == 1) {
        this.displayedColumns = ['group_unit_name', 'order_id', 'test_num', 'last_test_num','name', 'id_card', 'gender', 'age', 'is_marry', 'mobile',
        'jc_type', 'hazard_factors', 'work_state', 'work_type', 'department', 'work_num', 'register_date']
      } else {
        this.displayedColumns =['group_unit_name', 'order_id', 'test_num','name', 'id_card', 'gender', 'age', 'is_marry', 'mobile',
        'jc_type', 'hazard_factors', 'work_state', 'work_type', 'department', 'work_num', 'register_date']
      }
      this.dataSource = new MatTableDataSource([])
    })
  }
  get_work_state_list() {
    this.commonService.get_dict_data_by_type('workState')
      .subscribe(res => {
        if (res.flag) {
          
          this.work_state_list = res.value
        } else {
          this.notifyService.showError(res.value, '错误')
        }
      })
  }

  get hazard_factors() {
    return this.search_form.get('hazard_factors') as FormArray
  }

  get work_types() {
    return this.search_form.get('work_types') as FormArray
  }
  get check_items() {
    return this.search_form.get('check_items') as FormArray
  }
  hazard_factors_add() {
    let dialogRef = this.dialog.open(HazardAddMultiple2Component, {
      width: '80%',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let hazard_factors_exist = this.search_form.get('hazard_factors').value
        
        let pro_types = res.type_name
        
        pro_types.forEach(type => {
          if (!this.hazard_factors.controls.find(control => control.get('id').value == type.id)) {
            this.hazard_factors.push(this.fb.group({ ...type }))
          }
        })
      }
      
    })

  }

  delete_hazard_factor(j) {
    this.hazard_factors.removeAt(j)
  }

  displayFn(option: any): string {
    
    return option && option.title ? option.title : ''
  }

  private _filter(title: any): any[] {
    let tl_new = []
    this.commonService.get_work_type_list(title).subscribe(res => {
      if (res.flag) {
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }
  type_select(event) {
    
    this.commonService.get_t_pro_type_by_tree_and_condition(event.value, '')
      .subscribe(data => {
        if (data.flag) {
          this.filteredOptions = data.value
        } else {
          this.notifyService.showError(data.value, '失败')
        }
      })
  }
  get_type_list() {

    this.commonService.get_t_pro_type_by_tree().subscribe(res => {

      if (res.flag) {
        this.type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  hazard_search_type_change(event) {
    let value = event.value
    this.hazard_search_type = event.value
    
    if (value == 1) {
      this.hazard_factors.clear()
    } else if (value == 2) {
      this.search_form.get('hazard_factor_types').setValue('')
    }
  }

  add_2(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (input) {
      input.value = '';
    }
  }
  add_effect(event) {
    let value = event.source.value
    const input = event.isUserInput
    if (!this.work_types.controls.find(f => f.get('value').value == value.value)) {
      this.work_types.push(this.fb.group({
        ...value
      }))
    }
    if (input) {
      event.source.value = '';
    }
    
  }

  remove_form_list_items(index: number) {
    this.work_types.removeAt(index)
  }
  private _filter_e(title: any): any[] {
    let tl_new = []
    this.commonService.get_group_unit_List_by_part(title).subscribe(res => {
      
      if (res.flag) {
        
        res.value.forEach(va => {
          tl_new.push(va)
        })
      } else {
        this.notifyService.showError(res.value, '失败')
      }
    })
    return tl_new
  }

  displayFn_e(option: any): string {
    return option && option.name ? option.name : ''
  }
  get_his_dept_list() {
    this.commonService.get_his_dept_List().subscribe(res => {
      if (res.flag) {
        this.his_dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息' + res.value, '错误')
      }
    })
  }
  select_all(event) {
    
    
    
    let selected = event.source.selected
    if (selected == true) {
      this.search_form.get('office_ids').setValue('')
    }
  }

  office_id_select(event) {
    
    let ids = event.value
    if (Array.isArray(ids)) {
      this.search_form.get('office_ids').setValue(ids.filter(f => f))
    }
  }
  recheck_item_add() {
    let dialogRef = this.dialog.open(PortfolioProjectImportComponent, {
      width: '90%',
      height: '90%',
      data: {
        title: '人员体检清单',
        subtitle: '体检项目导入'
      }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        
        res.forEach(r => {
          
          if (!this.check_items.controls.find(f => f.get('id').value == r.id)) {
            this.check_items.push(this.fb.group({ ...r }))
          }
        })
      }
    })
  }
  delete_recheck_item(i) {
    this.check_items.removeAt(i)
  }
  search() {
    if (this.loading == false) {
      
      this.loading = true
      const endDate = this.search_form.get('end_date').value;
      endDate.setHours(23, 59, 59, 999);
      let input_data = {
        'start_date': this.datePipe.transform(this.search_form.get('start_date').value, 'yyyy-MM-dd HH:mm:ss'),
        'end_date': this.datePipe.transform(endDate, 'yyyy-MM-dd HH:mm:ss'),
        'physical_type': this.search_form.get('physical_type').value,
        'jc_type': this.search_form.get('jc_type').value,
        'is_recheck': this.search_form.get('is_recheck').value,
        'is_pass': this.search_form.get('is_pass').value,
        'order_type': this.search_form.get('order_type').value,
        'person_name': this.search_form.get('person_name').value,
        'id_card': this.search_form.get('id_card').value,
        'person_gender': this.search_form.get('person_gender').value,
        'work_state_codes': this.search_form.get('work_state_codes').value,
        'work_types': this.search_form.get('work_types').value.map(m => m.value),
        'test_num': this.search_form.get('test_num').value,
        'group_unit_id': this.search_form.get('group_unit_id').value ? this.search_form.get('group_unit_id').value.id : '',
        'hazard_factors': this.search_form.get('hazard_factors').value.map(m => m.type_code),
        'hazard_factor_types': this.search_form.get('hazard_factor_types').value,
        'age_range': this.search_form.get('age_range').value,
        'order_id': this.search_form.get('order_id').value,
        'production_cell': this.search_form.get('production_cell').value
      }
      this.checkPersonListService.post_checkPersonList_statistics(input_data).subscribe(res => {
        
        this.notifyService.message_alert(res, '', '')
        if (res.flag) {
          
          this.dataSource = new MatTableDataSource(res.value.recheck_list)
          this.recheck_person_count = res.value.recheck_person_count
        }
        this.loading = false
      })
    }

  }
  sortChange(event): void {
    

    let active = event.active;
    let sort = event.direction;

    if (sort && this.dataSource) {
      if (sort === 'asc') {
        this.dataSource.data.sort((a, b) => {
          const valueA = a[active] + '';
          const valueB = b[active] + '';
          const pinyinA = pinyin.convertToPinyin(valueA, '', true);
          const pinyinB = pinyin.convertToPinyin(valueB, '', true);
          return pinyinA !== undefined && pinyinB !== undefined ? pinyinA.localeCompare(pinyinB) : 0;
        });
      } else if (sort === 'desc') {
        this.dataSource.data.sort((a, b) => {
          const valueA = a[active] + '';
          const valueB = b[active] + '';
          const pinyinA = pinyin.convertToPinyin(valueA, '', true);
          const pinyinB = pinyin.convertToPinyin(valueB, '', true);
          return pinyinA !== undefined && pinyinB !== undefined ? pinyinB.localeCompare(pinyinA) : 0;
        });
      }
    }
    this.dataSource = new MatTableDataSource(this.dataSource.data)
  }
  replay() {
    window.location.reload();
  }
  export_excel() {
    const dialog_input = this.dialog.open(DialogOneInputComponent, {
      width: '400px',
      data: {
        title: '人员体检清单',
        name: '人员体检清单'
      }
    })
    dialog_input.afterClosed().subscribe(res => {
      
      if (res) {
        let title = res
        let header_model_temp = ['序号', '所属单位', '订单号', '订单类型', '体检号', '姓名', '身份证号', '性别', '年龄', '结婚状况', '联系电话', '监测类型', '危害因素', '在岗状态', '工种名称', '工作部门', '人员工号', '生产单元（厂区）', '体检登记时间']
        if (this.search_form.get('is_recheck').value == 1) {
          header_model_temp = ['序号', '所属单位', '订单号',  '订单类型', '体检号', '上次体检号', '姓名', '身份证号', '性别', '年龄', '结婚状况', '联系电话', '监测类型', '危害因素', '在岗状态', '工种名称', '工作部门', '人员工号', '生产单元（厂区）', '体检登记时间']
        }
        if (this.search_form.get('physical_type').value == '健康体检') {
          header_model_temp = ['序号', '所属单位', '订单号', '订单类型', '体检号', '姓名', '身份证号', '性别', '年龄', '结婚状况', '联系电话', '体检登记时间', '套餐']

        }
        let excel_data = []
        excel_data.push(header_model_temp)
        this.dataSource.data.forEach((element, index) => {
          let gender = element.gender == '1' ? '男' : '女'
          let is_marry = element.is_marry
          if (is_marry === 0) {
            is_marry = '未婚'
          } else if (is_marry == 1) {
            is_marry = '已婚'
          } else if (is_marry == 2) {
            is_marry = '离异'
          } else if (is_marry == 3) {
            is_marry = '丧偶'
          } else if (is_marry == 4) {
            is_marry = '其他'
          }else{
            is_marry = ''
          }
          let jc_type = element.jc_type
          if (element.jc_type == '1') {
            jc_type = "常规监测"
          } else if (element.jc_type == '2') {
            jc_type = "主动监测"
          }
          let register_date = this.datePipe.transform(element.register_date, 'yyyy-MM-dd HH:mm:ss')
          if (this.search_form.get('physical_type').value == '健康体检') {
            excel_data.push([index + 1, element.group_unit_name, element.order_id, element.order_type, element.test_num, element.name, element.id_card, gender, element.age,
              is_marry, element.mobile, register_date, element.meal])
          } else {
            if (this.search_form.get('is_recheck').value == 1) {
              excel_data.push([index + 1, element.group_unit_name, element.order_id, element.order_type, element.test_num, element.last_test_num, element.name, element.id_card, gender, element.age,
                is_marry, element.mobile, jc_type,
              element.hazard_factors, element.work_state, element.work_type,
              element.department, element.work_num, element.production_cell, register_date])
            } else {
              excel_data.push([index + 1, element.group_unit_name, element.order_id,element.order_type, element.test_num, element.name, element.id_card, gender, element.age,
                is_marry, element.mobile, jc_type,
              element.hazard_factors, element.work_state, element.work_type,
              element.department, element.work_num, element.production_cell, register_date])
            }
          }
        });
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excel_data);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, title + '.xlsx');
        this.notifyService.showSuccess('导出成功，请等待下载完成！', '成功')
      }
    })
  }
  marks: NzMarks = {
    0: '0',
    18: '18',
    40: '40',
    50: '50',
    60: {
      style: {
        color: '#f50'
      },
      label: '<strong>60</strong>'
    }
  }
}

