<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    
    <mat-form-field appearance="fill" style="width: 24%;">
        <mat-label>打印状态</mat-label>
        <mat-select [(ngModel)]="print_flag" (selectionChange)="print_flag_change($event)">
            <mat-option value="">全部</mat-option>
            <mat-option value="0">待打印</mat-option>
            <mat-option value="1">已打印</mat-option>
            <!-- <mat-option value="-1">打印已过期</mat-option> -->
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 24%;">
        <mat-label>体检日期</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" >
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>
      <app-group-unit-selecter #group_unit_selecter [parent]="this" style="width: 49%;"></app-group-unit-selecter>
    <mat-form-field appearance="fill" style="width: 24%;">
        <mat-label>搜索字段</mat-label>
        <mat-select [(ngModel)]="input_cate">
            <mat-option value="test_num">体检编号</mat-option>
            <mat-option value="person_name">姓名</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" style="width: 24%;">
        <mat-label>请输入查询内容..</mat-label>
        <input matInput [(ngModel)]="input_text"  (keyup.enter)="search()"/>
    </mat-form-field>
    <button mat-stroked-button color="primary" (click)="search()"><mat-icon>search</mat-icon>搜索</button>
    <button mat-stroked-button color="warn" (click)="replay()" matTooltip="重置搜索项"><mat-icon>replay</mat-icon>重置</button>
    <button mat-stroked-button color="primary" [disabled]="print_button_status"  (click)="generate_total_check_sheet_list()"><mat-icon>print</mat-icon>{{print_button_text}}</button>

</div>
<div *ngIf="print_loading">
    <mat-spinner></mat-spinner>
</div>
<br>
<div class="spinner-container" *ngIf="dataSource.loading$ | async">
    <mat-spinner></mat-spinner>
</div>
<mat-table class="drug-insert-table mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null;changedRows($event)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [disabled]="row.print_flag==-1">
            </mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="test_num">
        <mat-header-cell *matHeaderCellDef><b>体检编号</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.test_num}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="person_name">
        <mat-header-cell *matHeaderCellDef><b>姓名</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.anonymity==1?(row.person_name|nameHide):row.person_name}}<span style="color:blue">{{row.is_recheck==1?'（复检）':''}}</span></mat-cell>
    </ng-container>
    <ng-container matColumnDef="sex">
        <mat-header-cell *matHeaderCellDef><b>性别</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.sex|sexStates}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="age">
        <mat-header-cell *matHeaderCellDef><b>年龄</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.age}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="physical_type">
        <mat-header-cell *matHeaderCellDef><b>体检类型</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.physical_type}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="dept">
        <mat-header-cell *matHeaderCellDef><b>单位名称</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.dept}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="check_date">
        <mat-header-cell *matHeaderCellDef><b>体检日期</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.check_date|date:"yyyy-MM-dd"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="regist_date">
        <mat-header-cell *matHeaderCellDef><b>登记时间</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.regist_date|date:"yyyy-MM-dd HH:mm:ss"}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="print_flag">
        <mat-header-cell *matHeaderCellDef><b>打印状态</b></mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let row">{{row.print_flag|printStatus}}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="parent.onRowClicked(row)"></mat-row>
</mat-table>
<mat-paginator [length]="dataSource.length" [pageSize]="15" [pageSizeOptions]="[15,30, 60]" showFirstLastButtons>
</mat-paginator>