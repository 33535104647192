import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/common.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { TermSetService } from '../../term-set/term-set.service';

@Component({
  selector: 'app-term-set-pop',
  templateUrl: './term-set-pop.component.html',
  styleUrls: ['./term-set-pop.component.css'],
  providers: [DatePipe]
})
export class TermSetPopComponent implements OnInit {
  term_set: FormGroup
  edit_flag: boolean = false
  submit_disabled: boolean = false
  dept_list = []
  his_dept_list = []

  unitPriority = []
  inspect_type_list = []
  type_list = []
  hazard_type_list = []
  hazard_factors_list = []
  work_state_text_list = []
  zy_flag = false
  filteredOptions: Observable<any[]>;
  title = '--新增术语信息--'
  not_qtjbhycjg=true //术语类型不是：其他疾病或异常标记

  constructor(
    public dialogRef: MatDialogRef<TermSetPopComponent>,
    private fb: FormBuilder,
    private termSetService: TermSetService,
    private notifyService: NotificationService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
  ) {
    this.get_dept_list()
    this.get_his_dept_list()
    this.get_inspect_type_list()
    this.get_type_list()
    this.get_work_state_text_list()
    this.get_hazard_type_list()
  }

  ngOnInit(): void {

    

    if (this.dialog_data) {
      if (this.dialog_data.data) {  //id 有值，修改术语操作
        let data = this.dialog_data.data
        this.term_set = this.fb.group(
          {
            id: data.id,
            office_id: data.office_id,
            inspect_type: [],
            type: data.type,
            hazard_type: data.hazard_type,
            hazard_factors: data.hazard_factors,
            work_state: [],
            status: data.status,
            content: data.content
          }
        )
        this.term_set.get('inspect_type').setValue(data.inspect_type)
        if (data.inspect_type.includes('职业体检')) {
          this.zy_flag = true
        }
        this.edit_flag = this.dialog_data.edit_flag
        if (!this.edit_flag) {
          this.term_set.disable()
          this.submit_disabled = true
          this.title = '--查看术语信息--'
        } else {
          this.title = '--修改术语信息--'
        }
      } else { //id 没有值 ，导入术语界面，新增术语功能；
        let data = this.dialog_data
        this.term_set = this.fb.group({
          id: '',
          office_id: data.office_id,
          inspect_type: [],
          type: data.type,
          hazard_type: [],
          hazard_factors: data.hazard_factors,
          work_state: [],
          status: '0',  //默认 启用
          content: ''
        })
        this.term_set.get('inspect_type').setValue([data.inspect_type])
        if (data.inspect_type.includes('职业体检')) {
          this.zy_flag = true
        }
        this.title = '--新增术语信息--'
        //更新危害因素
        if (data.type !='qtjbhycjg'){
          this.get_hazard_type_by_hazard_factors()
        }else{
          this.not_qtjbhycjg = false
        }
      }
    } else {
      this.term_set = this.fb.group({
        id: '',
        office_id: '',
        inspect_type: '',
        type: '',
        hazard_type: '',
        hazard_factors: '',
        work_state: '',
        status: '0',
        content: ''
      })
    }
    this.filteredOptions = this.term_set.get('hazard_factors').valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name;
          return this._filter(name);
        }),
      );
  }

  submit() {
    this.dialogRef.close(true)
    let term = this.term_set.value
    
    let save_term_set = {
      id: term.id,
      office_id: term.office_id,
      inspect_type: term.inspect_type,
      type: term.type,
      hazard_factors: term.hazard_factors ? term.hazard_factors.type_code : '',
      hazard_factors_text: term.hazard_factors ? term.hazard_factors.type_name : '',
      work_state_code: term.work_state ? term.work_state.map(m => m.value) : '',
      work_state_text: term.work_state ? term.work_state.map(m => m.title) : '',
      status: term.status,
      content: term.content
    }
    

    if (this.edit_flag) {
      this.termSetService.edit_term_set(save_term_set)
        .subscribe(res => {
          if (res.flag) {
            this.notifyService.showSuccess('修改术语信息成功', '修改成功')
            this.dialogRef.close(true)
          } else {
            this.notifyService.showError("修改术语信息失败  " + res.value, '失败')

          }
        })
    } else {
      this.termSetService.insert_term_set(save_term_set)
        .subscribe(res => {
          if (res.flag) {
            this.notifyService.showSuccess('新增术语信息成功', '新增成功')
            this.dialogRef.close(true)
          } else {
            this.notifyService.showError("新增术语信息失败  " + res.value, '失败')

          }
        })

    }

  }
  get_dept_list() {
    this.commonService.get_dept_List().subscribe(res => {
      if (res.flag) {
        this.dept_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  displayFn(option: any): string {
    return option && option.type_name ? option.type_name : '';
  }
  type_select() {
    this.term_set.get('hazard_factors').setValue('')
  }
  //体检类型
  get_inspect_type_list() {
    this.commonService.get_dict_data_by_type('checkType').subscribe(res => {
      if (res.flag) {
        this.inspect_type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  //术语类型
  get_type_list() {
    this.commonService.get_dict_data_by_type('office_term_type').subscribe(res => {
      if (res.flag) {
        this.type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }

  //在岗状态
  get_work_state_text_list() {
    this.commonService.get_dict_data_by_type('workState').subscribe(res => {
      if (res.flag) {
        this.work_state_text_list = res.value
        if (this.dialog_data) {
          if (this.dialog_data.data){ //术语信息维护界面中的修改和查看使用
              let data = this.dialog_data.data
              let data_work_state_id = data.work_state.map(m => m.id)
              this.term_set.get('work_state').setValue(this.work_state_text_list.filter(m => data_work_state_id.includes(m.id)).map(m => m))
          }else{ //总检分检中，新增术语时使用；
            if(this.dialog_data.type != 'qtjbhycjg'){
              this.term_set.get('work_state').setValue(this.work_state_text_list.filter(m=>this.dialog_data.work_state_text==m.title).map(m=>m))
            }
          }
          
        }
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  private _filter(value: any): any[] {

    const filterValue = value
    let tl_new: any[] = [];

    this.commonService.get_t_pro_type_by_tree_and_condition(this.term_set.value.hazard_type, filterValue)
      .subscribe(data => {
        if (data.flag) {
          data.value.forEach(value => {
            tl_new.push(value)
          });
          return tl_new
        } else {
          this.notifyService.showError(data.value, '失败')
        }
      })
    return tl_new
  }

  get_hazard_type_list() {

    this.commonService.get_t_pro_type_by_tree().subscribe(res => {

      if (res.flag) {
        this.hazard_type_list = res.value
      } else {
        this.notifyService.showError(res.value, '错误')
      }
    })
  }
  inspect_type_change(event) {
    let inspect_type_value = event.value
    if (inspect_type_value.includes('职业体检')) {

      this.zy_flag = true
    } else {
      this.term_set.get('hazard_type').setValue('')
      this.term_set.get('hazard_factors').reset()
      this.zy_flag = false

    }
  }

  type_change(event) {
    let type_value = event.value
    if (type_value=='qtjbhycjg') {
      this.not_qtjbhycjg = false
    } else {
      this.term_set.get('hazard_type').setValue('')
      this.term_set.get('hazard_factors').reset()
      this.not_qtjbhycjg = true

    }
  }

  get_hazard_type_by_hazard_factors(){
    if (this.dialog_data.hazard_factors){
      let input_data = {
        'hazard_factors':this.dialog_data.hazard_factors
      }
      this.termSetService.get_hazard_type_by_hazard_factors(input_data).subscribe(res=>{
        
        if(res.flag){
          this.term_set.get('hazard_type').setValue(res.value.hazard_type)
          this.term_set.get('hazard_factors').reset(res.value.hazard_factors)
        }else{
          this.notifyService.showError(res.value,'错误')
        }
      })
    }
    
  }
  get_his_dept_list() {
    this.commonService.get_his_dept_List().subscribe(res => {
      if (res.flag) {
        this.his_dept_list = res.value
      } else {
        this.notifyService.showError('查询科室信息' + res.value, '错误')
      }
    })
  }

}
