// auth.directive.ts
import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../data-services/auth.service';

@Directive({
  selector: '[appAuth]'
})
// 实现 OnChanges 接口
export class AuthDirective implements OnChanges {
  // 输入属性传值,获取有权限的角色
  @Input('appAuth') roles: string[] = [];
  hasView = false;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userServe: AuthService
  ) { }
  // 在 ngOnChanges 阶段才能拿到输入属性的传值
  ngOnChanges(changes: SimpleChanges): void {
    if (this.roles.length) {
      this.userServe.currentUser.subscribe(res => {
        // 没匹配到角色
        if (this.haveCommonElements(this.roles, res.granted_roles?res.granted_roles.split(','):'')) {
          this.createView();
        } else {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
    } else {
      this.createView();
    }
  }
  // 创建视图
  private createView(): void {
    // 检查是否已经创建了视图
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
  }
  //判断是否存在交集
  haveCommonElements(arr1, arr2): boolean {
    return arr1.some(value => arr2.includes(value));
  }
}
