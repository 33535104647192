export class User {
    access_token: string = "";
    token_type: string = "";
    seq_kd99: string = "";
    hsp_code: string = "";
    opcode: string = "";
    opname?: string;
    dept_code?: string;
    email?: string;
    tel?: string;
    img?: string;
    granted_roles?: string;
    constructor() {
        this.access_token = "";
        this.token_type = "";
        this.seq_kd99 = "";
        this.hsp_code = "";
        this.opcode = "";
        this.opname = "";
        this.dept_code = "";
        this.email = "";
        this.tel = "";
        this.img = "";
        this.granted_roles = "";
    }
}
