import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uploadStatus'
})
export class UploadStatusPipe implements PipeTransform {

  transform(value:number): unknown {
    //#0:生成文件错误，1：尚未上传，2：正在上传 ,3：上传成功，4：上传失败
    let result = ''
    if(value==0){
      result = '文件错误'
    }else if (value==1){
      result = '等待上传'
    }else if (value==2){
      result = '正在上传'
    }else if (value==3){
      result = '上传成功'
    }else if(value==4){
      result = '上传失败'
    }else{
      result = '未知错误'
    }
    return result;
  }

}
