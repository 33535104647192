import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idcardHide'
})
export class IdcardHidePipe implements PipeTransform {

  transform(value:string): string {
    return value.substr(0,3)+'*******'+value.substr(-4);
  }

}
