import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ShareService } from './share-service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
     private router: Router,
     private shareService:ShareService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    let theaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    });
    let ts = 'grant_type=&username=' + username + '&password=' + password + '&scope=&client_id=&client_secret=';
    return this.http.post<any>(`${environment.apiUrl}/token/`, ts, { headers: theaders })
      .pipe(map(user => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    let user = new User();
    this.currentUserSubject.next(user);
    this.shareService.data.next(true)
    this.router.navigate(['/login'])
  }
  Verify(username: string, password: string) {
    let theaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    });
    if (password==''){
      password=null
    }
    let ts = 'grant_type=&username=' + username + '&password=' + password + '&scope=&client_id=&client_secret=';
    return this.http.post<any>(`${environment.apiUrl}/passVerify/`, ts, { headers: theaders })
      .pipe(user => {
        return user;
      });
  }
  saveChange(username: string, password: string) {
    let theaders = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    });
    if (password==''){
      password=null
    }
    let ts = 'grant_type=&username=' + username + '&password=' + password + '&scope=&client_id=&client_secret=';
    return this.http.post<any>(`${environment.apiUrl}/saveUserMessage/`, ts, { headers: theaders })
      .pipe(user => {
        return user;
      });
  }
}
