import { AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { Component, ComponentFactoryResolver, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from '../data-services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('scroll_target')
  scroll_target!: ElementRef;
  page:string
  constructor(private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    public authService:AuthService) { }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

  }
  async nav_page(tpage: string) {
    this.page = tpage
    switch (tpage) {
      case "basic": { // 体检信息管理
        this.viewContainerRef.clear();
        const { BasicComponent } = await import('./basic/basic.component');
        this.viewContainerRef.createComponent(
          this.cfr.resolveComponentFactory(BasicComponent)
        );
        this.scroll_target.nativeElement.scrollIntoView();
        break;
      }
      case "cost": { //体检费用管理
        this.viewContainerRef.clear();
        const { CostComponent } = await import('./cost/cost.component');
        this.viewContainerRef.createComponent(
          this.cfr.resolveComponentFactory(CostComponent)
        );
        this.scroll_target.nativeElement.scrollIntoView();
        break;
      } 
      case "content": { //体检中心
        this.viewContainerRef.clear();
        const { ContentComponent } = await import('./content/content.component');
        this.viewContainerRef.createComponent(
          this.cfr.resolveComponentFactory(ContentComponent)
        );
        this.scroll_target.nativeElement.scrollIntoView();
        break;
      }     
      case "sysset": { //系统设置  sysset
        this.viewContainerRef.clear();
        const { SyssetComponent } = await import('./sysset/sysset.component');
        this.viewContainerRef.createComponent(
          this.cfr.resolveComponentFactory(SyssetComponent)
        );
        this.scroll_target.nativeElement.scrollIntoView();
        break;
      } case "statistics": { //查询与统计
        this.viewContainerRef.clear();
        const { StatisticsComponent } = await import('./statistics/statistics.component');
        this.viewContainerRef.createComponent(
          this.cfr.resolveComponentFactory(StatisticsComponent)
        );
        this.scroll_target.nativeElement.scrollIntoView();
        break;
      }
      default: {
        //statements; 
        break;
      }

    }
  };

}
