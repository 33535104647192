import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-test-doc',
  templateUrl: './test-doc.component.html',
  styleUrls: ['./test-doc.component.css']
})
export class TestDocComponent implements OnInit {

  question:FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.question = this.fb.group({
      question_list:this.fb.array([
      ])})
  }

  get compoistion() { 
    return this.question.get('question_list') as FormArray;
  }

  remove_form_list_items(titems: FormArray, index: number) {
    titems.removeAt(index);
  }

  add_form_list_items(titems: FormArray) {
    titems.push(this.fb.group({
      message:"",
      done_message:'',
    }));
  }
}
