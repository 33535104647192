import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { handleError } from 'src/app/data-services/comm-func';
import { environment } from 'src/environments/environment';

import { timer, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DiagSetService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }
  // 查询诊断术语列表
  sch_diag_set_list(office_id, q_condition: string, q_value: string, tpage_nmb: number, tn_per_page: number): Observable<any> {
    let params = new HttpParams()
      .set('office_id', office_id)
      .set('q_condition', q_condition)
      .set('q_value', q_value)
      .set('tpage_nmb', String(tpage_nmb))
      .set('tn_per_page', String(tn_per_page));

    return this.http.get<any>(`${environment.apiUrl}/sysset/sch_diag_set_list/`, { params })
      .pipe(
        map(res => {

          return res
        }),
        catchError(handleError)
      )
  }

  //20240625增加总检导入诊断就出建议
  sch_diag_set_list_find_final_suggest_based_on_anomalies(q_value: string[]): Observable<any> {
    // let params = new HttpParams()
    //   .set('q_value', q_value)

    let params = new HttpParams();
    const qValueStr = q_value.join(',');
    params = params.set('q_values', qValueStr);
    // console.log('q_value++++++++++++++++++++++++', q_value)

    return this.http.get<any>(`${environment.apiUrl}/sysset/sch_diag_set_list_find_final_suggest_based_on_anomalies/`, { params })
      .pipe(
        map(res => {

          // let tjson = JSON.parse(String(res)) //20240529修改转化语句--测试
          // let tjson = JSON.parse(JSON.stringify(res))
          // console.log('sch_diag_set_list_find_final_suggest_based_on_anomalies res=', tjson);

          return res
        }),
        catchError(handleError)
      )
  }

  //20240828增加直接出汇总建议
  sch_diag_set_list_find_final_suggest_based_on_conclusion(q_value: string[]): Observable<any> {

    let params = new HttpParams();
    // const qValueStr = q_value.join(',');
    // http传输过程中会将+号还有几个其他的默认转换成空字符串，所以在这里将+替换加号为相应的编码值'%2B'
    const qValueStr = q_value.map(item => item.replace(/\+/g, '%2B')).join(';');
    params = params.set('q_values', qValueStr);

    return this.http.get<any>(`${environment.apiUrl}/sysset/sch_diag_set_list_find_final_suggest_based_on_conclusion/`, { params })
      .pipe(
        map(res => {
          return res
        }),
        catchError(handleError)
      )
  }

  edit_diag_set(diag_set) {
    let input_data = {
      'diag_set': diag_set
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/edit_diag_set/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  insert_diag_set(diag_set) {
    let input_data = {
      'diag_set': diag_set
    }
    return this.http.post<any>(`${environment.apiUrl}/sysset/insert_diag_set/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
  delete_selected(selected: any) {

    let input_data = {
      "selected": selected
    }

    return this.http.post<any>(`${environment.apiUrl}/sysset/delete_selected_diag_set_list/`, input_data, this.httpOptions)
      .pipe(
        map(res => {
          return res
        }), catchError(handleError)
      )
  }
}
