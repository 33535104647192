<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
    <h1 style="color: blue;"><b>体检登记(职业)</b></h1>
    <h3><b>修改人员信息</b></h3>
</div>
<hr>
<form [formGroup]="tGroupPersonForm" style="width:100%;height:84%;">
    <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>人员姓名</mat-label>
            <input matInput type="text" [maxlength]="50" formControlName="person_name" placeholder="请输入人员姓名" required />
            <mat-error>人员姓名不可为空</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>证件号码</mat-label>
            <input matInput type="text" formControlName="id_card" placeholder="请输入证件号码"  (ngModelChange)="generate_other_by_id_card($event)"  required>
            <mat-error *ngIf="tGroupPersonForm.get('id_card').hasError('required')">证件号码不可为空</mat-error>
            <mat-error *ngIf="tGroupPersonForm.get('id_card').hasError('pattern')||tGroupPersonForm.get('id_card').hasError('dateOfBirthInvalid')||tGroupPersonForm.get('id_card').hasError('idcardInvalid')">证件号码格式错误</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>性别</mat-label>
            <mat-select required formControlName="sex">
                <mat-option value="1">男</mat-option>
                <mat-option value="2">女</mat-option>
            </mat-select>
            <mat-error>性别不可为空，请输入证件号码以获取</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>出生日期</mat-label>
            <input matInput [matDatepicker]="birthPicker" placeholder="请选择" style="width: 100%;"
                formControlName="birth"  required/>
            <mat-datepicker-toggle matSuffix [for]="birthPicker"></mat-datepicker-toggle>
            <mat-datepicker #birthPicker></mat-datepicker>
            <mat-error>出生日期不可为空，请输入证件号码以获取</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>年龄</mat-label>
            <input matInput type="number" formControlName="age" placeholder="请输入年龄" required />
            <mat-error>年龄不可为空，请输入证件号码以获取</mat-error>

        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>结婚状况</mat-label>
            <mat-select formControlName="is_marry" placeholder="请选择">
                <mat-option value=""></mat-option>
                <mat-option value="0">未婚</mat-option>
                <mat-option value="1">已婚</mat-option>
                <mat-option value="2">离异</mat-option>
                <mat-option value="3">丧偶</mat-option>
                <mat-option value="4">其他</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 24%;">
            <mat-label>联系电话</mat-label>
            <input matInput type="text" formControlName="mobile" placeholder="请输入手机号码" required />
            <mat-error *ngIf="tGroupPersonForm.get('mobile').hasError('required')">联系电话不可为空</mat-error>
            <mat-error *ngIf="tGroupPersonForm.get('mobile').hasError('pattern')">联系电话格式错误</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 24%;" *ngIf="session_physical_type=='职业体检'" >
            <mat-label>监测类型</mat-label>
            <mat-select formControlName="jc_type" placeholder="请选择" required >
                <mat-option value="1">常规监测</mat-option>
                <mat-option value="2">主动监测</mat-option>
            </mat-select>
            <mat-error>该项必填</mat-error>
        </mat-form-field>
        <div *ngIf="session_physical_type=='职业体检'" style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
            <mat-form-field style="width:100%" appearance="fill" (dblclick)="hazard_factors_add()">
                <mat-label [ngClass]="{red:hazard_factors_text_list.length<1}">危害因素(双击添加)必填*</mat-label>
                <mat-chip-list >
                    <mat-chip *ngFor="let hazard_factor of hazard_factors_text_list;let j =index" selectable removable
                        (removed)="delete_hazard_factor(j)">
                        {{hazard_factor}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </mat-form-field>
            <mat-card *ngIf="other_hazard_factors_show" style="width: 100%;" >
                <mat-card-header>
                    <mat-card-title>其他危害因素</mat-card-title>
                </mat-card-header>
                <mat-card-content [formGroup]="other_hazard_factors_group">
                        <div formArrayName="others_array"  style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="1%">
                            <mat-form-field *ngFor="let other of others_array.controls;let i = index"  [formGroup]="other" style="width:49%" appearance="fill">
                                <mat-label>{{other.get('other_label').value}}</mat-label>
                                <input type="text" matInput formControlName="other_value">
                                <mat-error>危害因素存在其他时，其他危害因素必填*</mat-error>
                            </mat-form-field>
                        </div>
                </mat-card-content>
            </mat-card>
          
            <mat-form-field appearance="fill" style="width: 49%;">
                <mat-label>工种名称</mat-label>
                <input matInput placeholder="请选择工种名称" formControlName="work_type_text" required [matAutocomplete]="work_type" />
                <mat-autocomplete requireSelection  #work_type="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let w of work_type_list |async" [value]="w">{{w.value}}:{{w.title}}</mat-option>
                </mat-autocomplete>
                <mat-error>请选择工种名称</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 24%;" *ngIf="work_name_show">
                <mat-label>工种其他名称</mat-label>
                <input matInput type="text" formControlName="work_name" placeholder="请输入工种其他名称" />
                <mat-error>工种名称为其他时，该项必填*</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width: 24%;">
                <mat-label>在岗状态</mat-label>
                <mat-select formControlName="work_state" required (selectionChange)="work_state_change($event)">
                    <mat-option *ngFor="let i of work_state_list" [value]="i">{{i.title}}</mat-option>
                </mat-select>
                <mat-error>该项必填*</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 24%;">
                <mat-label>接害开始日期</mat-label>
                <input matInput [matDatepicker]="startDatePicker" placeholder="请选择" style="width: 100%;"
                    formControlName="exposure_start_date"  />
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error  *ngIf="tGroupPersonForm.get('exposure_start_date').hasError('required')">当在岗状态不是“上岗前”时，该项必填*</mat-error>
                <mat-error *ngIf="tGroupPersonForm.get('exposure_start_date').hasError('data_overflow')">开始接害日期不可以大于出生日期</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 24%;">
                <mat-label>生产单元（厂区）</mat-label>
                <input matInput type="text" formControlName="production_cell" > 
            </mat-form-field>
             <mat-form-field appearance="fill" style="width: 24%;">
                <mat-label>体检人员工作部门</mat-label>
                <input matInput type="text" formControlName="department" > 
            </mat-form-field>
            <mat-form-field appearance="fill" style="width: 24%;">
                <mat-label>人员工号</mat-label>
                <input matInput type="text" formControlName="work_num" > 
            </mat-form-field>
            <mat-slide-toggle formControlName="banonymity">信息隐匿</mat-slide-toggle>

            
        </div>
    </div>
    <hr>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="end center" fxLayoutGap="20px"
        style="height: 10%;width: 100% !important;">
        <button mat-raised-button color="warn" [matDialogClose]="false">取消</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="submit_disabled"
            (click)="submit()">提交</button>
    </div>
</form>