import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { concat } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppService } from './data-services/app.service';
import { handleError } from './data-services/comm-func';
import { NotificationService } from './utils/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ot';
  //设置关闭浏览器自动清空缓存-------start ---这种方式针对火狐低版本无效,以及ie无效
  _beforeUnload_time = 0;
  _gap_time = 0;

  //版本号
  version  =   require('../../package.json').version

  constructor(
    private notifyService: NotificationService,
    private http: HttpClient,
    private app_service:AppService,
    private paginatorIntl: MatPaginatorIntl
    ) {
      paginatorIntl.itemsPerPageLabel = '' // 设置表格mat-paginator标签内容
     }

  ngOnInit(): void {
      window.onunload = () => {
          this._gap_time = new Date().getTime() - this._beforeUnload_time;
          if (this._gap_time <= 5) {
            localStorage.removeItem('currentUser');
          }
      };
      window.onbeforeunload = () => {
          this._beforeUnload_time = new Date().getTime();
      };
      const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache'); 
      this.app_service.get_version().subscribe(res=>{
        if (this.version !== res.version) {
          console.log(this.version)
          console.log(res.version)
          this.http.get("", { headers, responseType: "text" })
          .subscribe(() =>{
            localStorage.clear()
            sessionStorage.clear()
            console.log("已经清空缓存！")
          });
        }
      })
      
  }
  //设置关闭浏览器自动清空缓存-------end
}
